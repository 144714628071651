import {
  Configuration,
  ECriteriaOperator,
  ISectionCriteriaField,
  ISectionCriteriaValueInner,
  ISuOMDO,
  ListApi,
} from "doweb-alfred-backend-client";
import {
  MDBInput,
  MDBTimepicker,
  MDBDatepicker,
  MDBAutocomplete,
  MDBBtn,
} from "mdb-react-ui-kit";
import danishTranslation from "../../../../../assets/config/datepickerTranslation.json";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
/*
Prop.datatype: ‘int’ | ’dec’ | ‘string’ | ‘date’ | ‘date-time’
Prop.value: ovenstående
Prop.setValue: function<‘int’ | ’dec’ | ‘string’ | ‘date’ | ‘date-time’>
*/
//value has datatype object for now, but should be typed later using a subtype hierarchy
interface Props {
  datatype:
    | "int"
    | "decimal"
    | "string"
    | "date"
    | "datetime"
    | "positiveString"
    | "stringorPK";
  value: ISectionCriteriaValueInner[];
  field: ISectionCriteriaField;
  operator: ECriteriaOperator;
  setValue: Function;
}
export function CriteriaValueSelect(props: Props) {

  //create useState to manage the value
  const [theValue, setTheValue] = useState(
    props.value.length === 1
      ? props.value[0]
      : {
          label: "",
          value:
            props.datatype === "date" || props.datatype === "datetime"
              ? new Date().toISOString()
              : "",
        }
  );

  const [asyncData, setAsyncData] = useState<ISuOMDO[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const onAsyncSearch = async (query: string) => {
    if (query.length <= 3) {
      return;
    }
    try {
      const token = await getAccessTokenSilently();
      if (props.field.listId === undefined) {
        setAsyncData([]);
        return;
      }

      setIsLoading(true);

      const listApi = new ListApi(
        new Configuration({
          accessToken: token,
          basePath: process.env.REACT_APP_BACKEND_BASE_URL,
        })
      );
      const response = await listApi.listGetListByMDONameMDOListNameGet(
        props.field.listId,
        query,
        true
      );

      setIsLoading(false);
      setAsyncData(response.data);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setAsyncData([]);
    }
  };
  const onSelectItem = (selectedValue: string) => {
    const selectedItem = asyncData.find(
      (item: ISuOMDO) => item.Name === selectedValue
    );

    if (selectedItem) {
      props.setValue([{ value: selectedItem.Id, label: selectedItem.Name }]);
    }
  };
  //create initRender state
  const [initRender, setInitRender] = useState(false);
  const [initRenderTime, setInitRenderTime] = useState(false);

  //Create useEffect to update the value when the value changes
  const ref = React.useRef<HTMLInputElement>(null); 
  useEffect(() => {
    setTheValue(
      props.value.length === 1
        ? props.value[0]
        : {
            label: "",
            value:
              props.datatype === "date" || props.datatype === "datetime"
                ? new Date().toISOString()
                : "",
          }
    );
    if (ref.current !== null) {
      ref.current.value =
        props.value.length === 1 ? props.value[0].value : "";
    }
 
    
  }, [props.value, setTheValue, ref, props.datatype]);
  //useAuth0
  const { getAccessTokenSilently } = useAuth0();

  //Create random id for the datepicker
  const randomIdForTimePicker =
    "timepicker" + Math.random().toString(36).substring(2, 15);

  const timePickerChangeHandler = (date: Date) => {
    const timepickerElement = document.querySelector(
      `#${randomIdForTimePicker}`
    ) as HTMLInputElement;
    const timepickerValue = timepickerElement!.getAttribute("value");
    if (timepickerValue !== null && timepickerValue !== "") {
      //extract hours and minutes from timepickerValue
      const timepickerValueSplit = timepickerValue.split(":");
      if (
        parseInt(timepickerValueSplit[0]) !== date.getHours() ||
        parseInt(timepickerValueSplit[1]) !== date.getMinutes()
      ) {
        date.setHours(parseInt(timepickerValueSplit[0]));
        date.setMinutes(parseInt(timepickerValueSplit[1]));
        props.setValue([
          {
            value: date.toISOString(),
            label: moment(date).format("DD/MM/YYYY HH:mm"),
          },
        ]);
      }
    }
  };

  return (
    <>
      {/* if the datatype is datetime, then we need to show a date and time picker */}
      {/*To save this, we need to use the same principle as we are using in general settings, another thing is to save the formatted date as the label, so we can show it in the overview. (TODO) */}
      {props.datatype === "datetime" && (
        <span className="time-input">
          <MDBDatepicker
            inputLabel="Dato"
            {...danishTranslation}
            value={
              theValue.value !== ""
                ? moment(new Date(theValue.value)).format("DD/MM/YYYY")
                : undefined
            }
            onChange={(value: string, date: Date) => {
              if (initRender) {
                timePickerChangeHandler(date);
              } else setInitRender(true);
            }}
          />
          <MDBTimepicker
            inputID={randomIdForTimePicker}
            defaultValue={
              theValue.value !== ""
                ? moment(theValue.value).format("HH:mm")
                : undefined
            }
            inputLabel="Klokkeslæt"
            onClose={() => {
              if (initRenderTime) {
                //run the handler
                timePickerChangeHandler(new Date(theValue.value));
              } else if (initRender) setInitRenderTime(true);
            }}
            onBlur={(e: any) => {
              if (e.target.value !== "") {
                //run the handler
                timePickerChangeHandler(new Date(theValue.value));
              }
            }}
            inline
            format="24h"
          />
        </span>
      )}
      {/* if the datatype is date, then we just need to show a date */}
      {props.datatype === "date" && (
        <span className="time-input">
          <MDBDatepicker
            inputLabel="Startdato"
            {...danishTranslation}
            value={
              theValue.value !== ""
                ? moment(new Date(theValue.value)).format("DD/MM/YYYY")
                : undefined
            }
            onChange={(value: string, date: Date) => {
              if (initRender)
                props.setValue([
                  {
                    value: date.toISOString(),
                    label: moment(date).format("DD/MM/YYYY"),
                  },
                ]);
              else setInitRender(true);
            }}
          />
        </span>
      )}
      {/* if the datatype is string, dec or int then we just need to show a text input */}
      {(props.datatype === "string" ||
        props.datatype === "positiveString" ||
        (props.datatype === "stringorPK" &&
          props.operator !== ECriteriaOperator.Is &&
          props.operator !== ECriteriaOperator.Isnot)) && ( 
        <MDBInput
          onBlur={(e) =>
            props.setValue([{ value: e.target.value, label: e.target.value }])
          } 
          ref={ref}
          type={"text"}
        />
      )}
      {(props.datatype === "decimal" || props.datatype === "int") && (
        <MDBInput
          onBlur={(e) =>
            props.setValue([{ value: e.target.value, label: e.target.value }])
          }
          ref={ref} 
          defaultValue={theValue.value}
          type={"number"}
        />
      )}
      {props.datatype === "stringorPK" &&
        (props.operator === ECriteriaOperator.Is ||
          props.operator === ECriteriaOperator.Isnot) && (
          <div className="d-grid gap-2">
          {theValue.value !== "" && <MDBBtn outline disabled color="dark">{theValue.label}</MDBBtn>}
          <MDBAutocomplete
            label={`Søg ${props.field.name}`} 
            onSearch={onAsyncSearch}
            data={asyncData as any}
            isLoading={isLoading} 
            noResults="Ingen resultater"
            onSelect={onSelectItem} 
            displayValue={(row: Record<string, string | number | string[]>) => {
              const mdo = row as unknown as ISuOMDO;
              return mdo.Name;
            }}

          /></div>
        )}
    </>
  );
}
