import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/scss/index.scss";
import App from "./App";
//import 'react-dropdown-tree-select/dist/styles.css'
import 'mdb-react-treeview/dist/css/treeview.min.css';
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import { getConfig } from "./config";
import { Provider } from "react-redux";

const onRedirectCallback = (appState: any) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const config = getConfig();

const providerConfig = {
  ...config,
  redirectUri: window.location.origin,
  onRedirectCallback,
};
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Auth0Provider {...providerConfig}>
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
