import React from "react";
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBBtn,
  MDBModalFooter,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
} from "mdb-react-ui-kit";

//interface for props:
interface Props {
  show: boolean;
  setShow: Function;
  onConfirm: Function;
  onCancel: Function;
  title: string;
  message: string;
}

//render function for modal
export const ConfirmModal = (props: Props) => {
  if (!props.show) {
    return null;
  }

  return (
    //use MDBModal to create modal
    <MDBModal staticBackdrop tabIndex="-1" show={props.show}>
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>{props.title}</MDBModalTitle>
          </MDBModalHeader>
          <MDBModalBody>{props.message}</MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="secondary"
              onClick={() => {
                props.onCancel();
                props.setShow(false);
              }}
            >
              Cancel
            </MDBBtn>
            <MDBBtn
              color="primary"
              onClick={() => {
                props.onConfirm();
                props.setShow(false);
              }}
            >
              Confirm
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};
