import React, { useRef } from "react";
import {
  MDBCol,
  MDBRow,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBAutocomplete,
  MDBIcon,
  MDBBtn,
} from "mdb-react-ui-kit";
import {
  ITargetGroupGroupMember, 
} from "doweb-alfred-backend-client";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { ISuoGroupAssocs } from "../../../../assets/types/General.d";
interface Props {
  members: ITargetGroupGroupMember[];
  setMembers: Function;
}

export function Groups(props: Props) {
  //useRef for autocomplete value
  const acRef = useRef<HTMLInputElement | null>(null);

  const groups = useSelector((state: RootState) => state.list.groups);
  const [acData, setAcData] = React.useState<ISuoGroupAssocs[]>(
    Object.values(groups.list).filter((group) => {
      //Filter those groups that is already selected
      const index = props.members.findIndex(
        (member) => member.groupId === group.groupId
      );
      return index === -1;
    })
  );
  const onGroupSearch = (value: string) => {
    
    if (value.length > 1) {
      setAcData(
        Object.values(groups.list)
          .filter((group) => {
            //Filter those groups that is already selected
            const index = props.members.findIndex(
              (member) => member.groupId === group.groupId
            );
            return index === -1;
          })
          .filter((item: ISuoGroupAssocs) =>
            item.name.toLowerCase().includes(value.toLowerCase())
          )
      );
    } else {
      setAcData(Object.values(groups.list)
      .filter((group) => {
        //Filter those groups that is already selected
        const index = props.members.findIndex(
          (member) => member.groupId === group.groupId
        );
        return index === -1;
      }));
    }
  };
  const onSelectGroup = (value: any) => {
    const selectedGroup: ISuoGroupAssocs = value as ISuoGroupAssocs;
    if (selectedGroup) {
      //Set the value of the autocomplete to the name of the selected group
      if (acRef.current) {
        acRef.current.value = selectedGroup.name;
      }

      const groupMember: ITargetGroupGroupMember = {
        ...selectedGroup,
      };

      const new_members: ITargetGroupGroupMember[] = [...props.members];
      const index = new_members.findIndex(
        (member) => member.groupId === groupMember.groupId
      );

      if (index === -1) {
        new_members.push(groupMember);
        props.setMembers(new_members);
      }
    }
  };

  return (
    <>
      <MDBRow className="mb-2">
        <MDBCol size={6}>
          <strong>Valgte grupper:</strong>
        </MDBCol>
        <MDBCol size={6}>
          <MDBAutocomplete
            label="Find en gruppe..."
            data={acData as any}
            onSearch={onGroupSearch}
            noResults="Der blev ikke fundet nogen gruppe"
            onSelect={onSelectGroup}
            ref={acRef}
            itemContent={(result: Record<string, any>) => {
              const group = result as ISuoGroupAssocs;
              return (
                <div className="autocomplete-custom-item-content">
                  <div className="autocomplete-custom-item-title">
                    {group.name}
                  </div>
                  <div className="autocomplete-custom-item-subtitle">
                    Antal medarbejdere i gruppe: {group.associates.length}
                  </div>
                </div>
              );
            }}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <div className="tableWrap">
            <MDBTable small striped>
              <MDBTableHead>
                <tr>
                  <th scope="col">Navn</th>
                  <th scope="col">Antal medarbejdere</th>
                  <th scope="col"></th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {props.members.length === 0 && (
                  <tr>
                    <td colSpan={3}>Ingen grupper valgt</td>
                  </tr>
                )}
                {props.members.map((member, index) => {
                  const this_group: ISuoGroupAssocs[] = groups.list.filter(
                    (group) => group.groupId === member.groupId
                  );
                  if (typeof this_group[0] !== "undefined") {
                    return (
                      <tr color="danger" key={index}>
                        <td>{this_group[0].name}</td>
                        <td>{this_group[0].associates.length}</td>
                        <td>
                          <MDBBtn
                            tag="a"
                            color="none"
                            className="m-1"
                            style={{ color: "#3b5998" }}
                            onClick={(e) => {
                              const new_members: ITargetGroupGroupMember[] = [
                                ...props.members,
                              ];
                              new_members.splice(index, 1);
                              props.setMembers(new_members);
                            }}
                          >
                            <MDBIcon color="danger" fas icon="trash-alt" />
                          </MDBBtn>
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr className="table-danger" key={index}>
                        <td>{member.name}</td>
                        <td>0 (Gruppen er slettet)</td>
                        <td>
                          <MDBBtn
                            tag="a"
                            color="none"
                            className="m-1"
                            style={{ color: "#3b5998" }}
                            onClick={(e) => {
                              const new_members: ITargetGroupGroupMember[] = [
                                ...props.members,
                              ];
                              new_members.splice(index, 1);
                              props.setMembers(new_members);
                            }}
                          >
                            <MDBIcon color="danger" fas icon="trash-alt" />
                          </MDBBtn>
                        </td>
                      </tr>
                    );
                  }
                })}
              </MDBTableBody>
            </MDBTable>
          </div>
        </MDBCol>
      </MDBRow>
    </>
  );
}
