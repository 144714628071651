import React, { useEffect, useRef } from "react"; 
import {
  MDBBadge,
  MDBListGroupItem,
  MDBIcon,
  MDBInput,
  MDBBtn,
} from "mdb-react-ui-kit";
import "./JobListItem.scoped.scss"; 
interface Props {
  id: string;
  editMode: boolean;
  title: string;
  changeTitle: Function;
  isActive: boolean;
  setActive: Function;
  deleteJob: Function;
  sectionCount: number;
}
export const JobListItem = (props: Props) => {
  const titleValRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if(titleValRef.current){ 
      titleValRef.current.value = props.title;
    }
  }, [titleValRef, props.title, props.editMode]);
  return (
    <MDBListGroupItem
      className="d-flex justify-content-between align-items-center jobListItem"
      tag="button"
      active={props.isActive && !props.editMode}
      onClick={(e) => {
        e.preventDefault();
        props.setActive(props.id);
      }}
      action
      type="button"
    >
      {props.editMode && <MDBInput type="text" onBlur={(e) => props.changeTitle(e.target.value)} ref={titleValRef} />}
      {!props.editMode && props.title}
      <div className="justify-content-right">
        {props.editMode && (
          <MDBBtn tag="a" floating color="danger" onClick={e => { e.preventDefault(); props.deleteJob()}}>
            <MDBIcon far icon="trash-alt" />
          </MDBBtn>
        )}
         {!props.editMode && props.sectionCount > 0 && (
          <MDBBadge color="warning" pill className="me-3">
            {props.sectionCount}
          </MDBBadge>
        )} 
      </div>
    </MDBListGroupItem>
  );
};



 