import {
  MDBAnimation,
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
  MDBProgress,
  MDBProgressBar,
  MDBRow,
  MDBSpinner,
} from "mdb-react-ui-kit";
import { EAsyncLoadStatus } from "../assets/types/General.d";
import WizardWait from "../components/Wizard";
export interface statusJobItem {
  id?: string;
  link?: string;
  name: string;
  status: EAsyncLoadStatus;
  error: string;
}
interface Props {
  jobs: statusJobItem[];
  rowButton?: boolean;
  rowButtonText?: string;
  fadeOut?: boolean;
  fadeIn?: boolean;
  showErrorOnSuccess?: boolean;
  title: string;
}

export const LoadingScreen = (props: Props) => {
  const jobSteps: number = 100 / props.jobs.length;
  const done =
    props.jobs.filter((job) => job.status === EAsyncLoadStatus.success)
      .length === props.jobs.length;
  const remaining = props.jobs.filter(
    (job) => job.status === EAsyncLoadStatus.success
  ).length;
  return (
    <MDBAnimation
      start="onLoad"
      duration={done ? 1500 : 500}
      animation={
        done
          ? props.fadeOut
            ? "fade-out"
            : ""
          : props.fadeIn
          ? "fade-in"
          : ""
      }
      style={{
        position: "absolute",
        background: "#f5f5f5",
        width: "100%",
        height: "100%",
        top: "0",
        zIndex: 1031,
        left: "0",
      }}
    >
      <MDBRow className="mt-5 justify-center">
        <WizardWait />
      </MDBRow>
      <MDBRow>
        <MDBCol sm="6" offsetSm={3}>
          <h1 style={{ fontFamily: "lobster" }} className=" text-center">
            {props.title}
          </h1>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol sm="6" offsetSm={3}>
          <MDBProgress height="20">
            <MDBProgressBar
              striped
              animated
              width={jobSteps * remaining}
              valuemin={0}
              valuemax={100}
            >
              {Math.round(jobSteps * remaining)}%
            </MDBProgressBar>
          </MDBProgress>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol className="mt-4" sm="6" offsetSm={3}>
          <MDBListGroup flush style={{ minWidth: "22rem" }}>
            {props.jobs.map((job, index) => {
              return (
                <MDBListGroupItem
                  key={index}
                  className="d-flex"
                  color={
                    job.status === EAsyncLoadStatus.success
                      ? "success"
                      : job.status === EAsyncLoadStatus.error
                      ? "danger"
                      : "transparent"
                  }
                >
                  <div className="flex-grow-1">
                  {job.status === EAsyncLoadStatus.loading && (
                    <MDBSpinner className="me-2" size="sm">
                      <span className="visually-hidden">Loading...</span>
                    </MDBSpinner>
                  )}
                  {job.status === EAsyncLoadStatus.idle && (
                    <MDBIcon fas icon="hourglass-half" />
                  )}
                  {job.status === EAsyncLoadStatus.success && (
                    <MDBIcon fas icon="check" />
                  )}
                  {job.status === EAsyncLoadStatus.error && (
                    <MDBIcon fas icon="times" />
                  )}
                  &nbsp; {job.name}{" "}
                  {(job.status === EAsyncLoadStatus.error || (job.status === EAsyncLoadStatus.success && props.showErrorOnSuccess && job.error !== '')) && <>({job.error})</>}
              
                  </div>
                  <div className="flex-shrink-1">{job.status === EAsyncLoadStatus.success && props.rowButton  && job.link !== undefined && <MDBBtn target="_blank" tag="a" size="sm" color="dark" outline href={job.link}>{props.rowButtonText}</MDBBtn>}</div>
                </MDBListGroupItem>
              );
            })}
          </MDBListGroup>
        </MDBCol>
      </MDBRow>
    </MDBAnimation>
  );
};
