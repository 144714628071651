import { ECriteriaOperator } from "doweb-alfred-backend-client";

const getOperator = (
  fieldType: string,
  selectedOperator: ECriteriaOperator
) => {
  const operatorMap: { [key: string]: any } = {
    associate: [
      {
        text: "Konsulent er en af",
        value: ECriteriaOperator.Associateisoneof,
        defaultSelected:
          selectedOperator === ECriteriaOperator.Associateisoneof,
      },
      {
        text: "Konsulent er ikke en af",
        value: ECriteriaOperator.Associateisnotoneof,
        defaultSelected:
          selectedOperator === ECriteriaOperator.Associateisnotoneof,
      },
      /* Not relevant option, as the selection will always be made as a system user.
      ,
      {
        text: "Konsulent er denne",
        value: ECriteriaOperator.CurrentAssociate,
        defaultSelected:
          selectedOperator ===
          ECriteriaOperator.CurrentAssociate,
      },
      */
    ],
    int: [
      {
        text: "Er",
        value: ECriteriaOperator.Eq,
        defaultSelected: selectedOperator === ECriteriaOperator.Eq,
      },
      {
        text: "Er ikke",
        value: ECriteriaOperator.Ne,
        defaultSelected: selectedOperator === ECriteriaOperator.Ne,
      },
      {
        text: "Er større end",
        value: ECriteriaOperator.Gt,
        defaultSelected: selectedOperator === ECriteriaOperator.Gt,
      },
      {
        text: "Er mindre end",
        value: ECriteriaOperator.Lt,
        defaultSelected: selectedOperator === ECriteriaOperator.Lt,
      },
      {
        text: "Er imellem",
        value: ECriteriaOperator.Between,
        defaultSelected: selectedOperator === ECriteriaOperator.Between,
      },
    ],
    decimal: [
      {
        text: "Er",
        value: ECriteriaOperator.Eq,
        defaultSelected: selectedOperator === ECriteriaOperator.Eq,
      },
      {
        text: "Er ikke",
        value: ECriteriaOperator.Ne,
        defaultSelected: selectedOperator === ECriteriaOperator.Ne,
      },
      {
        text: "Er større end",
        value: ECriteriaOperator.Gt,
        defaultSelected: selectedOperator === ECriteriaOperator.Gt,
      },
      {
        text: "Er mindre end",
        value: ECriteriaOperator.Lt,
        defaultSelected: selectedOperator === ECriteriaOperator.Lt,
      },
      {
        text: "Er imellem",
        value: ECriteriaOperator.Between,
        defaultSelected: selectedOperator === ECriteriaOperator.Between,
      },
    ],
    string: [
      {
        text: "Er",
        value: ECriteriaOperator.Is,
        defaultSelected: selectedOperator === ECriteriaOperator.Is,
      },
      {
        text: "Er ikke",
        value: ECriteriaOperator.Isnot,
        defaultSelected: selectedOperator === ECriteriaOperator.Isnot,
      },
      {
        text: "Starter med",
        value: ECriteriaOperator.Begins,
        defaultSelected: selectedOperator === ECriteriaOperator.Begins,
      },
      {
        text: "Starter ikke med",
        value: ECriteriaOperator.Notbegins,
        defaultSelected: selectedOperator === ECriteriaOperator.Notbegins,
      },
      {
        text: "Indeholder",
        value: ECriteriaOperator.Contains,
        defaultSelected: selectedOperator === ECriteriaOperator.Contains,
      },
      {
        text: "Indeholder ikke",
        value: ECriteriaOperator.Notcontains,
        defaultSelected: selectedOperator === ECriteriaOperator.Notcontains,
      },
    ],
    stringorPK: [
      {
        text: "Er",
        value: ECriteriaOperator.Is,
        defaultSelected: selectedOperator === ECriteriaOperator.Is,
      },
      {
        text: "Er ikke",
        value: ECriteriaOperator.Isnot,
        defaultSelected: selectedOperator === ECriteriaOperator.Isnot,
      },
      {
        text: "Starter med",
        value: ECriteriaOperator.Begins,
        defaultSelected: selectedOperator === ECriteriaOperator.Begins,
      },
      {
        text: "Starter ikke med",
        value: ECriteriaOperator.Notbegins,
        defaultSelected: selectedOperator === ECriteriaOperator.Notbegins,
      },
      {
        text: "Indeholder",
        value: ECriteriaOperator.Contains,
        defaultSelected: selectedOperator === ECriteriaOperator.Contains,
      },
      {
        text: "Indeholder ikke",
        value: ECriteriaOperator.Notcontains,
        defaultSelected: selectedOperator === ECriteriaOperator.Notcontains,
      },
    ],
    positiveString: [
      {
        text: "Er",
        value: ECriteriaOperator.Is,
        defaultSelected: selectedOperator === ECriteriaOperator.Is,
      },
      {
        text: "Er ikke",
        value: ECriteriaOperator.Isnot,
        defaultSelected: selectedOperator === ECriteriaOperator.Isnot,
      },
      {
        text: "Starter med",
        value: ECriteriaOperator.Begins,
        defaultSelected: selectedOperator === ECriteriaOperator.Begins,
      },
      {
        text: "Starter ikke med",
        value: ECriteriaOperator.Notbegins,
        defaultSelected: selectedOperator === ECriteriaOperator.Notbegins,
      },
      {
        text: "Indeholder",
        value: ECriteriaOperator.Contains,
        defaultSelected: selectedOperator === ECriteriaOperator.Contains,
      },
      {
        text: "Indeholder ikke",
        value: ECriteriaOperator.Notcontains,
        defaultSelected: selectedOperator === ECriteriaOperator.Notcontains,
      },
    ],
    date: [
      //after, afterToday, before, beforeToday, between, date, from, equals, to, today
      {
        text: "Før jobkørsel",
        value: ECriteriaOperator.Beforetoday,
        defaultSelected: selectedOperator === ECriteriaOperator.Beforetoday,
      },
      {
        text: "Samme dag som jobkørsel",
        value: ECriteriaOperator.Today,
        defaultSelected: selectedOperator === ECriteriaOperator.Today,
      },
      {
        text: "Efter jobkørsel",
        value: ECriteriaOperator.Aftertoday,
        defaultSelected: selectedOperator === ECriteriaOperator.Aftertoday,
      },
      {
        text: "I ugen forinden jobkørsel",
        value: ECriteriaOperator.Lastweek,
        defaultSelected: selectedOperator === ECriteriaOperator.Lastweek,
      },
      {
        text: "I samme uge som jobkørsel",
        value: ECriteriaOperator.Thisweek,
        defaultSelected: selectedOperator === ECriteriaOperator.Thisweek,
      },
      {
        text: "I ugen som følger jobkørsel",
        value: ECriteriaOperator.Nextweek,
        defaultSelected: selectedOperator === ECriteriaOperator.Nextweek,
      },
      {
        text: "I måneden forinden jobkørsel",
        value: ECriteriaOperator.Lastmonth,
        defaultSelected: selectedOperator === ECriteriaOperator.Lastmonth,
      },
      {
        text: "I samme måned som jobkørsel",
        value: ECriteriaOperator.Thismonth,
        defaultSelected: selectedOperator === ECriteriaOperator.Thismonth,
      },
      {
        text: "I måneden som følger jobkørsel",
        value: ECriteriaOperator.Nextmonth,
        defaultSelected: selectedOperator === ECriteriaOperator.Nextmonth,
      },
      {
        text: "I kvartalet forinden jobkørsel",
        value: ECriteriaOperator.Lastquarter,
        defaultSelected: selectedOperator === ECriteriaOperator.Lastquarter,
      },
      {
        text: "I samme kvartal som jobkørsel",
        value: ECriteriaOperator.Thisquarter,
        defaultSelected: selectedOperator === ECriteriaOperator.Thisquarter,
      },
      {
        text: "I Kvartalet som følger jobkørsel",
        value: ECriteriaOperator.Nextquarter,
        defaultSelected: selectedOperator === ECriteriaOperator.Nextquarter,
      },
      {
        text: "I Samme halvår som jobkørsel",
        value: ECriteriaOperator.Thishalf,
        defaultSelected: selectedOperator === ECriteriaOperator.Thishalf,
      },
      {
        text: "I Samme år som jobkørsel",
        value: ECriteriaOperator.Thisyear,
        defaultSelected: selectedOperator === ECriteriaOperator.Thisyear,
      },
      {
        text: "Er",
        value: ECriteriaOperator.Eq,
        defaultSelected: selectedOperator === ECriteriaOperator.Eq,
      },
      {
        text: "Er efter",
        value: ECriteriaOperator.After,
        defaultSelected: selectedOperator === ECriteriaOperator.After,
      },
      {
        text: "Er før",
        value: ECriteriaOperator.Before,
        defaultSelected: selectedOperator === ECriteriaOperator.Before,
      },
      {
        text: "Er imellem",
        value: ECriteriaOperator.Between,
        defaultSelected: selectedOperator === ECriteriaOperator.Between,
      },
      // HARDCODED FOR VÆKSTHUSET
      /*  
      custom_week_1_weeks_ago = "custom_week_1_weeks_ago",
      custom_week_2_weeks_ago = "custom_2_weeks_ago",
      custom_week_3_weeks_ago = "custom_3_weeks_ago",
      custom_week_6_weeks_ago = "custom_6_weeks_ago",
      custom_week_12_weeks_ago = "custom_12_weeks_ago",
      custom_week_18_weeks_ago = "custom_18_weeks_ago",
      custom_week_24_weeks_ago = "custom_24_weeks_ago",
      custom_week_30_weeks_ago = "custom_30_weeks_ago",
      */
      {
        text: "Custom: for præcis 1 uge før jobkørsel",
        value: ECriteriaOperator.CustomWeek1WeeksAgo,
        defaultSelected: selectedOperator === ECriteriaOperator.CustomWeek1WeeksAgo,
      },
      {
        text: "Custom: for præcis 2 uger før jobkørsel",
        value: ECriteriaOperator.Custom2WeeksAgo,
        defaultSelected: selectedOperator === ECriteriaOperator.Custom2WeeksAgo,
      },
      {
        text: "Custom: for præcis 3 uger før jobkørsel",
        value: ECriteriaOperator.Custom3WeeksAgo,
        defaultSelected: selectedOperator === ECriteriaOperator.Custom3WeeksAgo,
      },
      {
        text: "Custom: for præcis 6 uger før jobkørsel",
        value: ECriteriaOperator.Custom6WeeksAgo,
        defaultSelected: selectedOperator === ECriteriaOperator.Custom6WeeksAgo,
      },
      {
        text: "Custom: for præcis 12 uger før jobkørsel",
        value: ECriteriaOperator.Custom12WeeksAgo,
        defaultSelected: selectedOperator === ECriteriaOperator.Custom12WeeksAgo,
      },
      {
        text: "Custom: for præcis 18 uger før jobkørsel",
        value: ECriteriaOperator.Custom18WeeksAgo,
        deFaultSelected: selectedOperator === ECriteriaOperator.Custom18WeeksAgo,
      },
      {
        text: "Custom: for præcis 24 uger før jobkørsel",
        value: ECriteriaOperator.Custom24WeeksAgo,
        defaultSelected: selectedOperator === ECriteriaOperator.Custom24WeeksAgo,
      },
      {
        text: "Custom: for præcis 30 uger før jobkørsel",
        value: ECriteriaOperator.Custom30WeeksAgo,
        defaultSelected: selectedOperator === ECriteriaOperator.Custom30WeeksAgo,
      },
    ],
    datetime: [
      //DateTime, afterTime, beforeTime
      {
        text: "Før jobkørsel",
        value: ECriteriaOperator.Beforetoday,
        defaultSelected: selectedOperator === ECriteriaOperator.Beforetoday,
      },
      {
        text: "Samme dag som jobkørsel",
        value: ECriteriaOperator.Today,
        defaultSelected: selectedOperator === ECriteriaOperator.Today,
      },
      {
        text: "Efter jobkørsel",
        value: ECriteriaOperator.Aftertoday,
        defaultSelected: selectedOperator === ECriteriaOperator.Aftertoday,
      },
      {
        text: "I ugen forinden jobkørsel",
        value: ECriteriaOperator.Lastweek,
        defaultSelected: selectedOperator === ECriteriaOperator.Lastweek,
      },
      {
        text: "I samme uge som jobkørsel",
        value: ECriteriaOperator.Thisweek,
        defaultSelected: selectedOperator === ECriteriaOperator.Thisweek,
      },
      {
        text: "I ugen som følger jobkørsel",
        value: ECriteriaOperator.Nextweek,
        defaultSelected: selectedOperator === ECriteriaOperator.Nextweek,
      },
      {
        text: "I måneden forinden jobkørsel",
        value: ECriteriaOperator.Lastmonth,
        defaultSelected: selectedOperator === ECriteriaOperator.Lastmonth,
      },
      {
        text: "I samme måned som jobkørsel",
        value: ECriteriaOperator.Thismonth,
        defaultSelected: selectedOperator === ECriteriaOperator.Thismonth,
      },
      {
        text: "I måneden som følger jobkørsel",
        value: ECriteriaOperator.Nextmonth,
        defaultSelected: selectedOperator === ECriteriaOperator.Nextmonth,
      },
      {
        text: "I kvartalet forinden jobkørsel",
        value: ECriteriaOperator.Lastquarter,
        defaultSelected: selectedOperator === ECriteriaOperator.Lastquarter,
      },
      {
        text: "I samme kvartal som jobkørsel",
        value: ECriteriaOperator.Thisquarter,
        defaultSelected: selectedOperator === ECriteriaOperator.Thisquarter,
      },
      {
        text: "I Kvartalet som følger jobkørsel",
        value: ECriteriaOperator.Nextquarter,
        defaultSelected: selectedOperator === ECriteriaOperator.Nextquarter,
      },
      {
        text: "I samme halvår som jobkørsel",
        value: ECriteriaOperator.Thishalf,
        defaultSelected: selectedOperator === ECriteriaOperator.Thishalf,
      },
      {
        text: "I samme år som jobkørsel",
        value: ECriteriaOperator.Thisyear,
        defaultSelected: selectedOperator === ECriteriaOperator.Thisyear,
      },
      {
        text: "Er",
        value: ECriteriaOperator.Datetime,
        defaultSelected: selectedOperator === ECriteriaOperator.Datetime,
      },
      {
        text: "Er efter",
        value: ECriteriaOperator.Aftertime,
        defaultSelected: selectedOperator === ECriteriaOperator.Aftertime,
      },
      {
        text: "Er før",
        value: ECriteriaOperator.Beforetime,
        defaultSelected: selectedOperator === ECriteriaOperator.Beforetime,
      },
    ],
    lists: [
      //equals, oneOf, notOneOf
      {
        text: "Er",
        value: ECriteriaOperator.Eq,
        defaultSelected: selectedOperator === ECriteriaOperator.Eq,
      },
      {
        text: "Er en af",
        value: ECriteriaOperator.Oneof,
        defaultSelected: selectedOperator === ECriteriaOperator.Oneof,
      },
      {
        text: "Er ikke en af",
        value: ECriteriaOperator.Notoneof,
        defaultSelected: selectedOperator === ECriteriaOperator.Notoneof,
      },
    ],
    listAny: [
      //equals, oneOf, notOneOf
      {
        text: "Er",
        value: ECriteriaOperator.Eq,
        defaultSelected: selectedOperator === ECriteriaOperator.Eq,
      },
      {
        text: "Er en af",
        value: ECriteriaOperator.Oneof,
        defaultSelected: selectedOperator === ECriteriaOperator.Oneof,
      },
      {
        text: "Er ikke en af",
        value: ECriteriaOperator.Notoneof,
        defaultSelected: selectedOperator === ECriteriaOperator.Notoneof,
      },
    ],
    listInterest: [
      //equals, oneOf, notOneOf
      {
        text: "Er",
        value: ECriteriaOperator.Eq,
        defaultSelected: selectedOperator === ECriteriaOperator.Eq,
      },
      {
        text: "Er en af",
        value: ECriteriaOperator.Oneof,
        defaultSelected: selectedOperator === ECriteriaOperator.Oneof,
      },
      {
        text: "Er ikke en af",
        value: ECriteriaOperator.Notoneof,
        defaultSelected: selectedOperator === ECriteriaOperator.Notoneof,
      },
    ],
    userGroup: [
      //equals, oneOf, notOneOf
      {
        text: "Er",
        value: ECriteriaOperator.Eq,
        defaultSelected: selectedOperator === ECriteriaOperator.Eq,
      },
      {
        text: "Er en af",
        value: ECriteriaOperator.Oneof,
        defaultSelected: selectedOperator === ECriteriaOperator.Oneof,
      },
      {
        text: "Er ikke en af",
        value: ECriteriaOperator.Notoneof,
        defaultSelected: selectedOperator === ECriteriaOperator.Notoneof,
      },
    ],
  };
  if (fieldType in operatorMap) {
    return operatorMap[fieldType];
  } else {
    return [];
  }
};
export { getOperator };
