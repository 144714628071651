import { ISectionCriteriaValueInner } from "doweb-alfred-backend-client";
import { MDBRadio } from "mdb-react-ui-kit";
import React from "react";
/*
Prop.datatype:’bool’
Prop.value: ovenstående
Prop.setValue: function<bool>
*/
//value has datatype object for now, but should be typed later using a subtype hierarchy
interface Props {
  datatype: "bool";
  value: ISectionCriteriaValueInner[];
  setValue: Function;
}
export function CriteriaValueToggle(props: Props) {
  //Since this is a single value control, convert the array to a single value if length is 1
  //since we only accept bool value, convert props.value[0] to bool
  const theValue: boolean =
    props.value.length === 1 ? props.value[0].value === "true" : false;

  return (
    <div className="d-flex justify-content-center">
      <MDBRadio
        name="options"
        label="Nej"
        defaultChecked={!theValue}
        onClick={() => {
          props.setValue([{ value: "false", label: "Nej" }]);
        }}
      />
      <MDBRadio
        name="options"
        className="ms-2"
        label="Ja"
        defaultChecked={theValue}
        onClick={() => {
          props.setValue([{ value: "true", label: "Ja" }]);
        }}
      />
    </div>
  );
}
