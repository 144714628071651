import { useAuth0 } from "@auth0/auth0-react";
import { ISuOCRMScript } from "doweb-alfred-backend-client";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBBtn,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon,
  MDBSpinner,
} from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { EAsyncLoadStatus } from "../../../assets/types/General.d";
import { AppDispatch } from "../../../store";
import {
  fetchCRMScripts,
  getCRMScripts,
} from "../../../store/features/ListSlice";
import FolderView from "../../../components/FolderView";

interface Props {
  show: boolean;
  setShow: Function;
  setModal: Function;
}

export function SelectCRMScript(props: Props) {
  const auth0 = useAuth0();
  const crmscripts = useSelector(getCRMScripts);
  const dispatch = useDispatch<AppDispatch>();
  return (
    <MDBModal staticBackdrop show={props.show} setShow={props.setShow}>
      <MDBModalDialog scrollable centered size="xl">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Vælg et CRM Script</MDBModalTitle>
            <MDBBtn
              disabled={crmscripts.status === EAsyncLoadStatus.loading}
              style={{
                position: "absolute",
                right: "45px",
              }}
              color="success"
              onClick={(e) => {
                auth0.getAccessTokenSilently().then((token) => {
                  dispatch(fetchCRMScripts(token));
                });
              }}
            >
              {crmscripts.status === EAsyncLoadStatus.success && (
                <>
                  <MDBIcon fas icon={"sync"} />
                  &nbsp; Opdater
                </>
              )} 
               {crmscripts.status === EAsyncLoadStatus.loading && (
                 <MDBSpinner size="sm" role="status" tag="span" />
              )}
            </MDBBtn>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => props.setModal(!props.show)}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            {crmscripts.status === EAsyncLoadStatus.success && (
              
              <FolderView
                fontSize={14}
                filesAndFolders={crmscripts.list}
                onSelect={(element: ISuOCRMScript) =>
                  props.setModal(false, element)
                }
                iconSize="4x"
                cardWidth={160}
              />
              
            )}
            {crmscripts.status === EAsyncLoadStatus.loading && (
              <div className="d-flex justify-content-center">
                <MDBSpinner role="status">
                  <span className="visually-hidden">Indlæser...</span>
                </MDBSpinner>
              </div>
            )}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="secondary"
              onClick={() => {
                props.setModal(false);
              }}
            >
              Annuller
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
} 
