import React from "react";
import ReactDOM from "react-dom";
const modalRoot = document.getElementById("modal-root") as HTMLElement;
// assuming in your html file has a div with id 'modal-root';
type Props = {
    children: JSX.Element;
}
export class ModalWrapper extends React.Component<Props> {
  el: HTMLElement = document.createElement("div");

  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() { 
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}