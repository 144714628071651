import {
  MDBRow,
  MDBAccordionItem,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBSelect,
  MDBCol,
} from "mdb-react-ui-kit";
import {
  ECriteriaLogicalOperator,
  ECriteriaOperator,
  ESectionEntity,
  ISectionCriteria,
  ISectionCriteriaGroup, 
} from "doweb-alfred-backend-client";
import { SaveEditBtn } from "../../../SaveEditBtn";
import { useState } from "react";
import { ModalWrapper } from "../../../ModalWrapper";
import { CreateEditCriteriaModal } from "./CreateEditCriteriaModal";
import { getOperator } from "./CriteriaOperatorSelector/OperatorMap";
//import { MDBSortable, MDBSortableElement } from 'mdb-react-drag-drop';
interface Props {
  sectionType: ESectionEntity;
  id: number;
  criteriaGroup: ISectionCriteriaGroup;
  setCriteriaGroup: Function;
  deletecriteria: Function;
  motherModal: boolean;
  setMotherModal: Function;
}

export function CriteriaGroup(props: Props) {
  //We put the criteria in a cache, so everything wont be saved before hitting the save button

  const blankCriteria: ISectionCriteria = {
    field: { id: "", name: "Vælg et felt", dataType: "" },
    operator: ECriteriaOperator.Eq,
    value: [{ label: "Vælg en værdi", value: "" }],
    rank: props.criteriaGroup.criterias.length,
  };
  const [activeCriteria, setActiveCriteria] = useState({
    index: -1,
    criteria: blankCriteria,
  });
  //Create a state for the modal
  const [showEditModal, setShowEditModal] = useState(false);
  // trigger mother modal when edit modal is closed

  const showEditToggler = (show: boolean) => {
    if (!show && showEditModal && !props.motherModal) {
      props.setMotherModal(true);
      setShowEditModal(false); 
    }
  };

  const getOperatorLabel = (
    inputOperator: ECriteriaOperator,
    inputFieldType: string
  ) => {
    const allOperators = getOperator(inputFieldType, inputOperator).filter(
      (operator: any) => operator.defaultSelected
    );
    if (allOperators.length > 0) {
      return allOperators[0].text;
    } else if (inputFieldType === "bool") {
      return "Er";
    }
    return inputOperator.toString();
  };
  return (
    <>
      <MDBAccordionItem
        collapseId={props.id}
        key={props.id}
        headerTitle={
          <>
            {props.criteriaGroup.name}
            <MDBBtn
              style={{
                position: "absolute",
                right: "60px",
              }}
              tag="a"
              color="danger"
              size="sm"
              onClick={(e) => {
                e.preventDefault();
                props.deletecriteria(props.criteriaGroup);
              }}
            >
              <MDBIcon fas icon="trash" />
              &nbsp; Slet gruppe
            </MDBBtn>
          </>
        }
      >
        <form>
          <MDBRow>
            <MDBCol size={5}>
              <MDBInput
                onChange={(e) =>
                  props.setCriteriaGroup({
                    ...props.criteriaGroup,
                    name: e.target.value,
                  })
                }
                defaultValue={props.criteriaGroup.name}
                label={"Navn på kriteriegruppe"}
                className="mb-2"
              />
            </MDBCol>
            <MDBCol size={3}>
              <MDBSelect
                data={[
                  {
                    text: "Og",
                    value:
                      ECriteriaLogicalOperator.And,
                    defaultSelected:
                      props.criteriaGroup.logicalOperatorCriterias ===
                      ECriteriaLogicalOperator.And,
                  },
                  {
                    text: "Eller",
                    value: ECriteriaLogicalOperator.Or,
                    defaultSelected:
                      props.criteriaGroup.logicalOperatorCriterias ===
                      ECriteriaLogicalOperator.Or,
                  },
                ]}
                label="Logisk operator i gruppen"
                onValueChange={(e: any) =>
                  props.setCriteriaGroup({
                    ...props.criteriaGroup,
                    logicalOperatorCriterias:
                      e.value as ECriteriaLogicalOperator,
                  })
                }
              />
            </MDBCol>
            <MDBCol className="text-end" size={4}>
              <SaveEditBtn
              newText="Opret nyt kriterie"
                hideCancel
                hideEdit
                onClickCancel={(e) => {
                  e.preventDefault();
                }}
                onClickCreate={(e: any) => {
                  e.preventDefault();
                  props.setMotherModal(false);
                  setActiveCriteria({
                    index: -1,
                    criteria: blankCriteria,
                  });
                  setShowEditModal(true);
                }}
                createMode={showEditModal}
                onClickEdit={(e) => {
                  e.preventDefault();
                }}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size={12}>
              <div className="tableWrap">
                <MDBTable small striped>
                  <MDBTableHead>
                    <tr>
                      <th scope="col">Felt</th>
                      <th scope="col">Operator</th>
                      <th scope="col">Værdi</th>
                      <th scope="col" />
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {props.criteriaGroup.criterias.length === 0 && (
                      <tr>
                        <td colSpan={4}>Ingen kriterier</td>
                      </tr>
                    )}
                    {props.criteriaGroup.criterias.map((item, index) => (
                      <tr key={index}>
                        <td>{item.field?.name}</td>
                        <td>
                          {getOperatorLabel(item.operator, item.field.dataType)}
                        </td>
                        <td>
                          {item.value.map((value) => value.label).join(", ")}
                        </td>
                        <td className="text-end">
                          <MDBBtn
                            onClick={(e) => {
                              e.preventDefault();
                              props.setMotherModal(false);
                              setActiveCriteria({
                                index: index,
                                criteria: item,
                              });
                              setShowEditModal(true);
                            }}
                            size="sm"
                            color="warning"
                          >
                            <MDBIcon fas icon="pencil-alt" />
                          </MDBBtn>
                          <MDBBtn
                            className="ms-1"
                            color="danger"
                            size="sm"
                            onClick={(e) => {
                              e.preventDefault();
                              let tempCriterias = [
                                ...props.criteriaGroup.criterias,
                              ];
                              tempCriterias.splice(index, 1);
                              props.setCriteriaGroup({
                                ...props.criteriaGroup,
                                criterias: tempCriterias,
                              });
                            }}
                          >
                            <MDBIcon fas icon="trash" />
                          </MDBBtn>
                        </td>
                      </tr>
                    ))}
                  </MDBTableBody>
                </MDBTable>
              </div>
            </MDBCol>
          </MDBRow>
        </form>
      </MDBAccordionItem>

      <ModalWrapper>
        <CreateEditCriteriaModal
          sectionType={props.sectionType}
          criteria={activeCriteria.criteria}
          setCriteria={(changedCriteria: ISectionCriteria) => {
            if (activeCriteria.index === -1) {
              const newCriteriaArray = [
                ...props.criteriaGroup.criterias,
                changedCriteria,
              ];
              props.setCriteriaGroup({
                ...props.criteriaGroup,
                criterias: newCriteriaArray,
              }); 
            } else {
              let tempCriterias = [...props.criteriaGroup.criterias];
              tempCriterias[activeCriteria.index] = changedCriteria;
              props.setCriteriaGroup({
                ...props.criteriaGroup,
                criterias: tempCriterias,
              });
            }
          }}
          show={showEditModal}
          setModal={showEditToggler}
        />
      </ModalWrapper>
    </>
  );
}
