import React from "react";
import { MDBToast } from "mdb-react-ui-kit"; 

//necessary for toast to fire correctly - onHide shoots together with onShow.
let toastResetBool = false;

interface Props {
    toast: boolean;
    setToast: Function;
    message: string;
    header?: string;
    color: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'light' | 'dark' | 'info';
  }

export const Toast = (props: Props)=>  {
    //const [showToast, setShowToast] = useState(false);
return (
    <MDBToast
    show={props.toast}
    onHide={() => {if(toastResetBool){toastResetBool = false; props.setToast(false)} }}
    onShow={()=>{toastResetBool = true;}}
    color={props.color}
    autohide
    stacking
    position='top-right'
    delay={2000}
    appendToBody
    headerContent={
        props.header && 
        <strong className='me-auto'>{props.header}</strong>
     
    }
    bodyContent={props.message}
    />
)
}
