import { JobListItem } from "../../../../components/JobListItem";
import { SaveEditBtn } from "../../../../components/SaveEditBtn";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import {
  createDefaultJob,
  setActiveJob,
  updateJobCollection,
} from "../../../../store/features/JobSlice";
import { MDBCol, MDBListGroup, MDBRow } from "mdb-react-ui-kit";
import "./LeftJobList.scoped.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { IJobListItem } from "doweb-alfred-backend-client";

const LeftJobList = () => {
  const jobs = useSelector((state: RootState) => state.job);
  const dispatch = useDispatch<AppDispatch>();
  const { getAccessTokenSilently } = useAuth0();
  const [jobCache, setJobCache] = useState(jobs.collection);
  const [editMode, setEditMode] = useState(false);
  useEffect(() => {
    setJobCache(jobs.collection);
  }, [jobs.collection]);
  return (
    <>
      <MDBRow>
        <MDBCol size="12">
          <MDBListGroup>
            {jobCache.map((job: IJobListItem, index: number) => (
              <JobListItem
                sectionCount={job.sections.length}
                deleteJob={() => {
                  const jobTemp = [...jobCache];
                  jobTemp.splice(index, 1);
                  setJobCache(jobTemp);
                  //dispatch(deleteJob(index));
                }}
                id={job._id ? job._id : ""}
                isActive={job._id === jobs.openJob}
                key={index}
                title={job.title}
                changeTitle={(changedTitle: string) => {
                  const temp_job = { ...job, title: changedTitle };
                  let new_collection = [...jobCache];
                  new_collection[index] = temp_job;
                  setJobCache(new_collection);
                }}
                editMode={editMode}
                setActive={
                  //use confirmModal here:
                  (activeJob: string) => {
                    dispatch(setActiveJob(activeJob));
                  }
                }
              />
            ))}
          </MDBListGroup>
        </MDBCol>
      </MDBRow>
      <MDBRow className="mt-2 mb-5">
        <MDBCol size="12">
          <SaveEditBtn
            newText="Opret nyt job"
            onClickCancel={(e) => {
              setJobCache(jobs.collection);
              setEditMode(!editMode);
            }}
            onClickCreate={(e) => {
              e.preventDefault();
              getAccessTokenSilently().then((token) =>
                dispatch(
                  createDefaultJob({
                    accesstoken: token,
                    key: jobs.collection.length,
                  })
                )
              );
            }}
            editMode={editMode}
            onClickEdit={(e) => {
              setEditMode(!editMode);
              if (editMode) {
                getAccessTokenSilently().then((token) =>
                  dispatch(
                    updateJobCollection({
                      accesstoken: token,
                      collection: jobCache,
                    })
                  )
                );
              }
            }}
          />
        </MDBCol>
      </MDBRow>
    </>
  );
};
export default LeftJobList;
