 
import { ECriteriaOperator } from "doweb-alfred-backend-client";
import { MDBBtn, MDBSelect } from "mdb-react-ui-kit";
import React from "react";
import { getOperator } from "./OperatorMap";

interface Props {
  setOperator: Function;
  selectedOperator: ECriteriaOperator;
  selectedFieldType: string; //"Int" | "String" | "DateTime" | "Bool" | "Decimal" | "Date" | "Associate" | "Lists" | "Unary" | "";
}

//This will take any fieldtype and return elements that contain selection elements providing operators for that fieldtype
export function CriteriaOperatorSelector(props: Props) { 
 
  const boolSelector = () => {
    return (
      <MDBBtn block outline color="dark" disabled>
        Er
      </MDBBtn>
    );
  };
  const operatorSelector = getOperator(
    props.selectedFieldType,
    props.selectedOperator
  );
  return (
    <>
      {operatorSelector.length > 0 && <MDBSelect onValueChange={(e : any) => props.setOperator(e.value)} data={operatorSelector} />}
      {props.selectedFieldType === "bool" && boolSelector()}
    </>
  );
}
