// React
import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Hooks
import { useAuth0 } from "@auth0/auth0-react";
import "./App.css";
import { MDBContainer } from "mdb-react-ui-kit";
import Footer from "./components/Footer";
import Sidenav from "./components/Sidenav";
import Topnav from "./components/Topnav";
import Profile from "./views/Profile";
import Jobs from "./views/Jobs";
import Test from "./views/Test";
import { useDispatch, useSelector } from "react-redux";
import { EAsyncLoadStatus } from "./assets/types/General.d";
import { AppDispatch, RootState } from "./store";
import { fetchCurrentUser, getUserStatus, getUserError } from "./store/features/UserSlice";
import { LoadingScreen } from "./views/LoadingScreen";
import { fetchJobs, getJobInitialLoad, getJobError } from "./store/features/JobSlice";
import {
  buildAssociateTree,
  fetchAssociates,
  fetchCRMScripts,
  fetchFields,
  fetchGroups,
  fetchTemplates,
} from "./store/features/ListSlice";
import { EmailTemplate } from "./views/EmailTemplate/basic";
import Link from "./views/Link";
import { fetchActions, getActionError, getActionInitialLoad } from "./store/features/ActionSlice";
import { Action } from "./views/Action";



function App() {
  const auth0 = useAuth0();
  const dispatch = useDispatch<AppDispatch>();
  const [initialLoad, setInitialLoad] = useState(false);
  const userStatus = useSelector(getUserStatus);
  const userError = useSelector(getUserError);
  const jobStatus = useSelector(getJobInitialLoad);
  const jobError = useSelector(getJobError);
  const actionStatus = useSelector(getActionInitialLoad);
  const actionError =  useSelector(getActionError);
  const associateStatus = useSelector(
    (state: RootState) => state.list.associates.status
  );
  const groupStatus = useSelector(
    (state: RootState) => state.list.groups.status
  );
  const fieldStatus = useSelector(
    (state: RootState) => state.list.fields.status
  );
  const crmscriptStatus = useSelector(
    (state: RootState) => state.list.crmscripts.status
  );
  const templateStatus = useSelector(
    (state: RootState) => state.list.templates.status
  );
  const associateError = useSelector(
    (state: RootState) => state.list.associates.error
  );
  const groupError = useSelector(
    (state: RootState) => state.list.groups.error
  );
  const fieldError = useSelector(
    (state: RootState) => state.list.fields.error
  );
  const crmscriptError = useSelector(
    (state: RootState) => state.list.crmscripts.error
  );
  const templateError = useSelector(
    (state: RootState) => state.list.templates.error
  );
  useEffect(() => {
    if (auth0.isAuthenticated && userStatus === EAsyncLoadStatus.idle)
      auth0.getAccessTokenSilently().then((token) => {
        dispatch(fetchCurrentUser(token));
      });
    if (userStatus === EAsyncLoadStatus.success) {
      auth0.getAccessTokenSilently().then((token) => {
        dispatch(fetchJobs(token));
        dispatch(fetchActions(token));
        dispatch(fetchAssociates(token));
        dispatch(fetchGroups(token));
        dispatch(fetchFields(token));
        dispatch(fetchTemplates(token));
        dispatch(fetchCRMScripts(token));
      });
    }
  }, [userStatus, dispatch, auth0]);
  useEffect(() => {
    if (
      associateStatus === EAsyncLoadStatus.success &&
      groupStatus === EAsyncLoadStatus.success
    ) {
      dispatch(buildAssociateTree());
    }
  }, [associateStatus, groupStatus, dispatch]);

  const jobs = [
    {
      name: "Henter bruger fra databasen...",
      status: userStatus,
      error: String(userError)
    },
    {
      name: "Henter alle jobs...",
      status: jobStatus,
      error: String(jobError)
    },
    {
      name: "Henter alle Actions...",
      status: actionStatus,
      error: String(actionError)
    },
    {
      name: "Henter alle templates...",
      status: templateStatus,
      error: String(templateError)
    },
    {
      name: "Henter listen over brugere i SuperOffice...",
      status: associateStatus,
      error: String(associateError)
    },
    {
      name: "Henter listen over grupper i SuperOffice...",
      status: groupStatus,
      error: String(groupError)
    },
    {
      name: "Henter mulige felter i SuperOffice...",
      status: fieldStatus,
      error: String(fieldError)
    },

    {
      name: "Henter alle CRMScripts fra SuperOffice...",
      status: crmscriptStatus,
      error: String(crmscriptError)
    },

  ];
  const jobLengthSuccess = jobs.filter((job) => job.status === EAsyncLoadStatus.success).length;
  const jobLengthTotal = jobs.length;
  useEffect(() => {
    if (
      jobLengthSuccess === jobLengthTotal &&
      !initialLoad
    ) {
      setTimeout(() => {
        setInitialLoad(true);
      }, 1500);

      
    }
  }, [jobLengthSuccess, jobLengthTotal, initialLoad]);

  if (auth0.error) {
    return <div>Oops... {auth0.error?.message}</div>;
  }
  
  return (
    <>
      {(auth0.isLoading || auth0.isAuthenticated) && !initialLoad && (
        <LoadingScreen fadeOut={true} title="Indlæser..." jobs={jobs} />
      )}
      {!auth0.isLoading && (
        <BrowserRouter basename={process.env.REACT_APP_BASE}> 
          <MDBContainer fluid className="mt-5" style={{ zIndex: 1 }}>
            <header>
              <Sidenav />

              <Topnav />
            </header>
            <main>
              <Routes>
                <Route path="/" element={<Jobs />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/test" element={<Test />} />
                <Route path="/actions" element={<Action />} />
                <Route path="/templates" element={<EmailTemplate />} />
                <Route path="/link/:linkId" element={<Link />} />
              </Routes>
            </main>
            <Footer />
          </MDBContainer>
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
