import { useEffect, useState } from "react";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
  MDBTabsContent,
} from "mdb-react-ui-kit";
import { Weekly } from "./Weekly";
import { Daily } from "./Daily";
import { Monthly } from "./Monthly";
import { Yearly } from "./Yearly"; 
import "./PeriodPanes.scoped.scss";
import { EPlanningPattern, IJobPlanning } from "doweb-alfred-backend-client";
interface Props {
  data: IJobPlanning;
  setData: Function;
}

export function PeriodPanes(props: Props) { 
  const [periodData, setPeriodData] = useState(props.data);
  useEffect(() => {
    setPeriodData(props.data); 
  }, [props.data, setPeriodData]);

  const handleJustifyClick = (value: EPlanningPattern) => {
    const changedPattern : IJobPlanning = {...props.data, pattern: value}
    props.setData(changedPattern);
  };
  const changeDataHandler = (changedPeriod: IJobPlanning) => {
    props.setData(changedPeriod);
  };
  return (
    <div className="periodPanes">
      <MDBTabs justify className="mb-3">
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleJustifyClick(EPlanningPattern.Daily)}
            active={periodData.pattern === EPlanningPattern.Daily}
          >
            Dagligt
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleJustifyClick(EPlanningPattern.Weekly)}
            active={periodData.pattern === EPlanningPattern.Weekly}
          >
            Ugentlig
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleJustifyClick(EPlanningPattern.Monthly)}
            active={periodData.pattern === EPlanningPattern.Monthly}
          >
            Månedlig
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleJustifyClick(EPlanningPattern.Yearly)}
            active={periodData.pattern === EPlanningPattern.Yearly}
          >
            Årlig
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>
      <MDBTabsContent>
        <MDBTabsPane show={periodData.pattern === EPlanningPattern.Daily}>
          <Daily setData={changeDataHandler} data={periodData} />
        </MDBTabsPane>
        <MDBTabsPane show={periodData.pattern === EPlanningPattern.Weekly}>
          <Weekly setData={changeDataHandler} data={periodData} />
        </MDBTabsPane>
        <MDBTabsPane show={periodData.pattern === EPlanningPattern.Monthly}>
          <Monthly setData={changeDataHandler} data={periodData} />
        </MDBTabsPane>
        <MDBTabsPane show={periodData.pattern === EPlanningPattern.Yearly}>
          <Yearly  setData={changeDataHandler}  data={periodData} />
        </MDBTabsPane>
      </MDBTabsContent>
    </div>
  );
}
