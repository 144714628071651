import React, { RefObject } from "react";
import {
  ECriteriaOperator,
  ESectionEntity,
  ISectionCriteriaField,
  ISectionCriteriaValueInner,
} from "doweb-alfred-backend-client";
import { CrtieriaValueTreeSelect } from "./CriteriaValueTreeSelect";
import { CriteriaValueToggle } from "./CriteriaValueToggle";
import { CriteriaValueBetween } from "./CriteriaValueBetween";
import { CriteriaValueSelect } from "./CriteriaValueSelect";
import { MDBBtn } from "mdb-react-ui-kit";
interface Props {
  setSelectedValue: Function;
  selectedValue: ISectionCriteriaValueInner[];
  selectedFieldType:
    | string
    | "unary"
    | "bool"
    | "associate"
    | "int"
    | "decimal"
    | "datetime"
    | "date"
    | "string"
    | "positiveString"
    | "stringorPK"
    | "lists"
    | "listAny"
    | "userGroup"
    | "listInterest";
  selectedOperator: ECriteriaOperator;
  selectedField: ISectionCriteriaField;
  sectionType: ESectionEntity;
  popoverOpen: boolean;
  setPopoverOpen: Function;
  popoverRef?: RefObject<HTMLAllCollection>;
}

//This will take any fieldtype and return elements that contain selection elements providing operators for that fieldtype
export function CriteriaValueSelector(props: Props) { 
  const valueMap: { [key: string]: any } = {
    between: {
      int: (
        <CriteriaValueBetween
          datatype={"int"}
          value={props.selectedValue}
          setValue={props.setSelectedValue}
        />
      ),
      decimal: (
        <CriteriaValueBetween
          datatype={"decimal"}
          value={props.selectedValue}
          setValue={props.setSelectedValue}
        />
      ),
      date: (
        <CriteriaValueBetween
          datatype={"date"}
          value={props.selectedValue}
          setValue={props.setSelectedValue}
        />
      ),
    },

    tree: {
      lists: {
        associateIsOneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        associateIsNotOneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        oneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        notOneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        eq: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={false}
          />
        ),
      },
      listInterest: {
        associateIsOneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        associateIsNotOneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        oneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        notOneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        eq: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={false}
          />
        ),
      },
      listAny: {
        associateIsOneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        associateIsNotOneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        oneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        notOneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        eq: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={false}
          />
        ),
      },
      userGroup: {
        associateIsOneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        associateIsNotOneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        oneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        notOneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        eq: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={false}
          />
        ),
      },
      associate: {
        currentAssociate: <></>,
        associateIsOneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        associateIsNotOneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        oneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        notOneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        eq: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={false}
          />
        ),
      },
      associateInterest: {
        associateIsOneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        associateIsNotOneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        oneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        notOneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        eq: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={false}
          />
        ),
      },
      associateAny: {
        associateIsOneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        associateIsNotOneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        oneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        notOneOf: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={true}
          />
        ),
        eq: (
          <CrtieriaValueTreeSelect
            entityType={props.sectionType}
            popoverOpen={props.popoverOpen}
            setPopoverOpen={props.setPopoverOpen}
            selectedField={props.selectedField}
            datatype={"lists"}
            value={props.selectedValue}
            setValue={props.setSelectedValue}
            multiSelect={false}
          />
        ),
      },
    },
    field: {
      string: (
        <CriteriaValueSelect
        field={props.selectedField}
          operator={props.selectedOperator}
          datatype={"string"}
          value={props.selectedValue}
          setValue={props.setSelectedValue}
        />
      ),
      positiveString: (
        <CriteriaValueSelect
        field={props.selectedField}
          operator={props.selectedOperator}
          datatype={"positiveString"}
          value={props.selectedValue}
          setValue={props.setSelectedValue}
        />
      ),
      stringorPK: (
        <CriteriaValueSelect
        field={props.selectedField}
          operator={props.selectedOperator}
          datatype={"stringorPK"}
          value={props.selectedValue}
          setValue={props.setSelectedValue}
        />
      ),
      int: (
        <CriteriaValueSelect
        field={props.selectedField}
          operator={props.selectedOperator}
          datatype={"int"}
          value={props.selectedValue}
          setValue={props.setSelectedValue}
        />
      ),
      decimal: (
        <CriteriaValueSelect
        field={props.selectedField}
          operator={props.selectedOperator}
          datatype={"decimal"}
          value={props.selectedValue}
          setValue={props.setSelectedValue}
        />
      ),
      datetime: (
        <CriteriaValueSelect
        field={props.selectedField}
          operator={props.selectedOperator}
          datatype={"datetime"}
          value={props.selectedValue}
          setValue={props.setSelectedValue}
        />
      ),
      date: (
        <CriteriaValueSelect
        field={props.selectedField}
          operator={props.selectedOperator}
          datatype={"date"}
          value={props.selectedValue}
          setValue={props.setSelectedValue}
        />
      ),
    },
    toggle: (
      <CriteriaValueToggle
        value={props.selectedValue}
        datatype={"bool"}
        setValue={props.setSelectedValue}
      />
    ),
  };
  const fieldMap: { [key: string]: any } = {
    unary: {},
    bool: { set: valueMap["toggle"] },
    associate: {
      associateIsOneOf: valueMap["tree"]["associate"]["associateIsOneOf"],
      associateIsNotOneOf: valueMap["tree"]["associate"]["associateIsNotOneOf"],
      currentAssociate: valueMap["tree"]["associate"]["currentAssociate"],
    },
    int: {
      eq: valueMap["field"]["int"],
      ne: valueMap["field"]["int"],
      lt: valueMap["field"]["int"],
      gt: valueMap["field"]["int"],
      between: valueMap["between"]["int"],
    },
    decimal: {
      eq: valueMap["field"]["decimal"],
      ne: valueMap["field"]["decimal"],
      lt: valueMap["field"]["decimal"],
      gt: valueMap["field"]["decimal"],
      between: valueMap["between"]["decimal"],
    },
    datetime: {
      //["dateTime","beforeTime","afterTime"];
      beforeTime: valueMap["field"]["datetime"],
      afterTime: valueMap["field"]["datetime"],
      dateTime: valueMap["field"]["datetime"],
    },
    date: {
      // "before","date","after","between"
      before: valueMap["field"]["date"],
      eq: valueMap["field"]["date"],
      after: valueMap["field"]["date"],
      between: valueMap["between"]["date"],
    },
    string: {
      begins: valueMap["field"]["string"],
      contains: valueMap["field"]["string"],
      is: valueMap["field"]["string"],
      notBegins: valueMap["field"]["string"],
      notContains: valueMap["field"]["string"],
      isNot: valueMap["field"]["string"],
    },
    positiveString: {
      begins: valueMap["field"]["positiveString"],
      contains: valueMap["field"]["positiveString"],
      is: valueMap["field"]["positiveString"],
      notBegins: valueMap["field"]["positiveString"],
      notContains: valueMap["field"]["positiveString"],
      isNot: valueMap["field"]["positiveString"],
    },
    stringorPK: {
      begins: valueMap["field"]["stringorPK"],
      contains: valueMap["field"]["stringorPK"],
      is: valueMap["field"]["stringorPK"],
      notBegins: valueMap["field"]["stringorPK"],
      notContains: valueMap["field"]["stringorPK"],
      isNot: valueMap["field"]["stringorPK"],
    },
    lists: {
      eq: valueMap["tree"]["lists"]["eq"],
      oneOf: valueMap["tree"]["lists"]["oneOf"],
      notOneOf: valueMap["tree"]["lists"]["notOneOf"],
    },
    listAny: {
      eq: valueMap["tree"]["listAny"]["eq"],
      oneOf: valueMap["tree"]["listAny"]["oneOf"],
      notOneOf: valueMap["tree"]["listAny"]["notOneOf"],
    },
    userGroup: {
      eq: valueMap["tree"]["userGroup"]["eq"],
      oneOf: valueMap["tree"]["userGroup"]["oneOf"],
      notOneOf: valueMap["tree"]["userGroup"]["notOneOf"],
    },
    listInterest: {
      eq: valueMap["tree"]["listInterest"]["eq"],
      oneOf: valueMap["tree"]["listInterest"]["oneOf"],
      notOneOf: valueMap["tree"]["listInterest"]["notOneOf"],
    },
  }; 
  //if array contains the selectedFieldType, then return the fieldMap[selectedField]
  if (typeof fieldMap[props.selectedFieldType] !== "undefined") {
    //if the operator is found inside the fieldMap, then return the fieldMap[selectedField][operator]

    if (
      typeof fieldMap[props.selectedFieldType][props.selectedOperator] !==
      "undefined"
    ) {
      return fieldMap[props.selectedFieldType][props.selectedOperator];
    } else
      return (
        <MDBBtn disabled outline color="dark">
          Operator ikke nødvendig..
        </MDBBtn>
      );
  } else return <></>;
}
