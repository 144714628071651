import React, { useEffect } from "react";
import danishTranslation from "../../../assets/config/datepickerTranslation.json";
import {
  MDBCol,
  MDBRow,
  MDBSelect,
  MDBCheckbox,
  MDBTimepicker,
  MDBDatepicker,
} from "mdb-react-ui-kit";
import {
  IJobPlanning,
  EPlanningMonthNames,
  EPlanningSubPattern
} from "doweb-alfred-backend-client";
 
import moment from "moment";

interface Props {
  data: IJobPlanning;
  setData: Function;
}

let uiTimeHour = 0;
let uiTimeMinutes = 0;
let uiDate = 0;
let uiMonth = 0;
let uiYear = 0;

export function Monthly(props: Props) {
  const changeMonthsHandler = (month: EPlanningMonthNames, add: boolean) => {
    let changedData = [...props.data.months];
    if (add) {
      changedData.push(month);
    } else {
      changedData.splice(changedData.indexOf(month), 1);
    }
    props.setData({ ...props.data, months: changedData });
  };

//segment to deal with the weird bootstrap behaviour related to date
////////////////////////////////////////////////////////////////
useEffect(() => { //set initial value of the time-of-day widget. Only runs once.
  //const day = new Date(props.data.startDate);
  uiTimeHour = parseInt(moment(props.data.startDate).format("HH"));
  uiTimeMinutes = parseInt(moment(props.data.startDate).format("mm"));
  uiDate = parseInt(moment(props.data.startDate).format("DD"));
  uiMonth = parseInt(moment(props.data.startDate).format("MM"));
  uiYear = parseInt(moment(props.data.startDate).format("YYYY"));
}, );
const changeDateHandler = (element: string) => { 
  const tempDay = parseInt(moment(element, "DD/MM/YYYY").format("DD"));
  const tempMonth = parseInt(moment(element, "DD/MM/YYYY").format("MM")) - 1;///////////THIS SHOULD NEVER BE NECESSARY
  const tempYear = parseInt(moment(element, "DD/MM/YYYY").format("YYYY"));
  if(uiDate !== tempDay || uiMonth !== tempMonth || uiYear !== tempYear){
    uiDate = tempDay; uiMonth = tempMonth; uiYear = tempYear;
    let tempDate = moment(props.data.startDate);
    tempDate.set('date', tempDay);
    tempDate.set('month', tempMonth);
    tempDate.set('year', tempYear);
    props.setData({
      ...props.data,
      startDate: tempDate.toISOString(),
    })
  }
}
//Function that handles the conversion of time to two different variables containing minutes and hours, and updates the prop with the new value
const changeTimedayHandler = (time: string) => {
  const changedTime = time.split(":");
  const changedHour = parseInt(changedTime[0]);
  const changedMinutes = parseInt(changedTime[1]);
  if(uiTimeHour !== changedHour || uiTimeMinutes !== changedMinutes){
    uiTimeHour = changedHour;
    uiTimeMinutes = changedMinutes;
    let newStartDate = moment(props.data.startDate).set('hour', changedHour).set('minute', changedMinutes);//moment(props.data.startDate);
   
    props.setData({
      ...props.data,
      startDate: newStartDate.toISOString(),
    })
  }
};
////////////////////////////////////////////////////////////////

  const changeFrequencyHandler = (day: Number) => {
    props.setData({ ...props.data, cycleMonth: day });
  };

  const selectDays = [];
  for (let days = 1; days < 29; days++) {
    selectDays.push(days);
  }

  return (
    <>
      <MDBRow>
        <MDBCol>
          <MDBRow>
            <span className="time-input">
              {" "}
              <MDBSelect
                label="Frekvens"
                onValueChange={(e: any) => changeFrequencyHandler(e.value)}
                data={selectDays.map((day, index) => {
                  if (day === 1) {
                    return {
                      text: "Første dag",
                      value: EPlanningSubPattern.Startof,
                      defaultSelected: day === props.data.cycleMonth,
                    };
                  } else if (day === 28) {
                    return {
                      text: "Sidste dag",
                      value: EPlanningSubPattern.Endof,
                      defaultSelected: day === props.data.cycleMonth,
                    };
                  } else {
                    return {
                      text: "Dag " + day,
                      value: day,
                      defaultSelected: day === props.data.cycleMonth,
                    };
                  }
                })}
              />
            </span>
            &nbsp;, med start d. &nbsp;
            <span className="time-input">
            <MDBDatepicker
              inputLabel="Startdato"
              {...danishTranslation}
              value={moment(props.data.startDate).format("DD/MM/YYYY")}
              onChange={(value:string) =>{
                changeDateHandler(value);
              }}
            />
            </span>
          </MDBRow>
          <MDBRow className="mt-3">
            klokken&nbsp;
            <span className="time-input">
            <MDBTimepicker
              inputID="monthTimePicker"
              defaultValue={moment(props.data.startDate).format('HH:mm')}
              inputLabel="Tidspunkt"
              onClose={() => { // needs onClose instead of onChange because bootstrap messed up; new version should fix it
                const element = document.querySelector("#monthTimePicker");
                const value = element!.getAttribute("value");
                changeTimedayHandler(value!);
              }}
              onBlur={() => { // needs onClose instead of onChange because bootstrap messed up; new version should fix it
                const element = document.querySelector("#monthTimePicker");
                const value = element!.getAttribute("value");
                changeTimedayHandler(value!);
              }}
              inline
              format="24h"
            />
            </span>
            &nbsp;i følgende måneder:
          </MDBRow>
        </MDBCol>
      </MDBRow>
      <MDBRow className="mt-4">
        <MDBCol size={6}>
          <div className="d-flex flex-wrap align-items-start flex-column">
            <MDBCheckbox
              value="1"
              name="Check1"
              onChange={(el) => {
                changeMonthsHandler(EPlanningMonthNames.Jan, el.target.checked);
              }}
              checked={props.data.months.some(
                (month) => month === EPlanningMonthNames.Jan
              )}
              id="Check1"
              label="Januar"
            />
            <MDBCheckbox
              value="2"
              name="Check2"
              onChange={(el) => {
                changeMonthsHandler(EPlanningMonthNames.Feb, el.target.checked);
              }}
              checked={props.data.months.some(
                (month) => month === EPlanningMonthNames.Feb
              )}
              id="Check2"
              label="Februar"
            />
            <MDBCheckbox
              value="3"
              name="Check3"
              onChange={(el) => {
                changeMonthsHandler(EPlanningMonthNames.Mar, el.target.checked);
              }}
              checked={props.data.months.some(
                (month) => month === EPlanningMonthNames.Mar
              )}
              id="Check3"
              label="Marts"
            />
            <MDBCheckbox
              value="4"
              name="Check4"
              onChange={(el) => {
                changeMonthsHandler(EPlanningMonthNames.Apr, el.target.checked);
              }}
              checked={props.data.months.some(
                (month) => month === EPlanningMonthNames.Apr
              )}
              id="Check4"
              label="April"
            />
            <MDBCheckbox
              value="5"
              name="Check5"
              onChange={(el) => {
                changeMonthsHandler(EPlanningMonthNames.May, el.target.checked);
              }}
              checked={props.data.months.some(
                (month) => month === EPlanningMonthNames.May
              )}
              id="Check5"
              label="Maj"
            />
            <MDBCheckbox
              value="6"
              name="Check6"
              onChange={(el) => {
                changeMonthsHandler(EPlanningMonthNames.Jun, el.target.checked);
              }}
              checked={props.data.months.some(
                (month) => month === EPlanningMonthNames.Jun
              )}
              id="Check6"
              label="Juni"
            />
          </div>
        </MDBCol>
        <MDBCol size={6}>
          <div className="d-flex flex-wrap align-items-start flex-column">
            <MDBCheckbox
              value="7"
              name="Check7"
              onChange={(el) => {
                changeMonthsHandler(EPlanningMonthNames.Jul, el.target.checked);
              }}
              checked={props.data.months.some(
                (month) => month === EPlanningMonthNames.Jul
              )}
              id="Check7"
              label="Juli"
            />
            <MDBCheckbox
              value="8"
              name="Check8"
              onChange={(el) => {
                changeMonthsHandler(EPlanningMonthNames.Aug, el.target.checked);
              }}
              checked={props.data.months.some(
                (month) => month === EPlanningMonthNames.Aug
              )}
              id="Check8"
              label="August"
            />
            <MDBCheckbox
              value="9"
              name="Check9"
              onChange={(el) => {
                changeMonthsHandler(EPlanningMonthNames.Sep, el.target.checked);
              }}
              checked={props.data.months.some(
                (month) => month === EPlanningMonthNames.Sep
              )}
              id="Check9"
              label="September"
            />
            <MDBCheckbox
              value="10"
              name="Check10"
              onChange={(el) => {
                changeMonthsHandler(EPlanningMonthNames.Oct, el.target.checked);
              }}
              checked={props.data.months.some(
                (month) => month === EPlanningMonthNames.Oct
              )}
              id="Check10"
              label="Oktober"
            />
            <MDBCheckbox
              value="11"
              name="Check11"
              onChange={(el) => {
                changeMonthsHandler(EPlanningMonthNames.Nov, el.target.checked);
              }}
              checked={props.data.months.some(
                (month) => month === EPlanningMonthNames.Nov
              )}
              id="Check11"
              label="November"
            />
            <MDBCheckbox
              value="12"
              name="Check12"
              onChange={(el) => {
                changeMonthsHandler(EPlanningMonthNames.Dec, el.target.checked);
              }}
              checked={props.data.months.some(
                (month) => month === EPlanningMonthNames.Dec
              )}
              id="Check12"
              label="December"
            />
          </div>
        </MDBCol>
      </MDBRow>
    </>
  );
}
