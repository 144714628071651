import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"; 
import { EAsyncLoadStatus, IInitialActionState  } from "../../assets/types/General.d";
import {
  ActionApi,
  Configuration,
  ISectionAction,
  IDeleteArray,
  ESectionEntity,
  IDefaultSectionActionType,
} from "doweb-alfred-backend-client";
import { RootState } from ".."; 

const initialState : IInitialActionState = {
  collection: [],
  status: EAsyncLoadStatus.idle,
  initialLoad: EAsyncLoadStatus.idle,
  error: null,
  openEntity: ESectionEntity.Contact
};

/*
The thunkData interfaces all enable the createAsyncThunk function to only take a single parameter
They enable the passing of any parameters defined in the interface to the various async functions
*/

interface editActionThunk {
  accesstoken: string;
  Action: ISectionAction; 
}

interface updateActionThunk {
  accesstoken: string;
  collection: ISectionAction[];
}

interface deleteActionThunk {
  accesstoken: string;
  id: string;
}

interface createDefaultActionThunk {
  accesstoken: string;
  entity: ESectionEntity;
}


/*
Creates a default Action on the server.
*/
export const createDefaultAction = createAsyncThunk(
  "action/createDefaultAction",
  async (input: createDefaultActionThunk, thunkApi) => {
    try {
      const action = new ActionApi(
        new Configuration({
          accessToken: input.accesstoken,
          basePath: process.env.REACT_APP_BACKEND_BASE_URL,
        })
      );
      const defaultInput : IDefaultSectionActionType = {entity: input.entity};
      const response = await action.actionCreateDefaultPost(defaultInput);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);


/*
  Called whenever editmode ends in the left Action list.n
  Sends the full Action collection off to the server;
  returns the updated collection only if save is pressed
  must eventually return the collection from the server
  Handles bulk deletions and renames
*/

export const updateActionCollection = createAsyncThunk(
  "action/updateActionCollection",
  async (input: updateActionThunk, thunkApi) => {
    try {
      const action =
      new ActionApi(
        new Configuration({
          accessToken: input.accesstoken,
          basePath: process.env.REACT_APP_BACKEND_BASE_URL,
        })
      );
      // Get IDS of the new collection to figure out which has been deleted
      const theChangedActionCollection = input.collection.map((action) => action._id);
      const getTheActionState = (): IInitialActionState => {
        const state = thunkApi.getState() as RootState;
        return state.action;
      };
      const theCurrentActionCollection = getTheActionState().collection;
      const theDeletedIds : IDeleteArray[] = theCurrentActionCollection
        .filter((action) => theChangedActionCollection.indexOf(action._id) === -1)
        .map((action) => {
          return {'_id': action._id} as IDeleteArray;
        });
      //Now send the ids to the delete function. Please configure backend to be able to receive several id's instead of just one.
      await action.actionDelete(theDeletedIds);

      //After that update the collection of remaining Actions by sending a collection of Actions to the server, same as edit Action, but configured to receive an array instead.
      await action.actionPut(input.collection);


      //Pass on the changed collection to the thunk actions, in order to update the state: (Keep as is)
      return input.collection;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

/*
  Called whenever an edited Action is saved from a single Action pane.
  Handles the updating of most of the Action details
*/

export const editAction = createAsyncThunk(
  "action/editAction",
  async (input: editActionThunk, thunkApi) => {
    try {
      const action = new ActionApi(
        new Configuration({
          accessToken: input.accesstoken,
          basePath: process.env.REACT_APP_BACKEND_BASE_URL,
        })
      );

       await action.actionIdPut(String(input.Action._id), input.Action); 
      return { Action: input.Action };
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

/*
  When a single Action is deleted via the general form, this method is called.
  It currently has a malfunction (and perhaps it shouldn't even be in the UI, as it duplicates functionality in an odd place)
*/
export const deleteAction = createAsyncThunk(
  "action/deleteAction",
  async (input: deleteActionThunk, thunkApi) => {
    try {
      const action =
      new ActionApi(
        new Configuration({
          accessToken: input.accesstoken,
          basePath: process.env.REACT_APP_BACKEND_BASE_URL,
        })
      );
      const getTheActionState = (): IInitialActionState => {
        const state = thunkApi.getState() as RootState;
        return state.action;
      };
      const theCurrentActionCollection = getTheActionState().collection;
      await action.actionIdDelete(String(input.id));
      //Return theCurrentActionCollection without containing the deleted Action
      return theCurrentActionCollection.filter((action) => action._id !== input.id);
      
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

/*
Gets all the Actions from the server
*/
export const fetchActions = createAsyncThunk(
  "action/fetchActions",
  async (accesstoken: string, thunkApi) => {
    try {
      const action = new ActionApi(
        new Configuration({
          accessToken: accesstoken,
          basePath: process.env.REACT_APP_BACKEND_BASE_URL,
        })
      );
      const response = await action.actionGet();
      return response.data.list;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);


export const ActionSlice = createSlice({
  name: "action",
  initialState: initialState,
  reducers: {
    setActiveEntity: (state, action) => {
      state.openEntity = action.payload;
    },
  },
  extraReducers: (builder) => {
    /*
      This segment handles the asynchronous responsibilities of updating the entire Action collection (larger delete operations)
    */
    builder
      .addCase(updateActionCollection.fulfilled, (state, action) => {
        state.collection = action.payload;
        state.status = EAsyncLoadStatus.idle;
      })
      .addCase(updateActionCollection.pending, (state, action) => {
        state.status = EAsyncLoadStatus.loading;
      })
      .addCase(updateActionCollection.rejected, (state, action) => {
        state.status = EAsyncLoadStatus.error;
        state.error = action.payload as string;
      })
      /*
      This segment handles the asynchronous responsibilities of Action editing (updating an edited Action)
    */
      .addCase(editAction.fulfilled, (state, action) => {
        //Find the index of the action inside state.collection from its id
        const theActionIndex = state.collection.findIndex(
          (theAction) => theAction._id === action.payload.Action._id
        );
        state.collection[theActionIndex] = action.payload.Action;
        state.status = EAsyncLoadStatus.idle;
      })
      .addCase(editAction.pending, (state, action) => {
        state.status = EAsyncLoadStatus.loading;
      })
      .addCase(editAction.rejected, (state, action) => {
        state.status = EAsyncLoadStatus.error;
        state.error = action.payload as string;
      })
      /*
      This segment handles the asynchronous responsibilities of Action deletion
    */
      .addCase(deleteAction.fulfilled, (state, action) => {
        //action.payload.splice(action.payload, 1);
        state.collection = action.payload;
        state.status = EAsyncLoadStatus.idle;
      })
      .addCase(deleteAction.pending, (state, action) => {
        state.status = EAsyncLoadStatus.loading;
      })
      .addCase(deleteAction.rejected, (state, action) => {
        state.status = EAsyncLoadStatus.error;
        state.error = action.payload as string;
      })
      /*
      This segment handles the asynchronous responsibilities of section deletion
    */

      /*
      This segment handles the creation of new Actions
    */
      .addCase(createDefaultAction.fulfilled, (state, action) => {
        state.collection.push(action.payload);
        state.status = EAsyncLoadStatus.idle;
      })
      .addCase(createDefaultAction.pending, (state, action) => {
        state.status = EAsyncLoadStatus.loading;
      })
      .addCase(createDefaultAction.rejected, (state, action) => {
        state.status = EAsyncLoadStatus.error;
        state.error = action.payload as string;
      })

      /*
      This segment fetches all existing Actions
    */
      .addCase(fetchActions.fulfilled, (state, action) => {
        state.collection = action.payload;
        state.initialLoad = EAsyncLoadStatus.success;
      })
      .addCase(fetchActions.pending, (state, action) => {
        state.initialLoad = EAsyncLoadStatus.loading;
      })
      .addCase(fetchActions.rejected, (state, action) => {
        state.initialLoad = EAsyncLoadStatus.error;
        state.error = action.payload as string;
      });
  },
});

// Action creators are generated for each case reducer function
export const {setActiveEntity} = ActionSlice.actions;
export const getActionCollection = (state: RootState) => state.action.collection;
export const getOpenEntity = (state: RootState) => state.action.openEntity;
export const getActionStatus = (state: RootState) => state.action.status;
export const getActionError = (state: RootState) => state.action.error;
export const getActionInitialLoad = (state: RootState) => state.action.initialLoad;
export default ActionSlice.reducer;
