import React, { useEffect, useState } from "react";
import {
  Configuration,
  ESectionEntity,
  ISectionCriteriaField,
  ISectionCriteriaValueInner,
  ListApi,
} from "doweb-alfred-backend-client";
import { TreeSelect } from "../../../../TreeSelect/TreeSelect";
import { MDBBtn, MDBChip, MDBSpinner } from "mdb-react-ui-kit";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";

/*
Multiselect + singleselect
Prop.datatype: ’list’ | ‘associate’
Prop.multiselect: bool
Prop.value: array<number>
Prop.setValue: function<array>
*/
//value has datatype object for now, but should be typed later using a subtype hierarchy
interface Props {
  datatype:
    | "lists"
    | "listInterest"
    | "listAny"
    | "userGroup"
    | "associate"
    | "associateInterest"
    | "associateAny";
  value: ISectionCriteriaValueInner[];
  setValue: Function;
  multiSelect: Boolean;
  entityType: ESectionEntity;
  selectedField: ISectionCriteriaField;
  popoverOpen: boolean;
  setPopoverOpen: Function;
}
export function CrtieriaValueTreeSelect(props: Props) {
  const [theValue, setTheValue] = useState(props.value);
  //state for the field
  const [field, setField] = useState(props.selectedField);
  const [loading, setLoading] = useState(true);
 
  const {getAccessTokenSilently} = useAuth0();

  const [treeData, setTreeData] = useState<any[]>([]);

  const associateGroups = useSelector((state: RootState) =>
    state.list.groups.list.map((group, index) => {
      return {
        Id: group.groupId,
        key: group.groupId,
        Name: group.name,
        Type: "group",
        ExtraInfo: "associateGroup",
        ChildItems: group.associates.map((associate, index) => {
          return {
            Id: associate.associateId,
            Name: associate.name,
            Type: "associate",
            ExtraInfo: "associate",
            ChildItems: [],
          };
        }),
      };
    })
  );
  //useEffect to update the value when the value changes
  useEffect(() => {
    console.log("useEffect value");
    console.log(props.value);
    setTheValue(props.value);  
  }, [props.value, setTheValue]);
    //useEffect to update the field when the field changes
    useEffect(() => {
      console.log("useEffect field");
      setField(props.selectedField);  
      setTreeData([]);
    }, [props.selectedField, setField]);

  //UseEffect to get the data from the backend
  useEffect(() => {
    if(treeData.length === 0) {
    console.log("useEffect called fetch data");
    if (field.id.includes("associate" || "Associate")) {
      setTreeData(associateGroups);
    }
    else{
     getAccessTokenSilently()
      .then((token) => {
        const lists = new ListApi(
          new Configuration({
            accessToken: token,
            basePath: process.env.REACT_APP_BACKEND_BASE_URL,
          })
        );
        if (field.id.includes("Udef/")) {
          //Extract everything after the "Udef/" in the field id
          const fieldId = field.id.split("Udef/")[1];

          lists
            .listGetListByFieldProgIdGet(props.entityType, fieldId)
            .then((res) => {
              setTreeData(res.data);
            })
            .catch((err: any) => {
              console.log(err);
              
            });
        } else if (field.listId !== undefined) {
          //Get the list from the backend
          lists
            .listGetListByMDONameMDOListNameGet(field.listId)
            .then((res) => {
              setTreeData(res.data);
            })
            .catch((err: any) => {
              console.log(err);
              
            });
        }
      })
      .catch((err) => {
        console.log(err); 
      });
    }
    }
  }, [getAccessTokenSilently, field, props.entityType, associateGroups, treeData.length]);
  
  //Create useEffect to update loading when the treeData is updated
  useEffect(() => {
    if (treeData.length > 0) {
      setLoading(false);
    }
  }, [treeData, setLoading]);

  const multiSelectHandler = (e: any) => {
    if (theValue.find((obj) => obj.value === e.id.toString()) === undefined) {
      const newSelectedItems = [
        ...theValue,
        { label: e.label, value: e.id.toString() },
      ];
      //save to props.setValue
      props.setValue(newSelectedItems);
    }
  };
  return (
    <>
      {loading ? (
        <MDBBtn block outline color="dark" disabled>
          <MDBSpinner size="sm" />
        </MDBBtn>
      ) : (
        props.multiSelect && ( 
          <div className="d-flex" style={{flexWrap: "wrap"}}>
            {theValue.map((e, index) => {
              return (
                <MDBChip
                  onDelete={() => {
                    const newValues = [
                      ...theValue.filter((val) => val.value !== e.value),
                    ];
                    props.setValue(newValues);
                  }}
                  closeIcon
                  key={e.value}
                  color="primary"
                >
                  {e.label}
                </MDBChip>
              );
            })}
          </div> 
        )
      )}
      {!loading && (
        <TreeSelect
          label={
            props.multiSelect
              ? "Vælg flere"
              : theValue.length === 0
              ? "Vælg en værdi"
              : theValue[0].label
          }
          tree={treeData}
          onSelect={
            props.multiSelect
              ? multiSelectHandler
              : (e: any) => props.setValue([{ value: e.id, label: e.label }])
          }
          closeOnSelect={!props.multiSelect}
          popoverOpen={props.popoverOpen}
          setPopoverOpen={props.setPopoverOpen}
        />
      )}
    </>
  );
}
