import { GeneralSettings } from "./../../../../components/GeneralSettings";
import { SaveEditBtn } from "../../../../components/SaveEditBtn";
import { Section } from "../../../../components/Section";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import { editJob, setHasChanged } from "../../../../store/features/JobSlice";
import { ISection, ESectionEntity, IJobListItem } from "doweb-alfred-backend-client";
import "./RightJobDisplay.scoped.scss";
import { useEffect, useState } from "react";
import { MDBCol, MDBRow, MDBAccordion } from "mdb-react-ui-kit";
import { useAuth0 } from "@auth0/auth0-react";
import { ConfirmModal } from "../../../../components/ConfirmModal/ConfirmModal";
import { ModalWrapper } from "../../../../components/ModalWrapper";
 
export function RightJobDisplay() {
  const { getAccessTokenSilently } = useAuth0();
  //Create state for the confirm modal
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  //State for managing the edit mode
  const [isChangedGeneral, setIsChangedGeneral] = useState(false); 
  const [isChangedSection, setIsChangedSection] = useState(false);
 

  const dispatch = useDispatch<AppDispatch>();
  //Make useEffect to listen to changes for isChanged states
  useEffect(() => {
    if (isChangedGeneral || isChangedSection) {
      dispatch(setHasChanged(true));
    }
      
    } 
  , [isChangedGeneral, isChangedSection, dispatch]);
  
  const job = useSelector(
    (state: RootState) => state.job.collection.find(j => j._id === state.job.openJob)
  );
  const [editMode, setEditMode] = useState(false);
  if (typeof job !== "undefined") {
    let sections = job.sections;

    let editSectionClasses = ["editSectionBtn"];
    let createSectionClasses = ["createSectionBtn"];
    if (editMode) {
      editSectionClasses.push("active");
      createSectionClasses.push("inactive");
    }


   
    return (
      <>
        <MDBRow>
          {/* Konfiguration herunder */}
          <GeneralSettings jobData={job} saveJobData={(changedJob : IJobListItem) => {
            getAccessTokenSilently().then((token) => {
              dispatch(editJob({job: changedJob, accesstoken: token}));
            });
            
          }} isChanged={isChangedGeneral} setIsChanged={setIsChangedGeneral} />
        </MDBRow>
        <MDBRow className="mb-3">
          <MDBCol lg="8" md="4">
            <h3>Sektioner</h3>
          </MDBCol>
          <MDBCol lg="4" md="8">
            <SaveEditBtn
              onClickCancel={(e) => {}}
              onClickCreate={() => {
                const section: ISection = { 
                  criterias: [],
                  active: false,
                  entity: ESectionEntity.Project,
                  name: "Ny sektion " + sections.length,
                };
                getAccessTokenSilently().then((token) =>
                  dispatch(
                    editJob({accesstoken: token, job: {...job, sections: [...sections, section]}})
                  )
                );
              }}
              editMode={editMode}
              onClickEdit={(e) => setEditMode(!editMode)}
              newText="Opret ny sektion"
              hideEdit
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          {/* Sektioner herunder */}

          <MDBAccordion initialActive={1} alwaysOpen={true}>
            {sections.map((section: ISection, sectionIndex: number) => (
              <Section
              isChanged={isChangedSection} 
              setIsChanged={setIsChangedSection} 
                sectionIndex={sectionIndex}
                key={sectionIndex}
                section={section}
                setSection={(changedSection: ISection) => {
                  getAccessTokenSilently().then((token) =>
                    {
                      const newSectionArray = [...sections];
                      newSectionArray[sectionIndex] = changedSection;
                      dispatch(editJob({accesstoken: token, job: {...job, sections: newSectionArray}}));
                    });
                  }}
                  deleteSection={() => {
                    //Slice the sectionIndex from the sections array
                    getAccessTokenSilently().then((token) =>
                    {
                      const newSectionArray = [...sections];
                      newSectionArray.splice(sectionIndex, 1);
                      dispatch(editJob({accesstoken: token, job: {...job, sections: newSectionArray}}));
                    });
                  }}
              />
            ))}
          </MDBAccordion>
        </MDBRow>
        <ModalWrapper>
        <ConfirmModal
          show={showConfirmModal}
          setShow={setShowConfirmModal}
          onConfirm={() =>{}}
          onCancel={() => {}}
          title={"Warning: changes"}
          message={"are you sure?"}
        />
        </ModalWrapper>
      </>
    );
  } else {
    return <></>;
  }
}
