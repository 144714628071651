import React, { useEffect, useRef } from "react";
import danishTranslation from "../../../assets/config/datepickerTranslation.json";
import {
  MDBCol,
  MDBRow,
  MDBCheckbox,
  MDBInput,
  MDBTimepicker,
  MDBDatepicker,
} from "mdb-react-ui-kit";
import { IJobPlanning } from "doweb-alfred-backend-client";
import moment from "moment";

interface Props {
  data: IJobPlanning;
  setData: Function;
}

//segment to deal with the weird bootstrap behaviour related to date
////////////////////////////////////////////////////////////////
//UGLY
//necessary to maintain a local state check to work with bootstrap clock
let uiTimeHour = 0;
let uiTimeMinutes = 0;
let uiDate = 0;
let uiMonth = 0;
let uiYear = 0;
////////////////////////////////////////////////////////////////

export function Daily(props: Props) {
  //Ref that will handle the frequency input and update the value whenever the view rerenders.
  const freqRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (freqRef.current) {
      freqRef.current.value = props.data.cycle.toString();
    }
  }, [freqRef, props.data.cycle, props.data.startDate]);

//segment to deal with the weird bootstrap behaviour related to date
////////////////////////////////////////////////////////////////
  useEffect(() => { //set initial value of the time-of-day widget. Only runs once.
    //const day = new Date(props.data.startDate);
    uiTimeHour = parseInt(moment(props.data.startDate).format("HH"));
    uiTimeMinutes = parseInt(moment(props.data.startDate).format("mm"));
    uiDate = parseInt(moment(props.data.startDate).format("DD"));
    uiMonth = parseInt(moment(props.data.startDate).format("MM"));
    uiYear = parseInt(moment(props.data.startDate).format("YYYY"));
  }, );
  const changeDateHandler = (element: string) => {
    
    const tempDay = parseInt(moment(element, "DD/MM/YYYY").format("DD"));
    const tempMonth = parseInt(moment(element, "DD/MM/YYYY").format("MM")) - 1; ///////////THIS SHOULD NEVER BE NECESSARY
    const tempYear = parseInt(moment(element, "DD/MM/YYYY").format("YYYY"));
    if(uiDate !== tempDay || uiMonth !== tempMonth || uiYear !== tempYear){
      uiDate = tempDay; uiMonth = tempMonth; uiYear = tempYear;
      let tempDate = moment(props.data.startDate);
      tempDate.set('date', tempDay);
      tempDate.set('month', tempMonth);
      tempDate.set('year', tempYear);
      props.setData({
        ...props.data,
        startDate: tempDate.toISOString(),
      })
    }
  }
  //Function that handles the conversion of time to two different variables containing minutes and hours, and updates the prop with the new value
  const changeTimedayHandler = (time: string) => {
    const changedTime = time.split(":");
    const changedHour = parseInt(changedTime[0]);
    const changedMinutes = parseInt(changedTime[1]);
    if(uiTimeHour !== changedHour || uiTimeMinutes !== changedMinutes){
      uiTimeHour = changedHour;
      uiTimeMinutes = changedMinutes;
      let newStartDate = moment(props.data.startDate).set('hour', changedHour).set('minute', changedMinutes);//moment(props.data.startDate);
  
      props.setData({
        ...props.data,
        startDate: newStartDate.toISOString(),
      })
    }
  };
////////////////////////////////////////////////////////////////

  return (
    <MDBRow>
      <MDBCol>
        <MDBRow>
          Frekvens hver:&nbsp;
          <span className="frequency-input">
            <MDBInput
              defaultValue={props.data.cycle.toString()}
              ref={freqRef}
              onChange={(e) =>
                props.setData({
                  ...props.data,
                  cycle: parseInt(e.target.value),
                })
              }
              min="1"
              max="52"
              type="number"
            />
          </span>
          &nbsp;dag, med start d. &nbsp;
          <span className="time-input">
            <MDBDatepicker
              inputLabel="Startdato"
              {...danishTranslation}
              value={moment(props.data.startDate).format("DD/MM/YYYY")}
              onChange={(value:string) =>{
                changeDateHandler(value);
              }}
            />
          </span>
        </MDBRow>
        <MDBRow className="mt-3">
          klokken&nbsp;
          <span className="time-input">
            <MDBTimepicker
              inputID="dayTimePicker"
              defaultValue={moment(props.data.startDate).format('HH:mm')}
              inputLabel="Tidspunkt"
              onClose={() => { // needs onClose instead of onChange because bootstrap messed up; new version should fix it
                const element = document.querySelector("#dayTimePicker");
                const value = element!.getAttribute("value");
                changeTimedayHandler(value!);
              }}
              onBlur={() => { // needs onClose instead of onChange because bootstrap messed up; new version should fix it
                const element = document.querySelector("#dayTimePicker");
                const value = element!.getAttribute("value");
                changeTimedayHandler(value!);
              }}
              inline
              format="24h"
            />
          </span>
        </MDBRow>
        <MDBRow className="mt-3">
          <MDBCheckbox
            id="CheckWeekend"
            onChange={(el) =>
              props.setData({ ...props.data, skipWeekend: el.target.checked })
            }
            checked={props.data.skipWeekend}
            label="Send ikke i weekenden"
          />
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
}

