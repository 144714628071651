import React, { useEffect, useRef } from "react";
import danishTranslation from "../../../assets/config/datepickerTranslation.json";
import {
  MDBCol,
  MDBRow,
  MDBCheckbox, 
  MDBTimepicker,
  MDBDatepicker,
} from "mdb-react-ui-kit";
import { EPlanningWeekDays, IJobPlanning } from "doweb-alfred-backend-client";
 
import moment from "moment";

interface Props {
  data: IJobPlanning;
  setData: Function;
}

let uiTimeHour = 0;
let uiTimeMinutes = 0;
let uiDate = 0;
let uiMonth = 0;
let uiYear = 0;

export function Weekly(props: Props) {
  const freqRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (freqRef.current) {
      freqRef.current.value = props.data.cycle.toString();
    }
  }, [freqRef, props.data.cycle]);

  const changeWeekdayHandler = (weekday: EPlanningWeekDays, add: boolean) => {
    let changedData = [...props.data.weekdays];
    if (add) {
      changedData.push(weekday);
    } else {
      changedData.splice(changedData.indexOf(weekday), 1);
    }
    props.setData({ ...props.data, weekdays: changedData });
  };

//segment to deal with the weird bootstrap behaviour related to date
////////////////////////////////////////////////////////////////
useEffect(() => { //set initial value of the time-of-day widget. Only runs once.
  //const day = new Date(props.data.startDate);
  uiTimeHour = parseInt(moment(props.data.startDate).format("HH"));
  uiTimeMinutes = parseInt(moment(props.data.startDate).format("mm"));
  uiDate = parseInt(moment(props.data.startDate).format("DD"));
  uiMonth = parseInt(moment(props.data.startDate).format("MM"));
  uiYear = parseInt(moment(props.data.startDate).format("YYYY"));
}, );
const changeDateHandler = (element: string) => {
 
  const tempDay = parseInt(moment(element, "DD/MM/YYYY").format("DD"));
  const tempMonth = parseInt(moment(element, "DD/MM/YYYY").format("MM")) - 1;///////////THIS SHOULD NEVER BE NECESSARY
  const tempYear = parseInt(moment(element, "DD/MM/YYYY").format("YYYY"));
  if(uiDate !== tempDay || uiMonth !== tempMonth || uiYear !== tempYear){
    uiDate = tempDay; uiMonth = tempMonth; uiYear = tempYear;
    let tempDate = moment(props.data.startDate);
    tempDate.set('date', tempDay);
    tempDate.set('month', tempMonth);
    tempDate.set('year', tempYear);
    props.setData({
      ...props.data,
      startDate: tempDate.toISOString(),
    })
  }
}
//Function that handles the conversion of time to two different variables containing minutes and hours, and updates the prop with the new value
const changeTimedayHandler = (time: string) => {
  const changedTime = time.split(":");
  const changedHour = parseInt(changedTime[0]);
  const changedMinutes = parseInt(changedTime[1]);
  if(uiTimeHour !== changedHour || uiTimeMinutes !== changedMinutes){
    uiTimeHour = changedHour;
    uiTimeMinutes = changedMinutes;
    let newStartDate = moment(props.data.startDate).set('hour', changedHour).set('minute', changedMinutes);//moment(props.data.startDate);
    
    props.setData({
      ...props.data,
      startDate: newStartDate.toISOString(),
    })
  }
};
////////////////////////////////////////////////////////////////

  return (
    <>
      <MDBRow>
        Start d. &nbsp;
        <span className="time-input">
            <MDBDatepicker
              inputLabel="Startdato"
              {...danishTranslation}
              value={moment(props.data.startDate).format("DD/MM/YYYY")}
              onChange={(value:string) =>{
                changeDateHandler(value);
              }}
            />
        </span>
      </MDBRow>
      <MDBRow className="mt-3">
        klokken&nbsp;
        <span className="time-input">
        <MDBTimepicker
              inputID="weekTimePicker"
              defaultValue={moment(props.data.startDate).format('HH:mm')}
              inputLabel="Tidspunkt"
              onClose={() => { // needs onClose instead of onChange because bootstrap messed up; new version should fix it
                const element = document.querySelector("#weekTimePicker");
                const value = element!.getAttribute("value");
                changeTimedayHandler(value!);
              }}
              onBlur={() => { // needs onClose instead of onChange because bootstrap messed up; new version should fix it
                const element = document.querySelector("#weekTimePicker");
                const value = element!.getAttribute("value");
                changeTimedayHandler(value!);
              }}
              inline
              format="24h"
            />
        </span>
      </MDBRow>

      <MDBRow className="mt-4">
        <MDBCol size={6}>
          <div className="d-flex flex-wrap align-items-start flex-column">
            <MDBCheckbox
              name="Check1"
              onChange={(el) => {
                changeWeekdayHandler(EPlanningWeekDays.Mon, el.target.checked);
              }}
              checked={props.data.weekdays.some(
                (weekday) => weekday === EPlanningWeekDays.Mon
              )}
              id="Check1"
              label="Mandag"
            />
            <MDBCheckbox
              name="Check2"
              onChange={(el) => {
                changeWeekdayHandler(EPlanningWeekDays.Tue, el.target.checked);
              }}
              checked={props.data.weekdays.some(
                (weekday) => weekday === EPlanningWeekDays.Tue
              )}
              id="Check2"
              label="Tirsdag"
            />
            <MDBCheckbox
              name="Check3"
              onChange={(el) => {
                changeWeekdayHandler(EPlanningWeekDays.Wed, el.target.checked);
              }}
              checked={props.data.weekdays.some(
                (weekday) => weekday === EPlanningWeekDays.Wed
              )}
              id="Check3"
              label="Onsdag"
            />
            <MDBCheckbox
              name="Check4"
              onChange={(el) => {
                changeWeekdayHandler(EPlanningWeekDays.Thu, el.target.checked);
              }}
              checked={props.data.weekdays.some(
                (weekday) => weekday === EPlanningWeekDays.Thu
              )}
              id="Check4"
              label="Torsdag"
            />
          </div>
        </MDBCol>
        <MDBCol size={6}>
          <div className="d-flex flex-wrap align-items-start flex-column">
            <MDBCheckbox
              name="Check2"
              onChange={(el) => {
                changeWeekdayHandler(EPlanningWeekDays.Fri, el.target.checked);
              }}
              checked={props.data.weekdays.some(
                (weekday) => weekday === EPlanningWeekDays.Fri
              )}
              id="Check5"
              label="Fredag"
            />
            <MDBCheckbox
              name="Check3"
              onChange={(el) => {
                changeWeekdayHandler(EPlanningWeekDays.Sat, el.target.checked);
              }}
              checked={props.data.weekdays.some(
                (weekday) => weekday === EPlanningWeekDays.Sat
              )}
              id="Check6"
              label="Lørdag"
            />
            <MDBCheckbox
              name="Check3"
              onChange={(el) => {
                changeWeekdayHandler(EPlanningWeekDays.Sun, el.target.checked);
              }}
              checked={props.data.weekdays.some(
                (weekday) => weekday === EPlanningWeekDays.Sun
              )}
              id="Check7"
              label="Søndag"
            />
          </div>
        </MDBCol>
      </MDBRow>
    </>
  );
}
