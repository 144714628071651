import {
  ISuOCRMScriptTree,
  ETreeType
} from "doweb-alfred-backend-client";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCol,
  MDBIcon,
  MDBRow,
  MDBTooltip,
} from "mdb-react-ui-kit";
import React, { useState } from "react";

interface Props {
  filesAndFolders: ISuOCRMScriptTree[];
  onSelect: Function;
  iconSize: "1x" | "2x" | "3x" | "4x" | "5x" | "6x" | "7x" | "8x";
  cardWidth: number;
  fontSize: number;
}
interface Level {
  index: number;
  name: string;
  nextLevel: null | Level;
}
const GetBreadCrumbs = (level: Level | null): string[] => {
  let thisLevelName: string[] = [];
  if (level !== null) {
    thisLevelName = [level.name];
    if (level.nextLevel !== null) {
      thisLevelName = [...thisLevelName, ...GetBreadCrumbs(level.nextLevel)];
    }
  }
  return thisLevelName;
};
const GetArray = (
  level: Level,
  topLevelArray: ISuOCRMScriptTree[]
): ISuOCRMScriptTree[] => {
  let thisLevelArr: ISuOCRMScriptTree[];
  if (level.nextLevel !== null) {
    thisLevelArr = GetArray(level.nextLevel, topLevelArray[level.index].nodes);
  } else {
    thisLevelArr = topLevelArray[level.index].nodes;
  }
  return thisLevelArr;
};
const AddLevel = (topLevel: Level | null, newlevel: Level): Level => {
  if (topLevel !== null) {
    if (topLevel.nextLevel !== null) {
      newlevel = AddLevel(topLevel.nextLevel, newlevel);
    }
    topLevel.nextLevel = newlevel;
  } else {
    topLevel = newlevel;
  }
  return topLevel;
};
const GoToLevel = (level: Level, index: number): Level => {
  let thisLevel: Level = { ...level };
  if (level.nextLevel !== null && 1 < index) {
    index--;
    thisLevel.nextLevel = GoToLevel(level.nextLevel, index);
  } else {
    thisLevel.nextLevel = null;
  }
  return thisLevel;
};
const RemoveLevel = (
  prevLevel: Level | null,
  nextLevel: Level | null
): Level | null => {
  if (nextLevel !== null && prevLevel !== null) {
    if (nextLevel.nextLevel === null) {
      prevLevel.nextLevel = null;
    } else {
      prevLevel.nextLevel = RemoveLevel(nextLevel, nextLevel.nextLevel);
    }
  } else {
    prevLevel = null;
  }
  return prevLevel;
};
const FolderView = (props: Props) => {
  const [level, setLevel] = useState<Level | null>(null);
  let currentLevel: ISuOCRMScriptTree[] = props.filesAndFolders;
  if (level !== null) {
    currentLevel = GetArray(level, currentLevel);
  }
  const breadCrumbs = ["Topniveau", ...GetBreadCrumbs(level)];
  return (
    <>
      {breadCrumbs.length > 1 && (
        <MDBRow>
          <MDBCol className="navbar navbar-expand-lg navbar-light bg-light mb-3">
            <MDBBreadcrumb>
              {breadCrumbs.map((breadcrumb, i) => (
                <MDBBreadcrumbItem key={i}>
                  <a
                    href={`#${level}`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (level !== null && i !== 0)
                        setLevel({ ...GoToLevel(level, i) });
                      else {
                        setLevel(null);
                      }
                    }}
                  >
                    {breadcrumb}
                  </a>
                </MDBBreadcrumbItem>
              ))}
            </MDBBreadcrumb>
          </MDBCol>
        </MDBRow>
      )}

      <MDBRow>
        <MDBCol>
          <div className="d-flex flex-wrap">
            {level !== null && (
              <MDBCard
                onClick={() => {
                  const levelUp = RemoveLevel(level, level.nextLevel);

                  setLevel(levelUp === null ? levelUp : { ...levelUp });
                }}
                className="m-1 "
                style={{ width: props.cardWidth + "px", cursor: "pointer" }}
              >
                <div className="text-center">
                  <MDBIcon
                    fas
                    icon="caret-square-left"
                    className="p-3"
                    size={props.iconSize}
                  />
                </div>
                <MDBCardBody>
                  <MDBCardTitle
                    className="text-center text-truncate"
                    style={{
                      maxWidth: props.cardWidth,
                      fontSize: props.fontSize + "px",
                    }}
                  >
                    Gå en mappe op
                  </MDBCardTitle>
                </MDBCardBody>
              </MDBCard>
            )}
            {currentLevel.map((element, i) => (
              <MDBCard
                key={i}
                onClick={() => {
                  if (element.type === ETreeType.Folder) {
                    setLevel({
                      ...AddLevel(level, {
                        index: i,
                        name: element.text,
                        nextLevel: null,
                      }),
                    });
                  } else {
                    props.onSelect(element.crmscript);
                  }
                }}
                className="m-1 "
                style={{ width: props.cardWidth + "px", cursor: "pointer" }}
              >
                <div className="text-center">
                  {element.type === ETreeType.Folder && (
                    <MDBIcon
                      fas
                      icon="folder"
                      className="p-3"
                      size={props.iconSize}
                    />
                  )}
                  {element.type === ETreeType.File && (
                    <MDBIcon
                      fas
                      icon="file-code"
                      className="p-3"
                      size={props.iconSize}
                    />
                  )}
                </div>
                <MDBCardBody>
                  <MDBCardTitle
                    className="text-center text-truncate"
                    style={{
                      maxWidth: props.cardWidth,
                      fontSize: props.fontSize + "px",
                    }}
                  >
                    <MDBTooltip tag="span" title={element.text}>
                      {element.text}
                    </MDBTooltip>
                  </MDBCardTitle>
                </MDBCardBody>
              </MDBCard>
            ))}
          </div>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default FolderView;
