import React, { useEffect, useState } from "react";
import {
  MDBAccordion,
} from "mdb-react-ui-kit";
import { ActionSection } from "./ActionSection"; 
import { ESectionEntity, ISectionAction } from "doweb-alfred-backend-client";
 
interface Props {
  setActions: Function;
  actions: ISectionAction[];
  entity: ESectionEntity; 
}
export const CreateEditAction = (props: Props) => {
  //We put the actions in cache, so everything wont be saved before hitting the save button
  const [actionCache, setActionCache] = useState(props.actions);
  //Create useEfect for the actioncache
  useEffect(() => {
    setActionCache(props.actions);
  }
  , [props.actions]);
  return (
    <>
   
     
    
            {actionCache.length === 0 && <h3 className="text-center mt-3">Ingen actions - tilføj en ved at klikke "Opret ny"</h3>}
            {actionCache.length > 0 && (
              <MDBAccordion initialActive={0} alwaysOpen>
                {actionCache.map((action, actionIndex) => (
                  <ActionSection
                    sectionType="props.sectionType"
                  
                    action={action}
                    id={actionIndex + 1}
                    key={actionIndex}
                    setAction={(changedAction: ISectionAction) => {
                      props.setActions(changedAction, 'update');
                    }}
                    deleteAction={() => {
                      props.setActions(action, 'delete');
                    }}
                  />
                ))}
              </MDBAccordion>
            )}
        
            
    
  </>
  );
};
