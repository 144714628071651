import {
  ESectionEntity,
  ETemplateType,
  ITemplate,
} from "doweb-alfred-backend-client";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBListGroup,
  MDBListGroupItem,
  MDBNavbar,
  MDBNavbarBrand,
  MDBRow,
  MDBSelect,
} from "mdb-react-ui-kit";
import { WysiwygEditor } from "../../components/WysiwygEditor";
import { useRef, useState, useEffect } from "react";
import { Editor as TinyMCEEditor } from "tinymce";
import { useDispatch, useSelector } from "react-redux";
import {
  getTemplates,
  createDefaultTemplate,
  deleteTemplate,
  editTemplate, 
} from "../../store/features/ListSlice";
import { AppDispatch } from "../../store";
import { useAuth0 } from "@auth0/auth0-react";
import { SaveEditBtn } from "../../components/SaveEditBtn";

export const EmailTemplate = () => {
  const [activeTemplate, setActiveTemplate] = useState("");
  const emailEditorRef = useRef<TinyMCEEditor>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const templates = useSelector(getTemplates);
  const templateData: ITemplate | undefined = templates.list.find(
    (t) => t._id === activeTemplate
  );
  const dispatch = useDispatch<AppDispatch>();
  const { getAccessTokenSilently } = useAuth0();
  //UseState to manage templateCache
  const [templateCache, setTemplateCache] = useState<ITemplate | undefined>(
    templateData
  );

  useEffect(() => {
    setTemplateCache(templateData);
    if (emailEditorRef.current) {
      emailEditorRef.current.setContent(
        typeof templateData?.html === "undefined" ? "" : templateData.html
      );
    }
    if (nameRef.current) {
      nameRef.current.value = typeof templateData?.name === "undefined" ? "" : templateData.name
   
    }
  }, [templateData, setTemplateCache, emailEditorRef]);

  const getEditorContent = (): string => {
    if (emailEditorRef.current) {
      return emailEditorRef.current.getContent({ format: "html" });
    } else {
      return "";
    }
  };
  const getName = (): string => {
    if (nameRef.current) {
      return nameRef.current.value;
    } else {
      return "";
    }
  };

  return (
    <>
      <MDBRow>
        <MDBCol size="12" className=" ps-3 pt-1 pb-2 text-start">
          <h3>Skabeloner</h3>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size={2}>
          <MDBListGroup>
            {templates.list.map((template, index) => (
              <MDBListGroupItem
                key={template._id ? template._id : index}
                tag="button"
                className="d-flex justify-content-between align-items-center"
                active={template._id === activeTemplate}
                onClick={() =>
                  setActiveTemplate(template._id ? template._id : "")
                }
              >
                {template.name}
              </MDBListGroupItem>
            ))}
          </MDBListGroup>
          <div className="mt-2">
          <SaveEditBtn
            onClickCancel={(e) => {}}
            onClickCreate={(e) => {
              e.preventDefault();
              getAccessTokenSilently().then((token) =>
                dispatch(
                  createDefaultTemplate({
                    accesstoken: token,
                  })
                )
              );
            }}
            onClickEdit={(e) => {}} 
            hideEdit={true}
          />
          </div>
        </MDBCol>
        <MDBCol size={10}>
          <MDBRow>
            <MDBCol>
              <MDBNavbar className="navbar navbar-expand-lg navbar-light bg-light">
                <MDBContainer fluid>
                  <MDBNavbarBrand>
                    {templateCache && <MDBInput style={{width: '400px'}} ref={nameRef} label="Navn" defaultValue={templateCache.name} /> }
                    {!templateCache && <>Vælg et template</>}
                  </MDBNavbarBrand>
                  {templateCache && (
                    <form className="d-flex w-auto">
                      {templateCache.type === ETemplateType.Section && (
                        <MDBSelect
                          label="Sektions Type"
                          className="me-3"
                          data={[
                            {
                              text: "Firma",
                              defaultSelected:
                                templateCache.entity === ESectionEntity.Contact,
                              value: ESectionEntity.Contact,
                            },
                            {
                              text: "Personer",
                              defaultSelected:
                                templateCache.entity === ESectionEntity.Person,
                              value: ESectionEntity.Person,
                            },
                            {
                              text: "Projekter",
                              defaultSelected:
                                templateCache.entity === ESectionEntity.Project,
                              value: ESectionEntity.Project,
                            },
                            {
                              text: "Salg",
                              defaultSelected:
                                templateCache.entity === ESectionEntity.Sale,
                              value: ESectionEntity.Sale,
                            },
                            {
                              text: "Dokumenter",
                              defaultSelected:
                                templateCache.entity ===
                                ESectionEntity.Document,
                              value: ESectionEntity.Document,
                            },
                            {
                              text: "Aktiviteter",
                              defaultSelected:
                                templateCache.entity ===
                                ESectionEntity.Appointment,
                              value: ESectionEntity.Appointment,
                            },
                          ]}
                          onValueChange={(e: any) => {
                            setTemplateCache({
                              ...templateCache,
                              entity: e.value,
                            });
                          }}
                        />
                      )}
                      <MDBSelect
                        label="Skabelon type"
                        className="me-3"
                        data={[
                          {
                            text: "Sektion",
                            defaultSelected:
                              templateCache.type === ETemplateType.Section,
                            value: ETemplateType.Section,
                          },
                          {
                            text: "Wrapper",
                            defaultSelected:
                              templateCache.type === ETemplateType.Wrapper,
                            value: ETemplateType.Wrapper,
                          },
                        ]}
                        onValueChange={(e: any) => {
                          setTemplateCache({
                            ...templateCache,
                            type: e.value,
                          });
                        }}
                      />
                      <MDBBtn
                        onClick={(e) => {
                          e.preventDefault();
                          if (templateCache) {
                            getAccessTokenSilently().then((token) => {
                              dispatch(
                                deleteTemplate({
                                  accesstoken: token,
                                  id: templateCache._id
                                    ? templateCache._id
                                    : "",
                                })
                              );
                            });
                          }
                        }}
                        color="danger"
                        className="me-3"
                      >
                        <MDBIcon fas icon={"trash"} />
                        &nbsp; Slet
                      </MDBBtn>
                      <MDBBtn
                        onClick={(e) => {
                          e.preventDefault();
                          getAccessTokenSilently().then((token) => {
                            dispatch(
                              editTemplate({
                                accesstoken: token,
                                template: {...templateCache, html: getEditorContent(), name: getName()},
                              })
                            );
                          });
                        }}
                        color="success"
                      >
                        <MDBIcon fas icon={"check"} />
                        &nbsp;Gem
                      </MDBBtn>
                    </form>
                  )}
                </MDBContainer>
              </MDBNavbar>
            </MDBCol>
          </MDBRow>
          {activeTemplate !== "" && (
            <MDBRow className="mt-3 mb-3">
              <WysiwygEditor
                defaultVal={templateCache?.html}
                editorRef={emailEditorRef}
              />
            </MDBRow>
          )}
        </MDBCol>
      </MDBRow>
    </>
  );
};
