import React, { useEffect, useState } from "react";
//Ui
import {
  MDBContainer,
  MDBRow,
  MDBCol, 
} from "mdb-react-ui-kit";
import Loading from "../components/Loading";
// Authentication
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react"; 

//API
export const Test = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [accessToken, setAccessToken] = useState("");
  useEffect(() => {
    const getData = async () => {
      setAccessToken(await getAccessTokenSilently());
    };
    getData();
  }, [getAccessTokenSilently]);
  return (
    <MDBContainer className="mb-5">
      <MDBRow className="align-items-center profile-header mb-5 text-center text-md-left">
        <MDBCol md={12}>
          <h1>Access token:</h1>
          <pre>{accessToken}</pre>
        </MDBCol>
      </MDBRow>
      
    </MDBContainer>
  );
};

export default withAuthenticationRequired(Test, {
  onRedirecting: () => <Loading />,
});
