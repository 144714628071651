import { useAuth0 } from "@auth0/auth0-react";
import { ESectionEntity, ISectionAction } from "doweb-alfred-backend-client";
import {
  MDBCol,
  MDBRow,
  MDBContainer,
  MDBListGroup,
  MDBListGroupItem,
  MDBNavbar,
  MDBNavbarBrand,
  MDBBtn,
  MDBIcon,
  MDBBadge,
  MDBToast,
} from "mdb-react-ui-kit";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IToastItem } from "../../assets/types/General.d";
import { AppDispatch } from "../../store";
import {
  getActionCollection,
  createDefaultAction,
  editAction,
  deleteAction,
  getOpenEntity,
  setActiveEntity,
} from "../../store/features/ActionSlice";
import { CreateEditAction } from "./CreateEditAction";

export function Action() {
  const [stackingToasts, setStackingToasts] = useState<IToastItem[]>([]);
  const actionSelector = useSelector(getActionCollection);
  const openEntity = useSelector(getOpenEntity);

  //create a state for the selected entity type
  const dispatch = useDispatch<AppDispatch>();
  //Get Auth0
  const { getAccessTokenSilently } = useAuth0();

  const entityArray = [
    {
      text: "Firma",
      active: openEntity === ESectionEntity.Contact,
      value: ESectionEntity.Contact,
      count: actionSelector.filter(
        (action) => action.entity === ESectionEntity.Contact
      ).length,
    },
    {
      text: "Personer",
      active: openEntity === ESectionEntity.Person,
      value: ESectionEntity.Person,
      count: actionSelector.filter(
        (action) => action.entity === ESectionEntity.Person
      ).length,
    },
    {
      text: "Projekter",
      active: openEntity === ESectionEntity.Project,
      value: ESectionEntity.Project,
      count: actionSelector.filter(
        (action) => action.entity === ESectionEntity.Project
      ).length,
    },
    {
      text: "Salg",
      active: openEntity === ESectionEntity.Sale,
      value: ESectionEntity.Sale,
      count: actionSelector.filter(
        (action) => action.entity === ESectionEntity.Sale
      ).length,
    },
    {
      text: "Dokumenter",
      active: openEntity === ESectionEntity.Document,
      value: ESectionEntity.Document,
      count: actionSelector.filter(
        (action) => action.entity === ESectionEntity.Document
      ).length,
    },
    {
      text: "Aktiviteter",
      active: openEntity === ESectionEntity.Appointment,
      count: actionSelector.filter(
        (action) => action.entity === ESectionEntity.Appointment
      ).length,
      value: ESectionEntity.Appointment,
    },
  ];
  return (
    <>
      {stackingToasts.map((item: IToastItem) => (
        <MDBToast
          color={item.color}
          show
          autohide
          stacking
          width={450}
          position="bottom-left"
          delay={2000}
          appendToBody
          key={item.count}
          headerContent={
            <>
              <strong className="me-auto">Alfred Siger:</strong>
            </>
          }
          bodyContent={item.body}
        />
      ))}

      <MDBRow>
        <MDBCol size="12" className=" ps-3 pt-1 pb-2 text-start">
          <h3>Actions</h3>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol className="mb-2" md={12} lg={2}>
          <MDBListGroup>
            {/*For eact entityArray, create a MDBListGroupItem */}
            {entityArray.map((entity, index) => (
              <MDBListGroupItem
                key={index}
                tag="button"
                className="d-flex justify-content-between align-items-center"
                active={entity.active}
                onClick={() => {
                  dispatch(setActiveEntity(entity.value));
                }}
              >
                {entity.text}
                {entity.count > 0 && (
                  <MDBBadge color="warning" pill className="me-3">
                    {entity.count}
                  </MDBBadge>
                )}
              </MDBListGroupItem>
            ))}
          </MDBListGroup>
        </MDBCol>
        <MDBCol md={12} lg={10}>
          <MDBRow>
            <MDBCol>
              <MDBNavbar className="navbar navbar-expand-lg navbar-light bg-light">
                <MDBCol md={6}>
                <MDBContainer fluid>
                  <MDBNavbarBrand>
                    Actions for{" "}
                    {entityArray.find((entity) => entity.active)?.text}
                  </MDBNavbarBrand>
                </MDBContainer>
                </MDBCol>
                <MDBCol md={6}>
                <div className="d-flex justify-content-end w-100">
                  <MDBBtn
                    color="success"
                    className="me-2"
                    onClick={(e) => {
                      e.preventDefault();
                      getAccessTokenSilently().then((token) => {
                        setStackingToasts([
                          ...stackingToasts,
                          {
                            color: "success",
                            body: `Jeg har oprettet en ny action i sektionstypen ${entityArray.find(ent => ent.active)?.text}, som du bad om.`,

                            count:
                              stackingToasts.length > 0
                                ? stackingToasts.length + 1
                                : 1,
                          },
                        ]);
                        dispatch(
                          createDefaultAction({
                            entity: openEntity,
                            accesstoken: token,
                          })
                        );
                      });
                    }}
                  >
                    <MDBIcon fas icon={"plus"} />
                    &nbsp; Opret ny Action
                  </MDBBtn>
                </div>
                </MDBCol>
              </MDBNavbar>
              
            </MDBCol>
          </MDBRow>
          <MDBRow className="mt-3 mb-3">
            <CreateEditAction
              entity={openEntity}
              setActions={(
                action: ISectionAction,
                eventType: "update" | "delete"
              ) => {
                getAccessTokenSilently().then((token) => {
                  if (eventType === "update") {
                    setStackingToasts([
                      ...stackingToasts,
                      {
                        color: "success",
                        body: `Jeg har opdateret '${action.name}' for dig!`,

                        count:
                          stackingToasts.length > 0
                            ? stackingToasts.length + 1
                            : 1,
                      },
                    ]);
                    dispatch(
                      editAction({ Action: action, accesstoken: token })
                    );
                  } else if (eventType === "delete") {
                    if (action._id) {
                      setStackingToasts([
                        ...stackingToasts,
                        {
                          color: "warning",
                          body: `Jeg har slettet '${action.name}'.`,

                          count:
                            stackingToasts.length > 0
                              ? stackingToasts.length + 1
                              : 1,
                        },
                      ]);
                      dispatch(
                        deleteAction({ id: action._id, accesstoken: token })
                      );
                    }
                  }
                });
              }}
              actions={actionSelector.filter(
                (action: ISectionAction) => action.entity === openEntity
              )}
            />
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </>
  );
}
