import "./wizard.scss";
interface Props {
  style?: any;
}
const WizardWait = (props: Props) => {
  return (
    <div className="wizardWaiter" style={props.style}>
      <div className="scene">
        <div className="tray">
          <div className="traybase"></div>
          <div className="trayrim"></div>
        </div>
        <div className="teastream">
          <div className="teapour"></div>
          <div className="teacover1"></div>
        </div>
        <div className="teapot">
          <div className="handle"></div>
          <div className="spout1"></div>
          <div className="spout2"></div>
          <div className="spout3"></div>
          <div className="spout4"></div>
          <div className="top"></div>
          <div className="teabase"></div>
          <div className="teabody"></div>
          <div className="lid"></div>
        </div>
        <div className="cup">
          <div className="cuphandle"></div>
          <div className="cupbase"></div>
          <div className="cuphandlein"></div>
          <div className="cupbody"></div>
          <div className="cupinside"></div>
        </div>
        <div className="wizard">
          <div className="leftarm">
            <div className="lefthand"></div>
          </div>
          <div className="body"></div>
          <div className="rightarm-all">
            <div className="rightarm">
              <div className="rightsleeve">
                <div className="righthand"></div>
                <div className="rightfinger"></div>
              </div>
            </div>
          </div>
          <div className="chest">
            <div className="button1"></div>
            <div className="button2"></div>
          </div>
          <div className="head">
            <div className="face">
              <div className="bowtie">
                <div className="bowtie1"></div>
                <div className="bowtie2"></div>
                <div className="bowtie3"></div>
              </div>
            </div>
            <div className="ear"></div>
            <div className="moustache"></div>
            <div className="hat">
              <div className="hat-fold"></div>
              <div className="hat-rim"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WizardWait;
