import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import logo from "../assets/logo.png";
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBIcon,
  MDBRipple,
} from "mdb-react-ui-kit";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";
import { setDrawer } from "../store/features/DrawerSlice";
import { Link } from "react-router-dom";

export default function Sidenav() {
  const drawerOpen = useSelector((state: RootState) => state.drawer.value);
  const dispatch = useDispatch();
  const {
    isAuthenticated,

    logout,
  } = useAuth0();
  const [basicCollapse1, setBasicCollapse1] = useState(false);
  const [basicCollapse2, setBasicCollapse2] = useState(false);
  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  const handleBasicCollapse1 = () => {
    if (basicCollapse2 && !basicCollapse1) setBasicCollapse2(false);

    setBasicCollapse1(!basicCollapse1);
  };

  return (
    <>
      <MDBSideNav
        isOpen={drawerOpen}
        backdrop={true}
        getOpenState={(e) => dispatch(setDrawer(e))}
      >
        <MDBRipple rippleTag="a" className="d-flex justify-content-center py-4">
          <img
            id="Generic-logo"
            src={logo}
            alt="Logo"
            draggable="false"
            style={{ width: "200px" }}
          />
        </MDBRipple>
        <MDBSideNavMenu>
          <MDBSideNavItem>
            {isAuthenticated && (
              <>
                <Link to="/" className="sidenav-link">
                  <MDBIcon fas icon="clock" className="fa-fw me-3" />
                  Jobs
                </Link>
                <Link to="/actions" className="sidenav-link">
                  <MDBIcon  fas icon="running"  className="fa-fw me-3" />
                  Actions
                </Link>
                <Link to="/templates" className="sidenav-link">
                  <MDBIcon fas icon="envelope" className="fa-fw me-3" />
                  Templates
                </Link>
              </>
            )}
            {!isAuthenticated && (
              <Link to="/" className="sidenav-link">
                <MDBIcon fas icon="home" className="fa-fw me-3" />
                Forside
              </Link>
            )}
          </MDBSideNavItem>
          {isAuthenticated && (
            <MDBSideNavItem>
              <MDBSideNavLink icon="angle-down" onClick={handleBasicCollapse1}>
                <MDBIcon fas icon="cogs" className="fa-fw me-3" />
                Profil
              </MDBSideNavLink>
              <MDBSideNavCollapse show={basicCollapse1}>
                <Link to="/profile" className="sidenav-link">
                  <MDBIcon far icon="user-circle" className="fa-fw me-3" />
                  Se profil
                </Link>
                <Link
                  className="sidenav-link"
                  to=""
                  onClick={() => logoutWithRedirect()}
                >
                  <MDBIcon fas icon="power-off" className="fa-fw me-3" />
                  Log ud
                </Link>
              </MDBSideNavCollapse>
            </MDBSideNavItem>
          )}
        </MDBSideNavMenu>
      </MDBSideNav>
    </>
  );
}
