import React, { useEffect, useState } from "react";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBBtn,
  MDBModalFooter,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";

//import DropdownTreeSelect from "react-dropdown-tree-select";
import {
  ECriteriaOperator,
  ESectionEntity,
  ISectionCriteria,
  ISectionCriteriaField,
  ISectionCriteriaValueInner,
} from "doweb-alfred-backend-client";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { CriteriaOperatorSelector } from "./CriteriaOperatorSelector/index";
import { CriteriaValueSelector } from "./CriteriaValueSelector";
import { getOperator } from "./CriteriaOperatorSelector/OperatorMap";
import { TreeSelect } from "../../../TreeSelect/TreeSelect";
interface Props {
  setCriteria: Function;
  criteria: ISectionCriteria;
  show: boolean;
  setModal: Function;
  sectionType: ESectionEntity;
}

export const CreateEditCriteriaModal = (props: Props) => {
  const fieldSelectorTree = useSelector(
    (state: RootState) => state.list.fields // sectionType ***REPLACE***
  );
  let theActualSelectorTree = fieldSelectorTree.project;
  //Make a Switch on props.sectionType
  switch (props.sectionType) {
    case ESectionEntity.Appointment:
      theActualSelectorTree = fieldSelectorTree.appointment;
      break;
    case ESectionEntity.Project:
      theActualSelectorTree = fieldSelectorTree.project;
      break;
    case ESectionEntity.Contact:
      theActualSelectorTree = fieldSelectorTree.contact;
      break;
    case ESectionEntity.Person:
      theActualSelectorTree = fieldSelectorTree.person;
      break;
    case ESectionEntity.Sale:
      theActualSelectorTree = fieldSelectorTree.sale;
      break;
    case ESectionEntity.Document:
      theActualSelectorTree = fieldSelectorTree.document;
      break;
  }
  //We don't use a criteriacache this time, because we want more fine-grained control over updates this time.
  //state for the select button
  const [criteriaCache, setCriteriaCache] = useState(props.criteria);
  //state for the field select popover
  const [fieldSelectPopover, setFieldSelectPopover] = useState(false);
  //state for the field select popover
  const [valueSelectPopover, setValueSelectPopover] = useState(false);

  //create useEffect to update the state when the modal is opened
  useEffect(() => {
    setCriteriaCache(props.criteria);
  }, [props.criteria]);

  const activeFieldHandler = (e: any) => {
    const field: ISectionCriteriaField = {
      name: e.label,
      id: e.data.Type,
      dataType: e.data.ExtraInfo,
      listId: e.data.RestrictionListName,
    };

    const getdefaultOperator = getOperator(
      field.dataType,
      ECriteriaOperator.Eq
    );
    if (getdefaultOperator.length > 0) {
      setCriteriaCache({
        ...criteriaCache,
        field: field,
        operator: getdefaultOperator[0].value,
        value: [],
      });
    } else {
      setCriteriaCache({
        ...criteriaCache,
        field: field,
        operator: ECriteriaOperator.Set,
        value: [],
      });
    }
  };
  const activeOperatorHandler = (e: ECriteriaOperator) => {
    setCriteriaCache({ ...criteriaCache, operator: e, value: [] });
  };

  const selectedValueHandler = (value: ISectionCriteriaValueInner[]) => {
    setCriteriaCache({ ...criteriaCache, value: value });
  };
  //create useEffect to hide the field select popover when the modal is closed
  useEffect(() => {
    if (!props.show) {
      setFieldSelectPopover(false);
      setValueSelectPopover(false);
    }
  }, [props.show]);

  //state for
  const saveHandler = () => {
    props.setCriteria(criteriaCache);
    setFieldSelectPopover(false);
    props.setModal(false);
  };

  return (
    <MDBModal
      staticBackdrop
      className="criteriaSelectModal"
      show={props.show}
      setShow={props.setModal}
    >
      <MDBModalDialog centered size="lg">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Rediger kriterie</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => {
                props.setModal(!props.show);
              }}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              <MDBCol size={4}>
                <TreeSelect
                  tree={theActualSelectorTree}
                  label={criteriaCache.field.name}
                  onSelect={activeFieldHandler}
                  closeOnSelect={true}
                  popoverOpen={fieldSelectPopover}
                  setPopoverOpen={setFieldSelectPopover}
                />
              </MDBCol>
              <MDBCol size={4}>
                <CriteriaOperatorSelector
                  selectedFieldType={criteriaCache.field.dataType}
                  selectedOperator={criteriaCache.operator}
                  setOperator={activeOperatorHandler}
                />
              </MDBCol>
              <MDBCol size={4}>
                <CriteriaValueSelector
                  sectionType={props.sectionType}
                  setSelectedValue={selectedValueHandler}
                  selectedValue={criteriaCache.value}
                  selectedField={criteriaCache.field}
                  selectedFieldType={criteriaCache.field.dataType}
                  selectedOperator={criteriaCache.operator}
                  popoverOpen={valueSelectPopover}
                  setPopoverOpen={setValueSelectPopover}
                />
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="secondary"
              onClick={() => {
                setCriteriaCache(props.criteria);
                props.setModal(!props.show);
              }}
            >
              Annuller
            </MDBBtn>
            <MDBBtn onClick={saveHandler}>Gem</MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};
/*
interface Props {
  setSelectedValue: Function;
  selectedValue: string[];
  selectedFieldType: string;
  selectedOperator: string;
}
*/
