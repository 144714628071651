import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"; 
import { EAsyncLoadStatus, IInitialJobState } from "../../assets/types/General.d";
import {
  JobApi,
  Configuration,
  IJobListItem,
  ISection,
  IDeleteArray,
} from "doweb-alfred-backend-client";
import { RootState } from ".."; 

const initialState: IInitialJobState = {
  openJob: '',
  hasChanged: false,
  status: EAsyncLoadStatus.idle,
  initialLoad: EAsyncLoadStatus.idle,
  error: null,
  collection: [],
};

/*
The thunkData interfaces all enable the createAsyncThunk function to only take a single parameter
They enable the passing of any parameters defined in the interface to the various async functions
*/

/*
createEditSection: (state, action) => {
  state.collection[action.payload.jobKey].sections[
    action.payload.sectionKey
  ] = action.payload.section;
},
*/

export interface editJobThunk {
  accesstoken: string;
  job: IJobListItem; 
}

interface updateJobThunk {
  accesstoken: string;
  collection: IJobListItem[];
}

interface deleteJobThunk {
  accesstoken: string;
  id: string;
}

interface delSectionJobThunk {
  accesstoken: string;
  jobKey: number;
  sectionKey: number;
}

interface createDefaultJobThunk {
  accesstoken: string;
  key: number;
}

interface createEditSectionJobThunk {
  accesstoken: string;
  jobKey: number;
  sectionKey: number;
  section: ISection;
}

/*
Has a bug - can't tell wth it is?!?!?
*/
export const createEditSection = createAsyncThunk(
  "jobs/createEditSection",
  async (input: createEditSectionJobThunk, thunkApi) => {
    try {
      const jobs = new JobApi(
        new Configuration({
          accessToken: input.accesstoken,
          basePath: process.env.REACT_APP_BACKEND_BASE_URL,
        })
      );
      const getTheJobState = (): IInitialJobState => {
        const state = thunkApi.getState() as RootState;
        return state.job;
      };
      let theCurrentJob : IJobListItem = {...getTheJobState().collection[input.jobKey]};
      let theNewSections = [...theCurrentJob.sections];
      theNewSections[input.sectionKey] = input.section;
      theCurrentJob.sections = theNewSections;
      await jobs.jobIdPut(String(theCurrentJob._id), theCurrentJob);
       
      return input;
    } catch (error: any) {
      
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

/*
Creates a default job on the server.
*/
export const createDefaultJob = createAsyncThunk(
  "jobs/createDefaultJob",
  async (input: createDefaultJobThunk, thunkApi) => {
    try {
      const jobs = new JobApi(
        new Configuration({
          accessToken: input.accesstoken,
          basePath: process.env.REACT_APP_BACKEND_BASE_URL,
        })
      );
      const response = await jobs.jobCreateDefaultPost();
      return { key: input.key, job: response.data };
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

/*
Called whenever a section needs to be removed from a job.
*/

export const deleteSection = createAsyncThunk(
  "jobs/deleteSection",
  async (input: delSectionJobThunk, thunkApi) => {
    try {
      const jobs = new JobApi(
        new Configuration({
          accessToken: input.accesstoken,
          basePath: process.env.REACT_APP_BACKEND_BASE_URL,
        })
      );
      const getTheJobState = (): IInitialJobState => {
        const state = thunkApi.getState() as RootState;
        return state.job;
      };
      let theCurrentJob : IJobListItem = {...getTheJobState().collection[input.jobKey]};
      let theNewSections = [...theCurrentJob.sections];
      theNewSections.splice(input.sectionKey, 1);
      theCurrentJob.sections = theNewSections;
      await jobs.jobIdPut(String(theCurrentJob._id), theCurrentJob);
       
      return input;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

/*
  Called whenever editmode ends in the left job list.n
  Sends the full job collection off to the server;
  returns the updated collection only if save is pressed
  must eventually return the collection from the server
  Handles bulk deletions and renames
*/

export const updateJobCollection = createAsyncThunk(
  "jobs/updateJobCollection",
  async (input: updateJobThunk, thunkApi) => {
    try {
      const jobs =
      new JobApi(
        new Configuration({
          accessToken: input.accesstoken,
          basePath: process.env.REACT_APP_BACKEND_BASE_URL,
        })
      );
      // Get IDS of the new collection to figure out which has been deleted
      const theChangedJobCollection = input.collection.map((job) => job._id);
      const getTheJobState = (): IInitialJobState => {
        const state = thunkApi.getState() as RootState;
        return state.job;
      };
      const theCurrentJobCollection = getTheJobState().collection;
      const theDeletedIds : IDeleteArray[] = theCurrentJobCollection
        .filter((job) => theChangedJobCollection.indexOf(job._id) === -1)
        .map((job) => {
          return {'_id': job._id} as IDeleteArray;
        });
      //Now send the ids to the delete function. Please configure backend to be able to receive several id's instead of just one.
      await jobs.jobDelete(theDeletedIds);
       

      //After that update the collection of remaining jobs by sending a collection of jobs to the server, same as edit job, but configured to receive an array instead.
      await jobs.jobPut(input.collection);


      //Pass on the changed collection to the thunk actions, in order to update the state: (Keep as is)
      return input.collection;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

/*
  Called whenever an edited job is saved from a single job pane.
  Handles the updating of most of the job details
*/

export const editJob = createAsyncThunk(
  "jobs/editJob",
  async (input: editJobThunk, thunkApi) => {
    try {
      const jobs = new JobApi(
        new Configuration({
          accessToken: input.accesstoken,
          basePath: process.env.REACT_APP_BACKEND_BASE_URL,
        })
      );

       await jobs.jobIdPut(String(input.job._id), input.job); 
      return input.job ;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

/*
  When a single job is deleted via the general form, this method is called.
  It currently has a malfunction (and perhaps it shouldn't even be in the UI, as it duplicates functionality in an odd place)
*/
export const deleteJob = createAsyncThunk(
  "jobs/deleteJob",
  async (input: deleteJobThunk, thunkApi) => {
    try {
      const jobs =
      new JobApi(
        new Configuration({
          accessToken: input.accesstoken,
          basePath: process.env.REACT_APP_BACKEND_BASE_URL,
        })
      );
       
      await jobs.jobIdDelete(input.id);
     
      return input.id;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

/*
Gets all the jobs from the server
*/
export const fetchJobs = createAsyncThunk(
  "jobs/fetchJobs",
  async (accesstoken: string, thunkApi) => {
    try {
      const jobs = new JobApi(
        new Configuration({
          accessToken: accesstoken,
          basePath: process.env.REACT_APP_BACKEND_BASE_URL,
        })
      );
      const response = await jobs.jobGet();
      return response.data.list;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const jobSlice = createSlice({
  name: "job",
  initialState: initialState,
  reducers: {
    setActiveJob: (state, action) => {
      state.openJob = action.payload;
      state.hasChanged = false;
    },
    setHasChanged: (state, action) => {
      state.hasChanged = action.payload;
    }

  },
  extraReducers: (builder) => {
    /*
      This segment handles the asynchronous responsibilities of updating the entire job collection (larger delete operations)
    */
    builder
      .addCase(updateJobCollection.fulfilled, (state, action) => {
        state.collection = action.payload;
        state.status = EAsyncLoadStatus.idle;
      })
      .addCase(updateJobCollection.pending, (state, action) => {
        state.status = EAsyncLoadStatus.loading;
      })
      .addCase(updateJobCollection.rejected, (state, action) => {
        state.status = EAsyncLoadStatus.error;
        state.error = action.payload as string;
      })
      /*
      This segment handles the asynchronous responsibilities of job editing (updating an edited job)
    */
      .addCase(editJob.fulfilled, (state, action) => {
        const theJobIndex = state.collection.findIndex(
          (theJob) => theJob._id === action.payload._id
        );
        state.collection[theJobIndex] = action.payload;
        state.status = EAsyncLoadStatus.idle; 
        if (state.openJob === "" && state.collection.length === 1 ) {
          state.openJob = action.payload._id ? action.payload._id : "";
        }
        state.status = EAsyncLoadStatus.idle;
      })
      .addCase(editJob.pending, (state, action) => {
        state.status = EAsyncLoadStatus.loading;
      })
      .addCase(editJob.rejected, (state, action) => {
        state.status = EAsyncLoadStatus.error;
        state.error = action.payload as string;
      })
      /*
      This segment handles the asynchronous responsibilities of job deletion
    */
      .addCase(deleteJob.fulfilled, (state, action) => {
        if (state.openJob === action.payload) {
          state.openJob = "";
        }
        //action.payload.splice(action.payload, 1);
        state.collection = state.collection.filter((item : IJobListItem) => item._id !== action.payload);
        state.status = EAsyncLoadStatus.idle;
      })
      .addCase(deleteJob.pending, (state, action) => {
        state.status = EAsyncLoadStatus.loading;
      })
      .addCase(deleteJob.rejected, (state, action) => {
        state.status = EAsyncLoadStatus.error;
        state.error = action.payload as string;
      })
      /*
      This segment handles the asynchronous responsibilities of section deletion
    */
      .addCase(deleteSection.fulfilled, (state, action) => {
        state.collection[action.payload.jobKey].sections.splice(
          action.payload.sectionKey,
          1
        );
        state.status = EAsyncLoadStatus.idle;
      })
      .addCase(deleteSection.pending, (state, action) => {
        state.status = EAsyncLoadStatus.loading;
      })
      .addCase(deleteSection.rejected, (state, action) => {
        state.status = EAsyncLoadStatus.error;
        state.error = action.payload as string;
      })
      /*
      This segment handles the creation of new jobs
    */
      .addCase(createDefaultJob.fulfilled, (state, action) => {
        state.collection[action.payload.key] = action.payload.job;
        state.status = EAsyncLoadStatus.idle;
      })
      .addCase(createDefaultJob.pending, (state, action) => {
        state.status = EAsyncLoadStatus.loading;
      })
      .addCase(createDefaultJob.rejected, (state, action) => {
        state.status = EAsyncLoadStatus.error;
        state.error = action.payload as string;
      })
      /*
      This segment handles the edit of a section
      */
      .addCase(createEditSection.fulfilled, (state, action) => {
        state.collection[action.payload.jobKey].sections[
          action.payload.sectionKey
        ] = action.payload.section;
        state.status = EAsyncLoadStatus.idle;
      })
      .addCase(createEditSection.pending, (state, action) => {
        state.status = EAsyncLoadStatus.loading;
      })
      .addCase(createEditSection.rejected, (state, action) => {
        state.status = EAsyncLoadStatus.error;
        state.error = action.payload as string;
      })
      /*
      This segment fetches all existing jobs
    */
      .addCase(fetchJobs.fulfilled, (state, action) => {
        state.collection = action.payload;
        state.initialLoad = EAsyncLoadStatus.success;
      })
      .addCase(fetchJobs.pending, (state, action) => {
        state.initialLoad = EAsyncLoadStatus.loading;
      })
      .addCase(fetchJobs.rejected, (state, action) => {
        state.initialLoad = EAsyncLoadStatus.error;
        state.error = action.payload as string;
      });
  },
});

// Action creators are generated for each case reducer function
export const { setActiveJob, setHasChanged } = jobSlice.actions;
export const getJobCollection = (state: RootState) => state.job.collection;
export const getJobStatus = (state: RootState) => state.job.status;
export const getJobError = (state: RootState) => state.job.error;
export const getJobInitialLoad = (state: RootState) => state.job.initialLoad;
 
export default jobSlice.reducer;
