import {
  MDBBtn,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
} from "mdb-react-ui-kit";
import { ButtonProps } from "mdb-react-ui-kit/dist/types/free/components/Button/types";
import React, { useState } from "react";
import { ModalWrapper } from "../ModalWrapper";
import Loading from "../Loading";
import PreviewTable from "./PreviewTable";
import { Configuration, IEmail, JobApi } from "doweb-alfred-backend-client";
import { useAuth0 } from "@auth0/auth0-react";
interface PreviewJobProps extends ButtonProps {
  jobId: string;
}

/**
 * button and modal for previewing a job. We need the id of the job to contact the API
 *
 * @param props
 */
const PreviewJob = (props: PreviewJobProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loadingModal, setLoadingModal] = useState(false);
  const [emails, setEmails] = useState<IEmail[]>([]); //IEmail[]
  const [showModal, setShowModal] = useState(false);
  const toggleShowLoading = () => setLoadingModal(!loadingModal);
  const toggleShowModal = () => setShowModal(!showModal);

    const getPreview = async () => {
        setLoadingModal(true);
        getAccessTokenSilently().then((token) => {
        const jobs = new JobApi(
            new Configuration({
              accessToken: token,
              basePath: process.env.REACT_APP_BACKEND_BASE_URL,
            })
          );
          jobs.jobIdTestGet(props.jobId).then((res) => {
            //If res is an instance of IEmail[], set the state
            if (Array.isArray(res.data)){
                setEmails(res.data);
            }
            else{ 
                setEmails([]);
                console.log("res is not an array", res);
            }

            setLoadingModal(false);
            setShowModal(true);
          });
        });
    };


  return (
    <>
      <MDBBtn {...props} onClick={getPreview}>
        {props.children}
      </MDBBtn> 
      <ModalWrapper>
        <>
          {/* Modal for the loading animation */}
          <MDBModal
            staticBackdrop
            show={loadingModal}
            setShow={setLoadingModal}
            tabIndex="-1"
          >
            <MDBModalDialog>
              <MDBModalContent>
                <MDBModalBody>
                  <div className="text-center">
                    <Loading />
                    <p>Vent...</p>
                  </div>
                </MDBModalBody>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
          {/* Modal for the preview */}
          <MDBModal show={showModal} setShow={setShowModal} tabIndex="-1">
            <MDBModalDialog size="xl">
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Forhåndsvisning</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={toggleShowModal}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <PreviewTable emails={emails} />
                </MDBModalBody>

                <MDBModalFooter>
                  <MDBBtn color="primary" onClick={toggleShowModal}>
                    Luk vinduet
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        </>
      </ModalWrapper>
    </>
  );
};

export default PreviewJob;
