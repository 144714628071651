import React, { useState } from "react";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBBtn,
  MDBModalFooter,
  MDBAccordion,
  MDBIcon,
  MDBSelect,
  //  MDBAccordionItem,
} from "mdb-react-ui-kit";
//import { ISectionCriteria } from "doweb-alfred-backend-client";
import {
  ISectionCriteriaGroup, 
  ESectionEntity,
  ECriteriaLogicalOperator,
} from "doweb-alfred-backend-client";
import { CriteriaGroup } from "./CriteriaGroup";
//import { MDBSortable, MDBSortableElement } from "mdb-react-drag-drop";
interface Props {
  sectionType: ESectionEntity;
  setCriterias: Function;
  criterias: ISectionCriteriaGroup[];
  show: boolean;
  setModal: Function;
}
export const CriteriaGroupModal = (props: Props) => {
  //We put the criterias in cache, so everything wont be saved before hitting the save button
  const [criteriaCache, setCriteriaCache] = useState(props.criterias);

  const saveHandler = () => {
    props.setCriterias(criteriaCache);
    props.setModal(false);
  };
  return (
    <>
      <MDBModal staticBackdrop show={props.show} setShow={(e: any) => props.setModal(e)}>
        <MDBModalDialog centered size="xl">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Rediger kriteriegrupper</MDBModalTitle>
              <MDBBtn
                onClick={(e) => {
                  e.preventDefault();
                  const newcriteria: ISectionCriteriaGroup = {
                    name: "Kriteriegruppe " + (criteriaCache.length + 1),
                    criterias: [],
                    logicalOperatorGroup:
                      ECriteriaLogicalOperator.And,
                    logicalOperatorCriterias:
                      ECriteriaLogicalOperator.And,
                    rank: criteriaCache.length + 1,
                  };
                  setCriteriaCache([...criteriaCache, newcriteria]);
                }}
                style={{
                  position: "absolute",
                  right: "45px",
                }}
                color="success"
              >
                <MDBIcon fas icon={"plus"} />
                &nbsp; Opret ny gruppe
              </MDBBtn>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={() => props.setModal(!props.show)}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {criteriaCache.length === 0 && (
                <h3>
                  Ingen grupper oprettet. Opret en ved at klikke på "Opret ny gruppe".
                </h3>
              )}
              {criteriaCache.length > 0 && (
                <MDBAccordion initialActive={0} alwaysOpen>
                  {criteriaCache.map((criteria, criteriaIndex) => (
                    <div className="mb-3" key={criteriaIndex}>
                      <CriteriaGroup
                        sectionType={props.sectionType}
                        motherModal={props.show}
                        setMotherModal={props.setModal}
                        criteriaGroup={criteria}
                        id={criteriaIndex + 1}
                        key={criteriaIndex}
                        setCriteriaGroup={(
                          changedcriteria: ISectionCriteriaGroup
                        ) => {
                          let tempCriterias = [...criteriaCache];
                          tempCriterias[criteriaIndex] = changedcriteria;
                          setCriteriaCache(tempCriterias);
                        }}
                        deletecriteria={() => {
                          let tempcriterias = [...criteriaCache];
                          tempcriterias.splice(criteriaIndex, 1);

                          setCriteriaCache(tempcriterias);
                        }}
                      />
                      {criteriaCache.length - criteriaIndex - 1 !== 0 && (
                        <div className="andOrSelector mt-3 mb-3">
                          <MDBSelect
                            data={[
                              {
                                text: "Og",
                                value:
                                  ECriteriaLogicalOperator.And,
                                defaultSelected:
                                  criteria.logicalOperatorGroup ===
                                  ECriteriaLogicalOperator.And,
                              },
                              {
                                text: "Eller",
                                value:
                                  ECriteriaLogicalOperator.Or,
                                defaultSelected:
                                  criteria.logicalOperatorGroup ===
                                  ECriteriaLogicalOperator.Or,
                              },
                            ]}
                            label="Logisk operator mellem grupperne"
                            onValueChange={(e: any) => {
                              let tempCriterias = [...criteriaCache];
                              tempCriterias[criteriaIndex] = {
                                ...tempCriterias[criteriaIndex],
                                logicalOperatorGroup: e.value,
                              };
                              setCriteriaCache(tempCriterias);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </MDBAccordion>
              )}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => {
                  setCriteriaCache(props.criterias);
                  props.setModal(false);
                }}
              >
                Annuller
              </MDBBtn>
              <MDBBtn onClick={saveHandler}>Gem</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};
