import {
  MDBCol,
  MDBRow,
  MDBAccordionItem,
  MDBRadio,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBSwitch,
} from "mdb-react-ui-kit";
import {
  ISectionAction,
  ISuOCRMScript,
  ESectionActionType,
  IActionParameter,
  EActionParameterType,
  CRMScriptApi,
  Configuration,
} from "doweb-alfred-backend-client";
import { SaveEditBtn } from "../../../components/SaveEditBtn";
import { SelectCRMScript } from "./SelectCRMScript";
import { useState } from "react";
import { CreateEditActionParameterModal } from "./CreateEditActionParameterModal";
import { ModalWrapper } from "../../../components/ModalWrapper";
import { Toast } from "../../../components/Toast";
import { useAuth0 } from "@auth0/auth0-react";
interface Props {
  sectionType: string;
  id: number;
  action: ISectionAction;
  setAction: Function;
  deleteAction: Function;
}
export function ActionSection(props: Props) {
  const [modalCRMScript, setModalCRMScript] = useState(false);
  //add state for clipBoardToast
  const [clipBoardToast, setClipBoardToast] = useState(false);

  const blankParameter: IActionParameter = {
    name: "",
    value: "",
    type: EActionParameterType.So,
  };
  const [activeParameter, setActiveParameter] = useState({
    index: -1,
    parameter: blankParameter,
    showModal: false,
  });
  const copyToClipBoard = (toCopy: string) => {
    navigator.clipboard.writeText(toCopy);
    setClipBoardToast(true);
  };
  //GetAccessToken from auth0
  const { getAccessTokenSilently } = useAuth0();
  //Create a useEffect to get the variables of a CRM Script
  const setScriptAndGetParameter = async (script: ISuOCRMScript) => {
    //Create new parameters for the action, if there are none
    getAccessTokenSilently()
      .then((token) => {
        const CRMScripts = new CRMScriptApi(
          new Configuration({
            accessToken: token,
            basePath: process.env.REACT_APP_BACKEND_BASE_URL,
          })
        );
        //Use CRMScripts to get variables
        CRMScripts.crmscriptIdVariablesGet(script.ejscriptId).then((result) => {
          //Set the parameters for the action
          props.setAction({
            ...props.action,
            crmscript: script,
            params: result.data.map((parameter: string) => ({
              ...blankParameter,
              name: parameter,
              type: EActionParameterType.Custom,
            })),
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Toast
        toast={clipBoardToast}
        setToast={setClipBoardToast}
        message={"Alfred: Jeg har kopieret Action ID til udklipsholder!"}
        color="success"
      />

      <MDBAccordionItem
        collapseId={props.id}
        headerTitle={
          <div className="justify-content-between align-items-center d-flex w-100">
            <span>{props.action.name}</span>
            <div className="d-flex">
              <div className="d-none d-md-block">
                <MDBRow>
                  <div className="align-items-center d-flex justify-content-end">
                    Eksekver action på alle rækker:
                    <MDBBtn
                      tag="div"
                      onClick={(e) => {
                        e.stopPropagation();
                        copyToClipBoard(
                          `{sectionAction id='${props.action._id}'}`
                        );
                      }}
                      color="link"
                    >
                      <MDBIcon far icon="clipboard" />
                    </MDBBtn>
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        copyToClipBoard(
                          `{sectionAction id='${props.action._id}'}`
                        );
                      }}
                      className="square text-muted border border-3 p-1 bg-light d-none d-lg-block"
                    >{`{sectionAction id="${props.action._id}"}`}</span>
                  </div>
                </MDBRow>
                <MDBRow>
                  <div className="align-items-center d-flex justify-content-end">
                    Eksekver action på én række:
                    <MDBBtn
                      tag="div"
                      onClick={(e) => {
                        e.stopPropagation();
                        copyToClipBoard(`{action id='${props.action._id}'}`);
                      }}
                      color="link"
                    >
                      <MDBIcon far icon="clipboard" />
                    </MDBBtn>
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        copyToClipBoard(`{action id='${props.action._id}'}`);
                      }}
                      className="square text-muted border border-3 p-1 bg-light d-none d-lg-block"
                    >{`{action id="${props.action._id}"}`}</span>
                  </div>
                </MDBRow>
              </div>
              <div className="align-items-center d-flex">
                <MDBBtn
                  className="me-3 ms-3"
                  tag="a"
                  color="danger"
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();

                    props.deleteAction(props.action);
                  }}
                >
                  <MDBIcon fas icon="trash" />
                  &nbsp; slet
                </MDBBtn>
              </div>
            </div>
          </div>
        }
      >
        <form>
          <MDBRow>
            <MDBCol style={{ minHeight: "240px" }} md={12} lg={4}>
              <MDBInput
                onBlur={(e) =>
                  props.setAction({
                    ...props.action,
                    name: e.target.value,
                  })
                }
                defaultValue={props.action.name}
                label={"Action navn"}
                className="mb-2"
              />
              <MDBRadio
                onChange={(e) => {
                  props.setAction({
                    ...props.action,
                    type: ESectionActionType.CrmScript,
                  });
                }}
                name={`actionTypeRadio${props.id}`}
                value={ESectionActionType.CrmScript}
                label="CRM Script"
                checked={props.action.type === ESectionActionType.CrmScript}
              />
              {props.action.type === ESectionActionType.CrmScript && (
                <MDBBtn
                  color="dark"
                  className="m-2"
                  outline
                  onClick={(e) => {
                    e.preventDefault();
                    setTimeout(() => {
                      setModalCRMScript(true);
                    }, 400);
                  }}
                >
                  {typeof props.action.crmscript === "undefined" && (
                    <>Vælg et CRMScript</>
                  )}
                  {typeof props.action.crmscript !== "undefined" &&
                    props.action.crmscript.name}
                </MDBBtn>
              )}

              <MDBRadio
                onChange={(e) => {
                  props.setAction({
                    ...props.action,
                    type: ESectionActionType.Entity,
                  });
                }}
                name={`actionTypeRadio${props.id}`}
                value={ESectionActionType.Entity}
                label="Åbn entitet i SuperOffice"
                checked={props.action.type === ESectionActionType.Entity}
              />

              <MDBRadio
                onChange={(e) => {
                  props.setAction({
                    ...props.action,
                    type: ESectionActionType.Link,
                  });
                }}
                name={`actionTypeRadio${props.id}`}
                value={ESectionActionType.Link}
                label="Åbn link"
                checked={props.action.type === ESectionActionType.Link}
              />
              {props.action.type === ESectionActionType.Link && (
                <MDBInput
                  className="m-2"
                  defaultValue={props.action.link}
                  label="Indtast en URL"
                  type="text"
                  onChange={(e) =>
                    props.setAction({
                      ...props.action,
                      link: e.target.value,
                    })
                  }
                />
              )}
              <hr />
              <MDBRow>
                <MDBCol md={6} lg={6}>
                  <MDBSwitch
                    checked = {props.action.sequential}
                    id="flexSwitchCheckDefault"
                    label="Sekventiel"
                    onChange={(e) => {
                      props.setAction({
                      ...props.action,
                      sequential: e.target.checked,
                      });
                    }}
                  />
                </MDBCol>
                <MDBCol md={6} lg={6}>
                  <MDBSwitch
                    checked = {props.action.oneclick}
                    id="flexSwitchCheckChecked"
                    label="1-Klik"
                    onChange={(e) => {
                      props.setAction({
                      ...props.action,
                      oneclick: e.target.checked,
                      });
                    }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol md={12} lg={8}>
              {props.action.type === ESectionActionType.CrmScript && (
                <>
                  <MDBRow>
                    <MDBCol size={6}>
                      <h4>Action parametre</h4>
                    </MDBCol>
                    <MDBCol className="text-end" size={6}>
                      <SaveEditBtn
                        hideEdit
                        hideCancel
                        onClickCancel={(e) => {}}
                        onClickCreate={(e: any) => {
                          e.preventDefault();
                          setActiveParameter({
                            index: -1,
                            parameter: blankParameter,
                            showModal: true,
                          });
                        }}
                        createMode={activeParameter.showModal}
                        editMode={false}
                        onClickEdit={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <div className="tableWrap">
                      <MDBTable small striped>
                        <MDBTableHead>
                          <tr>
                            <th scope="col">Felt</th>
                            <th scope="col">Værdi</th>
                          </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                          {props.action.params.length === 0 && (
                            <tr>
                              <td colSpan={2}>Ingen parametre</td>
                            </tr>
                          )}
                          {props.action.params.map((param, index) => (
                            <tr key={index}>
                              <td>{param.name}</td>
                              <td>
                                {param.type === EActionParameterType.Custom &&
                                  param.value}
                                {param.type === EActionParameterType.So &&
                                  param.label}
                              </td>
                              <td className="text-end">
                                <MDBBtn
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setActiveParameter({
                                      index: index,
                                      parameter: param,
                                      showModal: true,
                                    });
                                  }}
                                  size="sm"
                                  color="warning"
                                >
                                  <MDBIcon fas icon="pencil-alt" />
                                </MDBBtn>
                                <MDBBtn
                                  className="ms-1"
                                  color="danger"
                                  size="sm"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    let tempParameters = [
                                      ...props.action.params,
                                    ];
                                    tempParameters.splice(index, 1);
                                    props.setAction({
                                      ...props.action,
                                      params: tempParameters,
                                    });
                                  }}
                                >
                                  <MDBIcon fas icon="trash" />
                                </MDBBtn>
                              </td>
                            </tr>
                          ))}
                        </MDBTableBody>
                      </MDBTable>
                    </div>
                  </MDBRow>
                </>
              )}
            </MDBCol>
          </MDBRow>
        </form>
      </MDBAccordionItem>
      <ModalWrapper>
        <SelectCRMScript
          setShow={setModalCRMScript}
          show={modalCRMScript}
          setModal={(show: boolean, element?: ISuOCRMScript) => {
            setModalCRMScript(false);

            if (typeof element !== "undefined") {
              setScriptAndGetParameter(element);
            }
          }}
        />
      </ModalWrapper>
      <ModalWrapper>
        <CreateEditActionParameterModal
          sectionType={props.sectionType}
          parameter={activeParameter.parameter}
          setParameter={(changedParameter: IActionParameter) => {
            if (activeParameter.index === -1) {
              const newParameterArray = [
                ...props.action.params,
                changedParameter,
              ];
              props.setAction({ ...props.action, params: newParameterArray });
            } else {
              let tempParams = [...props.action.params];
              tempParams[activeParameter.index] = changedParameter;
              props.setAction({ ...props.action, params: tempParams });
            }
          }}
          show={activeParameter.showModal}
          setModal={(show: boolean) => {
            setActiveParameter({
              ...activeParameter,
              showModal: show,
            });
          }}
        />
      </ModalWrapper>
    </>
  );
}
