import React, { useState } from "react";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBIcon,
  MDBRow,
} from "mdb-react-ui-kit";
import "./PreviewTable.scoped.scss";
import { IEmail, ISuOAssociate } from "doweb-alfred-backend-client";

interface PreviewTableProps {
    emails: IEmail[];
}

export default function PreviewTable(props: PreviewTableProps) {
  const [showHtml, setShowHtml] = useState(-1);
   
  const makeInitials = (name: string) => {
    //Only firstname and the last lastname is used
    const names = name.split(" ");
    const firstName = names[0];
    const lastName = names[names.length - 1];
    //Get first letter of firstname and lastname
    const firstInitial = firstName.charAt(0);
    const lastInitial = lastName.charAt(0);
    //Return initials
    return firstInitial + lastInitial;
  };

  return (
    <>
      {showHtml > -1 && (
        <>
          {/* Back button, and name of the email recipient */}
          <MDBRow className="mb-4">
            <MDBCol>
              <MDBBtn
                onClick={() => setShowHtml(-1)}
                color="primary"
                className="mb-4"
                size="sm"
                outline
              >
                <MDBIcon icon="arrow-left" className="mr-2" /> Tilbage
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          <iframe
            srcDoc={props.emails[showHtml].html}
            style={{ width: "100%", height: "70vh", border: "none" }}
          />
        </>
      )}
      {showHtml == -1 && (
        <>
          <MDBRow>
            <MDBCol>
              Disse <b>{props.emails.length}</b> personer ville modtage en mail fra
              Alfred, hvis jobbet blev kørt nu. Du kan klikke på personen og se
              den mail de modtager.
            </MDBCol>
          </MDBRow>
          <MDBRow className="PreviewTable">
            {props.emails.map(
              (email, index) =>
                email.associate && (
                  <MDBCol key={index} xl={4} lg={6} className="mb-4">
                    <MDBCard onClick={() => setShowHtml(index)}>
                      <MDBCardBody>
                        <div className="d-flex align-items-center">
                          <div
                            style={{
                              width: "45px",
                              height: "45px",
                              fontSize: "1.5rem",
                              lineHeight: "45px",
                              textAlign: "center",
                              backgroundColor: "#e3e3e3",
                              textTransform: "uppercase",
                            }}
                            className="rounded-circle"
                          >
                            {makeInitials(email.associate.name)}
                          </div>
                          <div className="ms-3">
                            <p className="fw-bold mb-1">
                              {email.associate.name}
                            </p>
                            <p className="text-muted mb-0">{email.to}</p>
                          </div>
                        </div>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                )
            )}
          </MDBRow>
        </>
      )}
    </>
  );
}
