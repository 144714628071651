import { ESectionEntity, ICurrentUser, IJobListItem, ISectionAction, ISuOAssociate, ISuOCRMScriptTree, ISuOGroup, ITemplate } from "doweb-alfred-backend-client"

export interface ISuoGroupAssocs extends ISuOGroup{
  name: any;
  associates: ISuOAssociate[]
}

export enum EAsyncLoadStatus {
  idle = 0,
  loading = 1,
  success = 2,
  error = 3
};

 export interface IToastItem{
  color: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'light' | 'dark' | 'info';
  body: string;
  count: number;
 }

export interface ICurrentUserState {
  currentUser: ICurrentUser | null;
  status: EAsyncLoadStatus;
  error: string | null | undefined;
};
export const defaultCurrentUser : ICurrentUserState = {
  currentUser: null,
  status: EAsyncLoadStatus.idle,
  error: null
};
export interface IInitialJobState {
  openJob: string;
  hasChanged: boolean;
  status: EAsyncLoadStatus;
  initialLoad: EAsyncLoadStatus;
  error: string | null | undefined;
  collection: IJobListItem[];
}
export interface IInitialActionState {
  openEntity: ESectionEntity;
  status: EAsyncLoadStatus; 
  initialLoad: EAsyncLoadStatus;
  error: string | null | undefined;
  collection: ISectionAction[];
}
export interface IInitialListState {
  associates: {
    status: EAsyncLoadStatus;
    error: string | null | undefined;
    list: ISuOAssociate[];
  },
  groups: {
    status: EAsyncLoadStatus;
    error: string | null | undefined;
    list: ISuoGroupAssocs[];
  };
  crmscripts: {
    status: EAsyncLoadStatus;
    error: string | null | undefined;
    list: ISuOCRMScriptTree[];
  };
  fields: {
    status: EAsyncLoadStatus;
    error: string | null | undefined;
    contact: any[];
    person: any[];
    sale: any[];
    project: any[];
    document: any[];
    appointment: any[];
  };
  templates: {
    status: EAsyncLoadStatus;
    error: string | null | undefined;
    list: ITemplate[];
  };
}
