import { RootState } from './../index';
import { defaultCurrentUser, EAsyncLoadStatus } from './../../assets/types/General.d';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Configuration, GeneralApi } from 'doweb-alfred-backend-client';



const fetchCurrentUser = createAsyncThunk(
    'user/fetchCurrentUser',
    async (accesstoken : string, thunkApi) => {
        try{
        const general = new GeneralApi(
            new Configuration({
              accessToken: accesstoken,
              basePath: process.env.REACT_APP_BACKEND_BASEURL,
            })
          );
            const response = await general.generalCurrentUserLoginGet();
            return response.data
        }
        catch(error : any){
          return thunkApi.rejectWithValue(error.message);
        }
    }
  )

export const userSlice = createSlice({
    name:"user",
    initialState: defaultCurrentUser,
    reducers: {
    
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
    .addCase(fetchCurrentUser.fulfilled, (state, action) => {
      // Add user to the state array
      state.currentUser = action.payload;
      state.status = EAsyncLoadStatus.success;
    })
    .addCase(fetchCurrentUser.pending, (state, action) => {
        state.status = EAsyncLoadStatus.loading;
    })
    .addCase(fetchCurrentUser.rejected, (state, action) => {
        state.status = EAsyncLoadStatus.error;
        state.error = String(action.payload);
    })
  },
}
)

// Action creators are generated for each case reducer function
//export const { setuser } = userSlice.actions
export {fetchCurrentUser};
export const getUserStatus = (state : RootState) => state.user.status;
export const getUserError = (state : RootState) => state.user.error;
export const getCurrentUser = (state: RootState) => state.user.currentUser;
export default userSlice.reducer