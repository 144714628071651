import React, { MouseEventHandler } from "react";
import "./SaveEditBtn.scoped.scss";
import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";

interface SaveEditBtnProps {
  editMode?: boolean;
  onClickEdit: MouseEventHandler;
  onClickCreate: MouseEventHandler;
  onClickCancel: MouseEventHandler;
  createMode?: boolean;
  hideEdit?: boolean;
  hideCancel?: boolean;
  newText?: string;
  editText?: string;
}

export function SaveEditBtn(props: SaveEditBtnProps) {
  let editClasses = ["editBtn"];
  let createClasses = ["createBtn"];
  if (props.editMode) {
    
    if(props.hideCancel){
      editClasses.push("active");
      createClasses.push("inactive");
    }
    else {
      editClasses.push("cancelBtns");
      createClasses.push("cancelBtns");
    }
    
  }
  if (props.hideEdit && !props.hideCancel) {
    createClasses.push("full");
  }
  return (
    <>
      <MDBBtn
        onClick={(e) => (!props.editMode && props.onClickCreate(e)) || (props.editMode && props.onClickCancel(e))}
        className={createClasses.join(" ")}
        color={(!props.editMode && "success") || (props.editMode && "danger") || 'success'}
        size="sm"
      >
        <MDBIcon fas icon={!props.editMode ? "plus" : "times"} />
          {!props.editMode && (props.newText === undefined ? " Opret ny" : ` ${props.newText}`)}
          {props.editMode && " Annuller"}
      </MDBBtn>
      {!props.hideEdit && (
        <MDBBtn
          size="sm"
          className={editClasses.join(" ")}
          color={!props.editMode ? "warning" : "success"}
          onClick={(e) => props.onClickEdit(e)}
        >
          <MDBIcon fas icon={!props.editMode ? "pencil-alt" : "check"} />
          {props.editMode && " Gem"}
        </MDBBtn>
      )}
    </>
  );
}
