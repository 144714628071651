import { configureStore } from '@reduxjs/toolkit'
import JobReducer from './features/JobSlice' 
import DrawerReducer from './features/DrawerSlice' 
import UserReducer from './features/UserSlice'
import ListReducer from './features/ListSlice'
import ActionReducer from '././features/ActionSlice'
export const store = configureStore({
 
    reducer: {
      job: JobReducer, 
      drawer: DrawerReducer,
      user: UserReducer,
      list: ListReducer,
      action: ActionReducer,
    },
  })
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState> 
export type AppDispatch = typeof store.dispatch