import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  EAsyncLoadStatus,
  IInitialListState,
  ISuoGroupAssocs,
} from "../../assets/types/General.d";
import {
  ListApi,
  Configuration,
  ISuOAssociate,
  TemplateApi,
  CRMScriptApi,
  ITemplate
} from "doweb-alfred-backend-client";
import { RootState } from ".."; 

const initialState: IInitialListState = {
  associates: {
    status: EAsyncLoadStatus.idle,
    error: null,
    list: [],
  },
  groups: {
    status: EAsyncLoadStatus.idle,
    error: null,
    list: [],
  },
  crmscripts: {
    status: EAsyncLoadStatus.idle,
    error: null,
    list: [],
  },
  fields: {
    status: EAsyncLoadStatus.idle,
    error: null,
    contact: [],
    person: [],
    sale: [],
    project: [],
    document: [],
    appointment: [],
  },
  templates: {
    status: EAsyncLoadStatus.idle,
    error: null,
    list: [],
  },
};
interface deleteTemplateThunk {
  accesstoken: string;
  id: string;
}
export interface editTemplateThunk {
  accesstoken: string;
  template: ITemplate; 
}

interface createDefaultTemplateThunk {
  accesstoken: string;
}
/*
Gets all the lists from the server
*/
export const fetchAssociates = createAsyncThunk(
  "list/fetchAssociates",
  async (accesstoken: string, thunkApi) => {
    try {
      const lists = new ListApi(
        new Configuration({
          accessToken: accesstoken,
          basePath: process.env.REACT_APP_BACKEND_BASE_URL,
        })
      );
      const response = await lists.listAssociatesGet();
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
/*
Fetch the list of templates
*/
export const fetchTemplates = createAsyncThunk(
  "list/fetchTemplates",
  async (accesstoken: string, thunkApi) => {
    try {
      const lists = new TemplateApi(
        new Configuration({
          accessToken: accesstoken,
          basePath: process.env.REACT_APP_BACKEND_BASE_URL,
        })
      );
      const response = await lists.templateGet();
      return response.data.list;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
/*
Creates a default Template on the server.
*/
export const createDefaultTemplate = createAsyncThunk(
  "Templates/createDefaultTemplate",
  async (input: createDefaultTemplateThunk, thunkApi) => {
    try {
      const Templates = new TemplateApi(
        new Configuration({
          accessToken: input.accesstoken,
          basePath: process.env.REACT_APP_BACKEND_BASE_URL,
        })
      );
      const response = await Templates.templateCreateDefault();
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
/*
  Called whenever an edited job is saved from a single job pane.
  Handles the updating of most of the job details
*/

export const editTemplate = createAsyncThunk(
  "templates/editTemplate",
  async (input: editTemplateThunk, thunkApi) => {
    try {
      const Templates = new TemplateApi(
        new Configuration({
          accessToken: input.accesstoken,
          basePath: process.env.REACT_APP_BACKEND_BASE_URL,
        })
      );

       await Templates.templateIdPut(String(input.template._id), input.template); 
      return input.template ;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

/*
  When a single job is deleted via the general form, this method is called.
  It currently has a malfunction (and perhaps it shouldn't even be in the UI, as it duplicates functionality in an odd place)
*/
export const deleteTemplate = createAsyncThunk(
  "templates/deleteTemplate",
  async (input: deleteTemplateThunk, thunkApi) => {
    try {
      const Templates =
      new TemplateApi(
        new Configuration({
          accessToken: input.accesstoken,
          basePath: process.env.REACT_APP_BACKEND_BASE_URL,
        })
      );
       
      await Templates.templateIdDelete(input.id);
     
      return input.id;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

/*
Gets all the lists from the server
*/
export const fetchGroups = createAsyncThunk(
  "list/fetchGroups",
  async (accesstoken: string, thunkApi) => {
    try {
      const lists = new ListApi(
        new Configuration({
          accessToken: accesstoken,
          basePath: process.env.REACT_APP_BACKEND_BASE_URL,
        })
      );
      const response = await lists.listUsergroupsGet();
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

/*
Gets all the lists from the server
*/
export const fetchCRMScripts = createAsyncThunk(
  "list/fetchCRMScripts",
  async (accesstoken: string, thunkApi) => {
    try {
      const lists = new CRMScriptApi(
        new Configuration({
          accessToken: accesstoken,
          basePath: process.env.REACT_APP_BACKEND_BASE_URL,
        })
      );
      const response = await lists.crmscriptBuildTreeGet();
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const fetchFields = createAsyncThunk(
  "list/fetchFields",
  async (accesstoken: string, thunkApi) => {
    try {
      const lists = new ListApi(
        new Configuration({
          accessToken: accesstoken,
          basePath: process.env.REACT_APP_BACKEND_BASE_URL,
        })
      );
      const response_contact = await lists.listColumnsProviderGet(
        "FindContact"
      );
      const response_person = await lists.listColumnsProviderGet("FindPerson");
      const response_sale = await lists.listColumnsProviderGet("FindSale");
      const response_project = await lists.listColumnsProviderGet(
        "FindProject"
      );
      const response_document = await lists.listColumnsProviderGet(
        "FindDocument"
      );
      const response_appointment = await lists.listColumnsProviderGet(
        "FindAppointment"
      );
      return {
        contact: response_contact.data,
        person: response_person.data,
        sale: response_sale.data,
        project: response_project.data,
        appointment: response_appointment.data,
        document: response_document.data,
      };
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const listSlice = createSlice({
  name: "list",
  initialState: initialState,
  reducers: {
    buildAssociateTree: (state) => {
      //Check if state.associates.list is defined
      if (state.associates.list) {
      //add associates to group tree
      let groupArray: ISuoGroupAssocs[] = state.groups.list.map((group) => {
        return { ...group, associates: [] };
      });
      state.associates.list.forEach((associate) => {
        const foundGroup = groupArray.findIndex(
          (group) => group.name === associate.group[0]
        );
        if (foundGroup > -1) {
          groupArray[foundGroup].associates.push(associate);
        }
      });
      state.groups.list = groupArray;
      const unique_ids: number[] = [];
      state.associates.list.map((associate) => {
        const isDuplicate = unique_ids.includes(associate.associateId);
        if (!isDuplicate) {
          unique_ids.push(associate.associateId);
        }
        return associate;
      });
      const merged_associate: ISuOAssociate[] = {
        ...unique_ids.map((id) => {
          const find_associate = state.associates.list.filter(
            (associate) => associate.associateId === id
          );
          let this_associate: ISuOAssociate;
          if (find_associate.length >= 1) {
            this_associate = {
              ...find_associate[0],
              group: find_associate.map((ass) => ass.group[0]),
            };
          } else {
            this_associate = {
              ...find_associate[0],
              group: find_associate[0].group,
            };
          }
          return this_associate;
        }),
      };
      state.associates.list = merged_associate;
      }
    },

  },
  extraReducers: (builder) => {
    /*
      This segment handles the asynchronous responsibilities of updating the entire list collection (larger delete operations)
    */
    builder
      .addCase(fetchAssociates.fulfilled, (state, action) => {
        state.associates.list = action.payload;
        state.associates.status = EAsyncLoadStatus.success;
      })
      .addCase(fetchAssociates.pending, (state, action) => {
        state.associates.status = EAsyncLoadStatus.loading;
        state.associates.list = [];
      })
      .addCase(fetchAssociates.rejected, (state, action) => {
        state.associates.error = String(action.payload);
        state.associates.status = EAsyncLoadStatus.error;
      })
      /*
      This segment handles the asynchronous responsibilities of list editing (updating an edited list)
    */
      .addCase(fetchGroups.fulfilled, (state, action) => {
        const groupAssocs: ISuoGroupAssocs[] =
          action.payload.map<ISuoGroupAssocs>((group) => {
            const newGroup = { ...group, associates: [] };
            return newGroup;
          });
        state.groups.list = groupAssocs;
        state.groups.status = EAsyncLoadStatus.success;
      })
      .addCase(fetchGroups.pending, (state, action) => {
        state.groups.status = EAsyncLoadStatus.loading;
        state.groups.list = [];
      })
      .addCase(fetchGroups.rejected, (state, action) => {
        state.groups.error = String(action.payload);
        state.groups.status = EAsyncLoadStatus.error;
      })
      /*
      This segment handles the asynchronous fetching of templates
    */
      .addCase(fetchTemplates.fulfilled, (state, action) => {
        state.templates.list = action.payload;
        state.templates.status = EAsyncLoadStatus.success;
      })
      .addCase(fetchTemplates.pending, (state, action) => {
        state.templates.status = EAsyncLoadStatus.loading;
        state.templates.list = [];
      })
      .addCase(fetchTemplates.rejected, (state, action) => {
        state.templates.error = String(action.payload);
        state.templates.status = EAsyncLoadStatus.error;
      })
      /*
      This segment handles the asynchronous responsibilities of job editing (updating an edited job)
    */
      .addCase(editTemplate.fulfilled, (state, action) => {
        const theTemplateIndex = state.templates.list.findIndex(
          (theTemplate : ITemplate) => theTemplate._id === action.payload._id
        );
        state.templates.list[theTemplateIndex] = action.payload;
        state.templates.status = EAsyncLoadStatus.idle; 
      
        state.templates.status = EAsyncLoadStatus.idle;
      })
      .addCase(editTemplate.pending, (state, action) => {
        state.templates.status = EAsyncLoadStatus.loading;
      })
      .addCase(editTemplate.rejected, (state, action) => {
        state.templates.status = EAsyncLoadStatus.error;
        state.templates.error = action.payload as string;
      })
      /*
      This segment handles the asynchronous responsibilities of Template deletion
    */
      .addCase(deleteTemplate.fulfilled, (state, action) => { 
        //action.payload.splice(action.payload, 1);
        state.templates.list = state.templates.list.filter((item : ITemplate) => item._id !== action.payload);
        state.templates.status = EAsyncLoadStatus.idle;
      })
      .addCase(deleteTemplate.pending, (state, action) => {
        state.templates.status = EAsyncLoadStatus.loading;
      })
      .addCase(deleteTemplate.rejected, (state, action) => {
        state.templates.status = EAsyncLoadStatus.error;
        state.templates.error = action.payload as string;
      })
       /*
      This segment handles the creation of new jobs
    */
      .addCase(createDefaultTemplate.fulfilled, (state, action) => {
        state.templates.list.push(action.payload);
        state.templates.status = EAsyncLoadStatus.idle;
      })
      .addCase(createDefaultTemplate.pending, (state, action) => {
        state.templates.status = EAsyncLoadStatus.loading;
      })
      .addCase(createDefaultTemplate.rejected, (state, action) => {
        state.templates.status = EAsyncLoadStatus.error;
        state.templates.error = action.payload as string;
      })
      /*
      This segment handles the asynchronous fetching of templates
    */
      .addCase(fetchCRMScripts.fulfilled, (state, action) => {
        state.crmscripts.list = action.payload;
        state.crmscripts.status = EAsyncLoadStatus.success;
      })
      .addCase(fetchCRMScripts.pending, (state, action) => {
        state.crmscripts.status = EAsyncLoadStatus.loading;
        state.crmscripts.list = [];
      })
      .addCase(fetchCRMScripts.rejected, (state, action) => {
        state.crmscripts.error = String(action.payload);
        state.crmscripts.status = EAsyncLoadStatus.error;
      })
      /*
      This segment handles the asynchronous responsibilities of list editing (updating an edited list)
      */
      .addCase(fetchFields.fulfilled, (state, action) => {
        state.fields = { ...state.fields, ...action.payload };
        state.fields.status = EAsyncLoadStatus.success;
      })
      .addCase(fetchFields.pending, (state, action) => {
        state.fields.status = EAsyncLoadStatus.loading;
        state.fields = {
          ...state.fields,
          contact: [],
          person: [],
          sale: [],
          project: [],
          document: [],
          appointment: [],
        };
      })
      .addCase(fetchFields.rejected, (state, action) => {
        state.fields.error = String(action.payload);
        state.fields.status = EAsyncLoadStatus.error;
      });
  },
});

// Action creators are generated for each case reducer function
export const { buildAssociateTree } = listSlice.actions;
export const getAssociates = (state: RootState) => state.list.associates;
export const getGroups = (state: RootState) => state.list.groups;
export const getCRMScripts = (state: RootState) => state.list.crmscripts;
export const getFields = (state: RootState) => state.list.fields;
export const getTemplates = (state: RootState) => state.list.templates;
export default listSlice.reducer;
