import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  MDBContainer,
  MDBNavbar,
  MDBIcon,
  MDBBtn, 
  MDBCol,
} from "mdb-react-ui-kit";
import {useSelector, useDispatch} from 'react-redux';
import { RootState } from "../store";
import { setDrawer } from "../store/features/DrawerSlice";
import { getCurrentUser, getUserStatus } from "../store/features/UserSlice";
import { EAsyncLoadStatus } from "../assets/types/General.d";
export default function Topnav() {
  const drawerOpen = useSelector((state: RootState) => state.drawer.value);
  const currentUser = useSelector(getCurrentUser);
  const userStatus = useSelector(getUserStatus);
  const dispatch = useDispatch();
  const {  
    user
  } = useAuth0(); 
  return (
    <MDBNavbar id="main-navbar" expand="lg" light fixed="top" bgColor="light">
      <MDBContainer fluid>
       
          <MDBCol size={6}>
            <MDBBtn
              onClick={() => dispatch(setDrawer(!drawerOpen))}
              className="shadow-0 p-0 me-3 d-block"
              color="light"
            >
              <MDBIcon icon="bars" size="lg" fas /> Åben menu
            </MDBBtn>
          </MDBCol>
          <MDBCol size={6} className="text-end"> 
            {userStatus === EAsyncLoadStatus.success && <div className="d-flex flex-row-reverse">
            <div className="">
                  <img
            src={user!.picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture-top mb-3 mb-md-0"
          />
                  </div>
                  <div className="pe-2 pt-1">
                  <span style={{fontSize: 14}}>Velkommen {String(currentUser?.name)}</span> 
                  </div>
               
                  </div> }
          </MDBCol>
         
      </MDBContainer>
    </MDBNavbar>
  );
}
