import { useEffect, useState } from "react";
import { CriteriaGroupModal } from "./Modals";
//import { ISectionCriteria } from "doweb-alfred-backend-client";
import {
  MDBBtn,
  MDBIcon,
  MDBCol,
  MDBRow,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import { ModalWrapper } from "../../ModalWrapper";
import {
  ESectionEntity,
  ISectionCriteriaGroup,
} from "doweb-alfred-backend-client";
interface Props {
  sectionType: ESectionEntity;
  setCriteriaGroups: Function;
  criteriaGroups: ISectionCriteriaGroup[];
}

export function Criteria(props: Props) {
  const [modal, setModal] = useState(false);
  //state for criteria groups
  const [criteriaGroups, setCriteriaGroups] = useState(props.criteriaGroups);

  //UseEffect to update the criteria groups
  useEffect(() => {
    setCriteriaGroups(props.criteriaGroups);
  }, [props.criteriaGroups]);

  let sortedCriteriaGroups: ISectionCriteriaGroup[] = [];
  let sortedCriteriaGroupsWithSortedCriteria: ISectionCriteriaGroup[] = [];
  //Sort props.criteriaGroups by rank
  if (criteriaGroups.length > 0) {
    sortedCriteriaGroups = [...criteriaGroups].sort((a, b) => a.rank - b.rank);
    //Sort each criteria inside each criteria group by rank
    sortedCriteriaGroupsWithSortedCriteria = [...sortedCriteriaGroups].map(
      (criteriaGroup) => {
        const sortedCriteria = [...criteriaGroup.criterias].sort(
          (a, b) => a.rank - b.rank
        );
        return { ...criteriaGroup, criterias: sortedCriteria };
      }
    );
  }
  return (
    <>
      <MDBRow className="mb-3">
        <MDBCol size={8}>
          Der er opsat {sortedCriteriaGroupsWithSortedCriteria.length}{" "}
          kriteriegrupper i denne sektion
        </MDBCol>
        <MDBCol size={4}>
          <div className="d-grid gap-2">
            <MDBBtn
              onClick={(e) => {
                e.preventDefault();
                setModal(true);
              }}
              size="sm"
              color="primary"
            >
              <MDBIcon fas icon="pencil-alt" />
              &nbsp;Rediger
            </MDBBtn>
          </div>
        </MDBCol>
        <ModalWrapper>
          <CriteriaGroupModal
            sectionType={props.sectionType}
            show={modal}
            setModal={setModal}
            setCriterias={props.setCriteriaGroups}
            criterias={sortedCriteriaGroupsWithSortedCriteria}
          />
        </ModalWrapper>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <div className="tableWrap">
            <MDBTable small striped>
              <MDBTableHead>
                <tr>
                  <th scope="col">Gruppe</th>
                  <th scope="col">Antal kriterier</th>
                  <th scope="col">Og / Eller</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {sortedCriteriaGroupsWithSortedCriteria.length === 0 && (
                  <tr>
                    <td colSpan={3}>Ingen kriteriegrupper oprettet</td>
                  </tr>
                )}
                {sortedCriteriaGroupsWithSortedCriteria
                  .filter((criteriaGroup) => criteriaGroup.criterias.length > 0)
                  .map(
                    (criteriaGroup: ISectionCriteriaGroup, index: number) => (
                      <tr key={index}>
                        <td>{criteriaGroup.name}</td>
                        <td>{criteriaGroup.criterias.length}</td>
                        <td>
                          {
                            /* If this is not the last criteriagroup*/
                            index + 1 !==
                              sortedCriteriaGroupsWithSortedCriteria.filter((criteriaGroup) => criteriaGroup.criterias.length > 0).length && (
                              <>
                                {criteriaGroup.logicalOperatorGroup === "or" &&
                                  "Eller"}
                                {criteriaGroup.logicalOperatorGroup === "and" &&
                                  "Og"}
                              </>
                            )
                          }
                        </td>
                      </tr>
                    )
                  )}
              </MDBTableBody>
            </MDBTable>
          </div>
        </MDBCol>
      </MDBRow>
    </>
  );
}
