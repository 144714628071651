import { PeriodPanes } from "./PeriodPanes";
import { TargetGroup } from "./TargetGroup";
import { WysiwygEditor } from "../WysiwygEditor";
import {
  MDBCol,
  MDBRow,
  MDBAccordion,
  MDBAccordionItem,
  MDBCard,
  MDBCardBody,
  MDBSelect,
  MDBSwitch,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBSpinner,
} from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import {
  ETemplateType,
  IJobPlanning,
  ITargetGroup,
  IJobListItem,
} from "doweb-alfred-backend-client";
import { AppDispatch, RootState } from "../../store";
import { deleteJob } from "../../store/features/JobSlice";
import { getJobStatus } from "../../store/features/JobSlice";
import { Fragment, useEffect, useRef, useState } from "react";
import { Editor as TinyMCEEditor } from "tinymce";
import { useAuth0 } from "@auth0/auth0-react";
import { EAsyncLoadStatus } from "../../assets/types/General.d";
import { Toast } from "../Toast";
import PreviewJob from "../PreviewJob";

interface Props {
  jobData: IJobListItem;
  saveJobData: Function;
  isChanged: boolean;
  setIsChanged: Function;
}

//necessary for toast to fire correctly - onHide shoots together with onShow.

export function GeneralSettings(props: Props) {
  const jobSyncStatus = useSelector(getJobStatus);
  const { getAccessTokenSilently } = useAuth0();

  const templateData = useSelector(
    (state: RootState) => state.list.templates.list
  );
  const jobData = props.jobData;
  const [jobCache, setJobCache] = useState(jobData);
  const [showToast, setShowToast] = useState(false);
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const subjectRef = useRef<HTMLInputElement | null>(null);
  const enabledRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setJobCache(jobData);
  }, [jobData, setJobCache]);
  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setContent(
        typeof jobData.text === "undefined" ? "" : jobData.text
      );
    }
    if (enabledRef.current) {
      enabledRef.current.checked = jobData.enabled;
    }
    if (subjectRef.current) {
      subjectRef.current.value =
        typeof jobData.subject === "undefined" ? "" : jobData.subject;
    }
  }, [jobData, editorRef, subjectRef, enabledRef]);

  const changeTemplateHandler = (templateId: string) => {
    props.setIsChanged(true);
    setJobCache({ ...jobCache, _template: templateId });
  };

  const dispatch = useDispatch<AppDispatch>();
  const getEditorContent = (): string => {
    if (editorRef.current) {
      return editorRef.current.getContent({ format: "html" });
    } else {
      return "";
    }
  };

  return (
    <Fragment>
      <Toast
        toast={showToast}
        setToast={setShowToast}
        message={"Job er gemt"}
        color="success"
      />
      <MDBAccordion initialActive={1} show={true}>
        <MDBAccordionItem
          show={false}
          collapseId={1}
          headerTitle="Generelle Indstillinger"
          className="mb-4"
        >
          <MDBRow>
            <MDBCol xl="5" lg="12">
              <MDBRow className="mb-3">
                <MDBCard border="#e3e3e3">
                  <MDBCardBody>
                    <PeriodPanes
                      data={jobCache.planning}
                      setData={(changedPlanning: IJobPlanning) => {
                        props.setIsChanged(true);
                        setJobCache({ ...jobCache, planning: changedPlanning });
                      }}
                    />
                  </MDBCardBody>
                </MDBCard>
              </MDBRow>
              <MDBRow className="mb-4">
                {/* målgruppe */}
                <TargetGroup
                  targetGroup={jobCache.targetGroup}
                  setTargetGroup={(changedTargetGroup: ITargetGroup) => {
                    props.setIsChanged(true);
                    setJobCache({
                      ...jobCache,
                      targetGroup: changedTargetGroup,
                    });
                  }}
                />
              </MDBRow>
              <MDBRow />
            </MDBCol>
            {/* Wysiwyg her */}
            <MDBCol xl="7" lg="12">
              <MDBRow className="mb-1">
                <MDBCol>
                  <MDBInput
                    label="Emnelinje"
                    type="text"
                    defaultValue={jobData.subject}
                    ref={subjectRef}
                    onBlur={(e) => {
                      props.setIsChanged(true);
                      setJobCache({ ...jobCache, subject: e.target.value });
                    }}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol>
                  <WysiwygEditor
                    defaultVal={jobCache.text}
                    editorRef={editorRef}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-2">
                <MDBCol size={12}>
                  <div className="d-grid">
                    <MDBSelect
                      onValueChange={(e: any) => changeTemplateHandler(e.value)}
                      label="Skabelon"
                      data={[
                        { text: "Vælg en template", value: "" },
                        ...templateData
                          .filter((e) => {
                            return e.type === ETemplateType.Wrapper;
                          })
                          .map((element) => {
                            return {
                              text: element.name,
                              value: element._id,
                              defaultSelected:
                                element._id === jobCache._template,
                            };
                          }),
                      ]}
                    />
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <div className="d-flex flex-row-reverse">
                    <div className="pt-2 pb-2 ps-2">
                      <MDBBtn
                        onClick={(e) => {
                          e.preventDefault();
                          getAccessTokenSilently().then((token) =>
                            dispatch(
                              deleteJob({
                                accesstoken: token,
                                id: props.jobData._id ? props.jobData._id : "",
                              })
                            )
                          );
                        }}
                        outline
                        color="danger"
                      >
                        <MDBIcon far icon="trash-alt" />
                        &nbsp;Slet
                      </MDBBtn>
                    </div>
                    <div className="pt-2 pb-2 ps-2">
                      <MDBBtn
                        onClick={(e) => {
                          e.preventDefault();
                          props.saveJobData({
                            ...jobCache,
                            text: getEditorContent(),
                          });
                        }}
                        outline
                        color="primary"
                        disabled={jobSyncStatus === EAsyncLoadStatus.loading}
                      >
                        {jobSyncStatus !== EAsyncLoadStatus.loading && (
                          <>
                            <MDBIcon far icon="save" />
                            &nbsp;Gem
                          </>
                        )}
                        {jobSyncStatus === EAsyncLoadStatus.loading && (
                          <>
                            <MDBSpinner size="sm" role="status" tag="span" />
                            &nbsp;Gemmer...
                          </>
                        )}
                      </MDBBtn>
                    </div>

                    <div className="p-2 pt-3">
                      <MDBSwitch
                        defaultChecked={jobCache.enabled}
                        inputRef={enabledRef}
                        label="Aktiver jobbet"
                        onChange={(e) => {
                          props.setIsChanged(true);
                          setJobCache({
                            ...jobCache,
                            enabled: !jobCache.enabled,
                          });
                        }}
                      />
                    </div>
                    {props.jobData._id && (
                      <div className="pt-2 pb-2 ps-2">
                        <PreviewJob outline color="warning" jobId={props.jobData._id}>
                          <MDBIcon far icon="eye" /> Forhåndsvis
                        </PreviewJob>
                      </div>
                    )}
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBAccordionItem>
      </MDBAccordion>
    </Fragment>
  );
}
