

export function getConfig() {
 
  return {
    domain:  typeof process.env.REACT_APP_AUTH0_DOMAIN === 'undefined' ? '' : String(process.env.REACT_APP_AUTH0_DOMAIN),
    clientId:  typeof process.env.REACT_APP_AUTH0_CLIENTID === 'undefined' ? '' : String(process.env.REACT_APP_AUTH0_CLIENTID),
    audience: typeof process.env.REACT_APP_AUTH0_AUDIENCE === 'undefined' ? '' : String(process.env.REACT_APP_AUTH0_AUDIENCE)
  };
}
