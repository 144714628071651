import { WysiwygEditor } from "./../WysiwygEditor";
import { Editor as TinyMCEEditor } from "tinymce";
import {
  MDBCol,
  MDBRow,
  MDBAccordionItem,
  MDBCard,
  MDBCardBody,
  MDBSelect,
  MDBInput,
  MDBSwitch,
  MDBBtn,
  MDBIcon,
} from "mdb-react-ui-kit";
import "./Section.scoped.scss";
import { Criteria } from "./Criteria";
import { useSelector } from "react-redux";

import {
  ESectionEntity,
  ISection,
  ETemplateType,
  ISectionCriteriaGroup,
} from "doweb-alfred-backend-client";
import { RootState } from "../../store";
import { useEffect, useRef, useState } from "react";
interface Props {
  sectionIndex: number;
  section: ISection;
  setSection: Function;
  deleteSection: Function;
  isChanged: boolean;
  setIsChanged: Function;
}
export function Section(props: Props) {
  const sectionData: ISection = props.section;
  const templateData = useSelector(
    (state: RootState) => state.list.templates.list
  );
  const [sectionCache, setSectionCache] = useState(sectionData);
  const editorRef = useRef<TinyMCEEditor | null>(null);
  useEffect(() => {
    setSectionCache(sectionData);
  }, [sectionData, setSectionCache]);
  const getEditorContent = () => {
    if (editorRef.current) {
      return editorRef.current.getContent({ format: "html" });
    } else {
      return "";
    }
  };
  const changeTemplateHandler = (templateId: string) => {
    props.setIsChanged(true);
    setSectionCache({ ...sectionCache, template: templateId });
  };
  return (
    <>
      <MDBAccordionItem
        className="section"
        collapseId={props.sectionIndex + 1}
        headerTitle={`${sectionCache.name}`}
      >
        <MDBRow>
          <MDBCol xl="6" lg="12">
            <MDBRow className="mb-2">
              <MDBCard border="#e3e3e3">
                <MDBCardBody>
                  <MDBRow>
                    <MDBCol xl="6" lg="12">
                      <MDBInput
                        label="Sektionsnavn"
                        id="form1"
                        type="text"
                        value={sectionCache.name}
                        onChange={(e) => {
                          props.setIsChanged(true);
                          setSectionCache({
                            ...sectionCache,
                            name: e.target.value,
                          });
                        }}
                      />
                    </MDBCol>
                    <MDBCol xl="6" lg="12">
                      <MDBSelect
                        label="Sektionstype"
                        onValueChange={(e: any) => {
                          props.setIsChanged(true);
                          setSectionCache({
                            ...sectionCache,
                            entity: e.value,
                            template: undefined,
                            criterias: [],
                          });
                        }}
                        data={[
                          {
                            text: "Firma",
                            defaultSelected:
                              sectionCache.entity === ESectionEntity.Contact,
                            value: ESectionEntity.Contact,
                          },
                          {
                            text: "Personer",
                            defaultSelected:
                              sectionCache.entity === ESectionEntity.Person,
                            value: ESectionEntity.Person,
                          },
                          {
                            text: "Projekter",
                            defaultSelected:
                              sectionCache.entity === ESectionEntity.Project,
                            value: ESectionEntity.Project,
                          },
                          {
                            text: "Salg",
                            defaultSelected:
                              sectionCache.entity === ESectionEntity.Sale,
                            value: ESectionEntity.Sale,
                          },
                          {
                            text: "Dokumenter",
                            defaultSelected:
                              sectionCache.entity === ESectionEntity.Document,
                            value: ESectionEntity.Document,
                          },
                          {
                            text: "Aktiviteter",
                            defaultSelected:
                              sectionCache.entity ===
                              ESectionEntity.Appointment,
                            value: ESectionEntity.Appointment,
                          },
                        ]}
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </MDBRow>
            <MDBRow className="mb-4">
              <MDBCard border="#e3e3e3">
                <MDBCardBody>
                  <Criteria
                    sectionType={sectionCache.entity}
                    criteriaGroups={sectionCache.criterias}
                    setCriteriaGroups={(
                      changedCriterias: ISectionCriteriaGroup[]
                    ) => {
                      props.setIsChanged(true);
                      setSectionCache({
                        ...sectionCache,
                        criterias: changedCriterias,
                      });
                    }}
                  />
                </MDBCardBody>
              </MDBCard>
            </MDBRow>

            <MDBRow className="mb-4">
              <MDBCol size={12}>
                <div className="d-grid">
                  <MDBSelect
                    label="Skabelon"
                    onValueChange={(e: any) => changeTemplateHandler(e.value)}
                    data={[
                      { text: "Vælg en template", value: undefined },
                      ...templateData
                        .filter((e) => {
                          return (
                            e.type === ETemplateType.Section &&
                            e.entity === sectionCache.entity
                          );
                        })
                        .map((element) => {
                          return {
                            text: element.name,
                            value: element._id,
                            defaultSelected:
                              element._id === sectionCache.template,
                          };
                        }),
                    ]}
                  />
                </div>
              </MDBCol>
            </MDBRow>
          </MDBCol>
          {/* Wysiwyg her */}
          <MDBCol xl="6" lg="12">
            <MDBRow className="mb-2">
              <MDBCol>
                <WysiwygEditor
                  defaultVal={sectionCache.text}
                  editorRef={editorRef}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <div className="d-flex flex-row-reverse">
                  <div className="pt-2 pb-2 ps-2">
                    <MDBBtn
                      onClick={(e) => {
                        e.preventDefault();
                        props.deleteSection();
                      }}
                      outline
                      color="danger"
                    >
                      <MDBIcon far icon="trash-alt" />
                      &nbsp;Slet
                    </MDBBtn>
                  </div>
                  <div className="pt-2 pb-2 ps-2">
                    <MDBBtn
                      onClick={(e) => {
                        e.preventDefault();
                        props.setSection({
                          ...sectionCache,
                          text: getEditorContent(),
                        });
                      }}
                      outline
                      color="primary"
                    >
                    <MDBIcon far icon="save" />
                      &nbsp;Gem
                    </MDBBtn>
                  </div>
                  <div className="p-2 pt-3">
                    <MDBSwitch
                      defaultChecked={sectionCache.active}
                      label="Aktiver sektion"
                      onChange={(e) => {
                        props.setIsChanged(true);
                        setSectionCache({
                          ...sectionCache,
                          active: !sectionCache.active,
                        });
                      }}
                    />
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBAccordionItem>
    </>
  );
}
