import { IndividualUsers } from "./IndividualUsers";
import { Groups } from "./Groups";
import React, { useState } from "react";
import { ITargetGroup, ETargetGroupType } from "doweb-alfred-backend-client";

import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBBtn,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBRadio,
} from "mdb-react-ui-kit";
import { ITargetGroupUserMember } from "doweb-alfred-backend-client";
import { ITargetGroupGroupMember } from "doweb-alfred-backend-client";
interface Props {
  modal: boolean;
  setModal: Function;
  targetGroup: ITargetGroup;
  setTargetGroup: Function;
}
export const EditModal = (props: Props) => {
  const [targetGroupCache, setTargetGroupCache] = useState(props.targetGroup);
  return (
    <MDBModal show={props.modal} setShow={(e: any) => props.setModal(e)}>
      <MDBModalDialog centered size="xl">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Rediger Målgruppe</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => props.setModal(!props.modal)}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              <MDBCol size={4}>
                <MDBRadio
                  onChange={(e) =>
                    setTargetGroupCache({
                      ...targetGroupCache,
                      type: ETargetGroupType.User,
                    })
                  }
                  name="targetGroup"
                  label="Individuelle brugere"
                  defaultChecked={
                    targetGroupCache.type === ETargetGroupType.User
                  }
                />

                <MDBRadio
                  onChange={(e) =>
                    setTargetGroupCache({
                      ...targetGroupCache,
                      type: ETargetGroupType.Group,
                    })
                  }
                  name="targetGroup"
                  label="Medlemmer af gruppe"
                  defaultChecked={
                    targetGroupCache.type === ETargetGroupType.Group
                  }
                />
              </MDBCol>
              <MDBCol size={8}>
                {targetGroupCache.type === ETargetGroupType.User && (
                  <IndividualUsers
                    members={targetGroupCache.associateMembers}
                    setMembers={(newMembers: ITargetGroupUserMember[]) => {
                      setTargetGroupCache({
                        ...targetGroupCache,
                        associateMembers: newMembers,
                      });
                    }}
                  />
                )}
                {targetGroupCache.type === ETargetGroupType.Group && (
                  <Groups
                    members={targetGroupCache.groupMembers}
                    setMembers={(newMembers: ITargetGroupGroupMember[]) => {
                      setTargetGroupCache({
                        ...targetGroupCache,
                        groupMembers: newMembers,
                      });
                    }}
                  />
                )}
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="secondary"
              onClick={() => {
                setTargetGroupCache(props.targetGroup);
                props.setModal(!props.modal);
              }}
            >
              Annuller
            </MDBBtn>
            <MDBBtn
              onClick={(e) => {
                props.setModal(!props.modal);
                props.setTargetGroup(targetGroupCache);
              }}
            >
              Gem
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};
