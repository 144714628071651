import React from "react";
import { useRef, useEffect } from "react";
import danishTranslation from "../../../assets/config/datepickerTranslation.json";
import {
  MDBCol,
  MDBRow,
  MDBSelect,
  MDBInput,
  MDBTimepicker,
  MDBDatepicker,
} from "mdb-react-ui-kit";
import {
  EPlanningMonthNames,
  EPlanningWeekDays,
  EPlanningYearPattern,
  IJobPlanning,
  IPlanningYearPattern,
} from "doweb-alfred-backend-client";
import moment from "moment";

interface Props {
  data: IJobPlanning;
  setData: Function;
}

let uiTimeHour = 0;
let uiTimeMinutes = 0;
let uiDate = 0;
let uiMonth = 0;
let uiYear = 0;

export function Yearly(props: Props) {
  const freqRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (freqRef.current) {
      freqRef.current.value = props.data.cycle.toString();
    }
  }, [freqRef, props.data.cycle]);

  //segment to deal with the weird bootstrap behaviour related to date
  ////////////////////////////////////////////////////////////////
  useEffect(() => {
    //set initial value of the time-of-day widget. Only runs once.
    //const day = new Date(props.data.startDate);
    uiTimeHour = parseInt(moment(props.data.startDate).format("HH"));
    uiTimeMinutes = parseInt(moment(props.data.startDate).format("mm"));
    uiDate = parseInt(moment(props.data.startDate).format("DD"));
    uiMonth = parseInt(moment(props.data.startDate).format("MM"));
    uiYear = parseInt(moment(props.data.startDate).format("YYYY"));
  });
  //Function that handles the conversion of time to two different variables containing minutes and hours, and updates the prop with the new value
  const changeTimedayHandler = (time: string) => {
    const changedTime = time.split(":");
    const changedHour = parseInt(changedTime[0]);
    const changedMinutes = parseInt(changedTime[1]);
    if (uiTimeHour !== changedHour || uiTimeMinutes !== changedMinutes) {
      uiTimeHour = changedHour;
      uiTimeMinutes = changedMinutes;
      let newStartDate = moment(props.data.startDate)
        .set("hour", changedHour)
        .set("minute", changedMinutes); //moment(props.data.startDate);

      props.setData({
        ...props.data,
        startDate: newStartDate.toISOString(),
      });
    }
  };
  const changeDateHandler = (element: string) => {
    const tempDay = parseInt(moment(element, "DD/MM/YYYY").format("DD"));
    const tempMonth = parseInt(moment(element, "DD/MM/YYYY").format("MM")) - 1; ///////////THIS SHOULD NEVER BE NECESSARY
    const tempYear = parseInt(moment(element, "DD/MM/YYYY").format("YYYY"));
    if (uiDate !== tempDay || uiMonth !== tempMonth || uiYear !== tempYear) {
      uiDate = tempDay;
      uiMonth = tempMonth;
      uiYear = tempYear;
      let tempDate = moment(props.data.startDate);
      tempDate.set("date", tempDay);
      tempDate.set("month", tempMonth);
      tempDate.set("year", tempYear);
      props.setData({
        ...props.data,
        startDate: tempDate.toISOString(),
      });
    }
  };
  ////////////////////////////////////////////////////////////////

  /*
  const changeTimedayHandler = (time: String) => {
    const changedTime = time.split(":");
    let changedHours = parseInt(changedTime[0]);
    let changedMinutes = parseInt(changedTime[1]);
      props.setData({
        ...props.data,
        minutes: changedMinutes,
        hours: changedHours,
      });
    /*
    
      yearPattern?: {
    type: EPlanningYearPattern;
    weekDay: EPlanningWeekDays;
    monthName: EPlanningMonthNames;
  };
  };
  */
  const changeDayFreqHandler = (type: EPlanningYearPattern) => {
    const updatedData: IPlanningYearPattern = {
      ...props.data.yearPattern,
      type: type,
    };
    props.setData({ ...props.data, yearPattern: updatedData });
  };
  const changeWeekDayFreqHandler = (weekday: EPlanningWeekDays) => {
    const updatedData: IPlanningYearPattern = {
      ...props.data.yearPattern,
      weekDay: weekday,
    };
    props.setData({ ...props.data, yearPattern: updatedData });
  };
  const changeMonthFreqHandler = (month: EPlanningMonthNames) => {
    const updatedData: IPlanningYearPattern = {
      ...props.data.yearPattern,
      monthName: month,
    };
    props.setData({ ...props.data, yearPattern: updatedData });
  };

  return (
    <>
      <MDBRow>
        <MDBCol>
          <MDBRow>
            Frekvens hver:&nbsp;
            <span className="frequency-input">
              <MDBInput
                defaultValue={props.data.cycle.toString()}
                ref={freqRef}
                onChange={(e) =>
                  props.setData({
                    ...props.data,
                    cycle: parseInt(e.target.value),
                  })
                }
                min="1"
                max="52"
                type="number"
              />
            </span>
            &nbsp;år, klokken&nbsp;
            <span className="time-input">
              <MDBTimepicker
                inputID="yearTimePicker"
                defaultValue={moment(props.data.startDate).format("HH:mm")}
                inputLabel="Tidspunkt"
                onClose={() => {
                  // needs onClose instead of onChange because bootstrap messed up; new version should fix it
                  const element = document.querySelector("#yearTimePicker");
                  const value = element!.getAttribute("value");
                  changeTimedayHandler(value!);
                }}
                onBlur={() => {
                  // needs onClose instead of onChange because bootstrap messed up; new version should fix it
                  const element = document.querySelector("yearTimePicker");
                  const value = element!.getAttribute("value");
                  changeTimedayHandler(value!);
                }}
                inline
                format="24h"
              />
            </span>
          </MDBRow>
          <MDBRow className="mt-3">
            Hver D.&nbsp;
            <span style={{ width: "100px" }} className="frequency-input">
              <MDBSelect
                onValueChange={(e: any) => changeDayFreqHandler(e.value)}
                data={[
                  {
                    text: "første",
                    value: EPlanningYearPattern._1,
                    defaultSelected:
                      props.data.yearPattern.type ===
                      EPlanningYearPattern._1,
                  },
                  {
                    text: "anden",
                    value: EPlanningYearPattern._2,
                    defaultSelected:
                      props.data.yearPattern.type ===
                      EPlanningYearPattern._2,
                  },
                  {
                    text: "tredje",
                    value: EPlanningYearPattern._3,
                    defaultSelected:
                      props.data.yearPattern.type ===
                      EPlanningYearPattern._3,
                  },
                  {
                    text: "fjerde",
                    value: EPlanningYearPattern._4,
                    defaultSelected:
                      props.data.yearPattern.type ===
                      EPlanningYearPattern._4,
                  },
                ]}
              />
            </span>
            <span style={{ width: "100px" }} className="frequency-input">
              <MDBSelect
                onValueChange={(e: any) => changeWeekDayFreqHandler(e.value)}
                data={[
                  {
                    text: "Mandag",
                    value: EPlanningWeekDays.Mon,
                    defaultSelected:
                      props.data.yearPattern.weekDay === EPlanningWeekDays.Mon,
                  },
                  {
                    text: "Tirsdag",
                    value: EPlanningWeekDays.Tue,
                    defaultSelected:
                      props.data.yearPattern.weekDay === EPlanningWeekDays.Tue,
                  },
                  {
                    text: "Onsdag",
                    value: EPlanningWeekDays.Wed,
                    defaultSelected:
                      props.data.yearPattern.weekDay === EPlanningWeekDays.Wed,
                  },
                  {
                    text: "Torsdag",
                    value: EPlanningWeekDays.Thu,
                    defaultSelected:
                      props.data.yearPattern.weekDay === EPlanningWeekDays.Thu,
                  },
                  {
                    text: "Fredag",
                    value: EPlanningWeekDays.Fri,
                    defaultSelected:
                      props.data.yearPattern.weekDay === EPlanningWeekDays.Fri,
                  },
                  {
                    text: "Lørdag",
                    value: EPlanningWeekDays.Sat,
                    defaultSelected:
                      props.data.yearPattern.weekDay === EPlanningWeekDays.Sat,
                  },
                  {
                    text: "Søndag",
                    value: EPlanningWeekDays.Sun,
                    defaultSelected:
                      props.data.yearPattern.weekDay === EPlanningWeekDays.Sun,
                  },
                ]}
              />
            </span>
            &nbsp;i&nbsp;
            <span style={{ width: "100px" }} className="frequency-input">
              <MDBSelect
                onValueChange={(e: any) => changeMonthFreqHandler(e.value)}
                data={[
                  {
                    text: "Januar",
                    value: EPlanningMonthNames.Jan,
                    defaultSelected:
                      props.data.yearPattern.monthName ===
                      EPlanningMonthNames.Jan,
                  },
                  {
                    text: "Februar",
                    value: EPlanningMonthNames.Feb,
                    defaultSelected:
                      props.data.yearPattern.monthName ===
                      EPlanningMonthNames.Feb,
                  },
                  {
                    text: "Marts",
                    value: EPlanningMonthNames.Mar,
                    defaultSelected:
                      props.data.yearPattern.monthName ===
                      EPlanningMonthNames.Mar,
                  },
                  {
                    text: "April",
                    value: EPlanningMonthNames.Apr,
                    defaultSelected:
                      props.data.yearPattern.monthName ===
                      EPlanningMonthNames.Apr,
                  },
                  {
                    text: "Maj",
                    value: EPlanningMonthNames.May,
                    defaultSelected:
                      props.data.yearPattern.monthName ===
                      EPlanningMonthNames.May,
                  },
                  {
                    text: "Juni",
                    value: EPlanningMonthNames.Jun,
                    defaultSelected:
                      props.data.yearPattern.monthName ===
                      EPlanningMonthNames.Jun,
                  },
                  {
                    text: "Juli",
                    value: EPlanningMonthNames.Jul,
                    defaultSelected:
                      props.data.yearPattern.monthName ===
                      EPlanningMonthNames.Jul,
                  },
                  {
                    text: "August",
                    value: EPlanningMonthNames.Aug,
                    defaultSelected:
                      props.data.yearPattern.monthName ===
                      EPlanningMonthNames.Aug,
                  },
                  {
                    text: "September",
                    value: EPlanningMonthNames.Sep,
                    defaultSelected:
                      props.data.yearPattern.monthName ===
                      EPlanningMonthNames.Sep,
                  },
                  {
                    text: "Oktober",
                    value: EPlanningMonthNames.Oct,
                    defaultSelected:
                      props.data.yearPattern.monthName ===
                      EPlanningMonthNames.Oct,
                  },
                  {
                    text: "November",
                    value: EPlanningMonthNames.Nov,
                    defaultSelected:
                      props.data.yearPattern.monthName ===
                      EPlanningMonthNames.Nov,
                  },
                  {
                    text: "December",
                    value: EPlanningMonthNames.Dec,
                    defaultSelected:
                      props.data.yearPattern.monthName ===
                      EPlanningMonthNames.Dec,
                  },
                ]}
              />
            </span>
          </MDBRow>
          <MDBRow className="mt-3">
            med start d. &nbsp;
            <span className="time-input">
              <MDBDatepicker
                inputLabel="Startdato"
                {...danishTranslation}
                value={moment(props.data.startDate).format("DD/MM/YYYY")}
                onChange={(value: string) => {
                  changeDateHandler(value);
                }}
              />
            </span>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </>
  );
}
