import { RightJobDisplay } from "./Fragments/RightJobDisplay";
import LeftJobList from "./Fragments/LeftJobList";
import { MDBBtn, MDBCol, MDBRow, MDBIcon } from "mdb-react-ui-kit";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import WizardWait from "../../components/Wizard"; 
import { createDefaultJob } from "../../store/features/JobSlice";
import { useAuth0 } from "@auth0/auth0-react";
import {  getCurrentUser, getUserStatus } from "../../store/features/UserSlice";
 
import { EAsyncLoadStatus } from "../../assets/types/General.d";
const Jobs = () => {
  const { isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();

  const jobData = useSelector((state : RootState) => state.job);
  const dispatch = useDispatch<AppDispatch>();
  
  const currentUser = useSelector(getCurrentUser);
  const userStatus = useSelector(getUserStatus);
 
  return (
    <>
      {jobData.collection.length > 0 && isAuthenticated && (
        <>
          <MDBRow>
            <MDBCol size="12" className=" ps-3 pt-1 pb-2 text-start">
              <h3>Jobs</h3>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="5" sm="12" lg="4" xl="3">
              <LeftJobList />
            </MDBCol>
            <MDBCol sm="12" md="7" lg="8" xl="9">
              {jobData.openJob !== "" && (
                <RightJobDisplay />
              )}
            </MDBCol>
          </MDBRow>
        </>
      )}
      {jobData.collection.length === 0 && (
        <>
          <MDBRow className="pt-5">
            <WizardWait />
            <h1 style={{ fontFamily: "lobster" }} className=" text-center">
              Velkommen til Alfred{userStatus === EAsyncLoadStatus.success && <>, {String(currentUser?.name)}</>}!
            </h1>
            {isAuthenticated && (
              <h3 className="text-center">Start med at lave dit første job!</h3>
            )}
          </MDBRow>
          <MDBRow className="mt-1 pb-5">
            <MDBCol size={12} className="text-center">
              <MDBBtn
                onClick={(e) => {
                  e.preventDefault();
                  if (isAuthenticated)
                  getAccessTokenSilently().then((token) =>
                    dispatch(
                      createDefaultJob({accesstoken: token,
                        key: jobData.collection.length
                      })
                    ))
                  else loginWithRedirect({redirectUri: window.location.href, connection: 'SuperOffice-Vaeksthuset'});
                }}
                color="success"
                size="lg"
              >
                {isAuthenticated && (
                  <>
                    <MDBIcon fas icon="magic" /> &nbsp;Yes, opret et nyt job!
                  </>
                )}
                {!isAuthenticated && (
                  <>
                    <MDBIcon fas icon="sign-in-alt" /> &nbsp;Log ind her!
                  </>
                )}
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </>
      )}
    </>
  );
};
export default Jobs;
