import { ISectionCriteriaValueInner } from "doweb-alfred-backend-client";
import { MDBDatepicker, MDBInput } from "mdb-react-ui-kit";
import danishTranslation from "../../../../../assets/config/datepickerTranslation.json";
import moment from "moment";
import React, { useEffect } from "react";
import useState from "react-usestateref";
/*
Prop.datatype: ’int’ | 'dec' | ’date’
Prop.value: (værdi af enten ’int’ | ’date’)
Prop.setValue: function<array>
*/
//value has datatype object for now, but should be typed later using a subtype hierarchy
interface Props {
  datatype: "int" | "decimal" | "date";
  value: ISectionCriteriaValueInner[];
  setValue: Function;
}
export function CriteriaValueBetween(props: Props) {
  //Create useState initial render
  const [initRender1, setInitRender1] = useState(false);
  const [initRender2, setInitRender2] = useState(false);
  //Create a useState to manage the values
  const [theValue1, setTheValue1, theValueRef1] = useState(
    props.value.length === 2
      ? { ...props.value[0] }
      : { label: "", value: "" }
  );
  const [theValue2, setTheValue2, theValueRef2] = useState(
    props.value.length === 2
      ? { ...props.value[1] }
      : { label: "", value: "" }
  );
  //Create a useEffect to update the value when the values change

  useEffect(() => {
    setTheValue1(
      props.value.length === 2
        ? { ...props.value[0] }
        : { label: "", value: "" }
    );
    setTheValue2(
      props.value.length === 2
        ? { ...props.value[1] }
        : { label: "", value: "" }
    );
  }, [props.value, setTheValue1, setTheValue2]);
  return (
    <>
      {/* If the datatype is date, we need to use the datepicker */}
      {props.datatype === "date" && (
        <span className="time-input">
          <MDBDatepicker
            inputLabel="Startdato"
            {...danishTranslation}
            value={
              theValueRef1.current.value !== ""
                ? moment(new Date(theValue1.value)).format(
                    "DD/MM/YYYY"
                  )
                : undefined
            }
            onChange={(value: string, date: Date) => {
              if (initRender1) {
                props.setValue([
                  {
                    value: date.toISOString(),
                    label: moment(date).format("DD/MM/YYYY"),
                  },
                  {
                    value: theValueRef2.current.value,
                    label: theValueRef2.current.label,
                  },
                ]);
              }
              else{
                setInitRender1(true);
              }
            }}
          />
          <span className="d-block p-2"></span>
          <MDBDatepicker
            inputLabel="Slutdato"
            {...danishTranslation}
            value={
              theValueRef2.current.value !== ""
                ? moment(new Date(theValue2.value)).format(
                    "DD/MM/YYYY"
                  )
                : undefined
            }
            onChange={(value: string, date: Date) => {
              if (initRender2) {
                props.setValue([
                  {
                    value: theValueRef1.current.value,
                    label: theValueRef1.current.label,
                  },
                  {
                    value: date.toISOString(),
                    label: moment(date).format("DD/MM/YYYY"),
                  },
                ]);
              }
              else{
                setInitRender2(true);
              }
            }}
          />
        </span>
      )}
      {/* If the datatype is int or dec, we need to use the input */}
      {(props.datatype === "int" || props.datatype === "decimal") && (
        <>
          <MDBInput
            onBlur={(e) => {
              props.setValue([
                {
                  value: e.target.value,
                  label: e.target.value,
                },
                {
                  value: theValueRef2.current.value,
                  label: theValueRef2.current.label,
                }
                
              ]);
            }}
            defaultValue={theValueRef1.current.value}
            label="Fra"
            type={"number"}
          />
          <span className="d-block p-2"></span>
          <MDBInput
            onBlur={(e) => {
              props.setValue([
                {
                  value: theValueRef1.current.value,
                  label: theValueRef1.current.label,
                },
                {
                  value: e.target.value,
                  label: e.target.value,
                },
              ]);
            }}
            defaultValue={theValueRef2.current.value}
            label="Til"
            type={"number"}
          />
        </>
      )}
    </>
  );
}
