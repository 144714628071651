import React, { useRef } from "react";
import {
  MDBCol,
  MDBRow,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBAutocomplete,
  MDBChip,
  MDBIcon,
  MDBBtn,
} from "mdb-react-ui-kit";
import {
  ITargetGroupUserMember,
  ISuOAssociate,
} from "doweb-alfred-backend-client";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
interface Props {
  members: ITargetGroupUserMember[];
  setMembers: Function;
}
export function IndividualUsers(props: Props) {
  //useRef for autocomplete value
  const acRef = useRef<HTMLInputElement | null>(null);
  
  const associates = useSelector((state: RootState) => state.list.associates); 
  const [acData, setAcData] = React.useState<ISuOAssociate[]>(
    Object.values(associates.list).filter((associate) => {
      //Filter those groups that is already selected
      const index = props.members.findIndex(
        (member) => member.associateId === associate.associateId
      );
      return index === -1;
    })
  );

  
  const onAssociateSearch = (value: string) => {
    
    if (value.length > 1) {
      setAcData(
        Object.values(associates.list)
          .filter((associate) => {
            //Filter those associates that is already selected
            const index = props.members.findIndex(
              (member) => member.associateId === associate.associateId
            );
            return index === -1;
          })
          .filter((item: ISuOAssociate) =>
            item.name.toLowerCase().includes(value.toLowerCase())
          )
      );
    } else {
      setAcData(Object.values(associates.list)
      .filter((associate) => {
        //Filter those associates that is already selected
        const index = props.members.findIndex(
          (member) => member.associateId === associate.associateId
        );
        return index === -1;
      }));
    }
  };

  const onSelectUser = (value: any) => {
    //Cast value as ISuOAssociate
    const selectedUser: ISuOAssociate = value as ISuOAssociate;
  
    if (selectedUser) {
      if (acRef.current) {
        acRef.current.value = selectedUser.name;
      }

      const userMember: ITargetGroupUserMember = {
        ...selectedUser
      };

      const new_members: ITargetGroupUserMember[] = [...props.members];
      const index = new_members.findIndex(
        (member) => member.associateId === userMember.associateId
      );

      if (index === -1) {
        new_members.push(userMember);
        props.setMembers(new_members);
      }
    }
  };
  return (
    <>
      <MDBRow className="mb-2">
        <MDBCol size={6}>
          <strong>Valgte brugere:</strong>
        </MDBCol>
        <MDBCol size={6}>
          
        <MDBAutocomplete
            label="Find en bruger..."
            data={acData as any}
            ref={acRef}
            onSearch={onAssociateSearch}
            onSelect={onSelectUser}
            itemContent={(result: Record<string, any>) => {
              const associate = result as ISuOAssociate;
              return (
                <div className="autocomplete-custom-item-content">
                  <div className="autocomplete-custom-item-title">
                    {associate.name}
                  </div>
                  <div className="autocomplete-custom-item-subtitle">
                    {associate.group.length === 1 && associate.group[0]}
                    {associate.group.length > 1 && (
                      <>
                        {associate.group[0]}, og {associate.group.length - 1} andre
                        grupper..
                      </>
                    )}
                  </div>
                </div>
              );
            }}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <div className="tableWrap">
            <MDBTable small striped>
              <MDBTableHead>
                <tr>
                  <th scope="col">Navn</th>
                  <th scope="col">Email</th>
                  <th scope="col">Gruppe</th>
                  <th scope="col"></th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {props.members.length === 0 && (
                  <tr>
                    <td colSpan={4}>Ingen brugere valgt</td>
                  </tr>
                )}
                {props.members.map(
                  (member: ITargetGroupUserMember, index: number) => {
                    const this_associate: ISuOAssociate[] = Object.values(
                      associates.list
                    ).filter(
                      (associate: ISuOAssociate) =>
                        associate.associateId === member.associateId
                    );

                    if (typeof this_associate[0] !== "undefined") {
                      return (
                        <tr key={index}>
                          <td>{this_associate[0].name}</td>
                          <td>{this_associate[0].email}</td>
                          <td>
                            <div style={{ display: "flex" }}>
                              {this_associate[0].group.map((group: string, chipIndex: number) => (
                                <MDBChip key={chipIndex} size="sm">{group}</MDBChip>
                              ))}
                            </div>
                          </td>
                          <td>
                            <MDBBtn
                              tag="a"
                              color="none"
                              className="m-1"
                              style={{ color: "#3b5998" }}
                              onClick={(e) => {
                                let new_members: ITargetGroupUserMember[] = [
                                  ...props.members,
                                ];
                                new_members.splice(index, 1);
                                props.setMembers(new_members);
                              }}
                            >
                              <MDBIcon color="danger" fas icon="trash-alt" />
                            </MDBBtn>
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr className="table-danger" key={index}>
                          <td>{member.name}</td>
                          <td>{member.email}</td>
                          <td>- (Brugeren er fratrådt)</td>
                          <td>
                            <MDBBtn
                              tag="a"
                              color="none"
                              className="m-1"
                              style={{ color: "#3b5998" }}
                              onClick={(e) => {
                                const new_members: ITargetGroupUserMember[] = [
                                  ...props.members,
                                ];
                                new_members.splice(index, 1);
                                props.setMembers(new_members);
                              }}
                            >
                              <MDBIcon color="danger" fas icon="trash-alt" />
                            </MDBBtn>
                          </td>
                        </tr>
                      );
                    }
                  }
                )}
              </MDBTableBody>
            </MDBTable>
          </div>
        </MDBCol>
      </MDBRow>
    </>
  );
}
