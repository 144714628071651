import React, { useEffect } from "react";
import useState from "react-usestateref";
import { useParams, useSearchParams } from "react-router-dom";
import { EAsyncLoadStatus } from "../assets/types/General.d";
import { LoadingScreen, statusJobItem } from "./LoadingScreen";
import {
  Configuration,
  ELinkExpirationStatus,
  ILinkStatus,
  LinksApi,
} from "doweb-alfred-backend-client";
//React component named link, to render a link
const Link = () => {
  //get the link id from the URL path, using the react router (linkId)
  const linkId: string = useParams().linkId as string;
  //get the email from the query string (email)
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  //Create a state variable to store the link data
  const [link, setLink] = useState<ILinkStatus | null>(null);
  //state to keep track on which id's are currently executing to prevent duplicate requests
  const [processingLinks, setProcessingLinks] = useState<string[]>([]);
  // isLoading state
  const [isLoading, setIsLoading] = useState(true);
  const [headerTitle, setHeadertitle] = useState<string>("Alfred klarer skærene!");
  //Create a state to keep the jobs
  const [jobs, setJobs, jobRef] = useState<statusJobItem[]>([
    {
      name: "Henter link information...",
      status: EAsyncLoadStatus.loading,
      error: "",
    },
  ]);

  //Create a useEffect hook to fetch the link data
  useEffect(() => {
    if (link === null && isLoading) {
      const linkApi = new LinksApi(
        new Configuration({
          basePath: process.env.REACT_APP_BACKEND_BASE_URL,
        })
      );

      //Fetch the link data from the API
      linkApi
        .linkLinkIdGet(linkId, email as string)
        .then(
          //If the fetch is successful, set the link data to the state variable
          (resp) => {
            if (resp.data.status !== ELinkExpirationStatus.Expired) {
              const jobObject: statusJobItem[] = [
                {
                  name: "Information om link hentet...",
                  status: EAsyncLoadStatus.success,
                  error: "",
                },
              ];
              if (resp.data.linkCollection.length === 0) {
                jobObject.push({
                  id: resp.data._id,
                  name: resp.data.name,
                  status: EAsyncLoadStatus.loading,
                  error: "",
                });
              }
              setJobs([
                ...jobObject,
                ...resp.data.linkCollection.map((statusJobItem) => ({
                  id: statusJobItem._id,
                  name: statusJobItem.name,
                  status: EAsyncLoadStatus.loading,
                  error: "",
                })),
              ]);
              setLink(resp.data);
            } else {
              setHeadertitle("Alfred kunne ikke klare skærene! :-(");
              setJobs([
                {
                  name: "Linket kunne ikke hentes...",
                  status: EAsyncLoadStatus.error,
                  error: "Linket er desværre udløbet",
                },
              ]);
            }
          }
        )
        .catch(
          //If the fetch fails, log the error
          (error) => {
            setHeadertitle("Alfred kunne ikke klare skærene! :-(");
            console.log(error);
            if (error.response.status === 500) {
              setJobs([
                {
                  name: "Linket kunne ikke hentes...",
                  status: EAsyncLoadStatus.error,
                  error: "Du har angivet et forkert link",
                },
              ]);
            }
            if (error.response.status === 404) {
              setJobs([
                {
                  name: "Linket kunne ikke hentes...",
                  status: EAsyncLoadStatus.error,
                  error: "Linket findes ikke",
                },
              ]);
            }
            console.log(error.response);
          }
        );
      setIsLoading(false);
    }
  }, [link, setLink, linkId, email, setJobs, jobs, setIsLoading, isLoading]);

  const jobsToExecute = jobRef.current.filter(
    (job: statusJobItem) =>
      job.id !== undefined && job.status === EAsyncLoadStatus.loading
  );
  const finishedJobs = jobRef.current.filter(
    (job: statusJobItem) => job.id !== undefined
  );
  if (finishedJobs.length === 1) {
    //When there is only one finished job, redirect automatically to the link, if the link is set
    if (
      finishedJobs[0].link !== undefined &&
      finishedJobs[0].status === EAsyncLoadStatus.success
    ) {
      window.location.href = finishedJobs[0].link as string;
    }
  }
  //Create a useEffect hook to fetch the link data when linksWaiting is not empty
  useEffect(() => {
    if (
      !isLoading &&
      processingLinks.length === 0 &&
      jobsToExecute.length > 0 &&
      link !== null
    ) {
      jobsToExecute.forEach(async (job) => {
        if (processingLinks.findIndex((id) => id === job.id) === -1) {
          const newProcesing: string[] = [...processingLinks];
          newProcesing.push(job.id as string);
          setProcessingLinks(newProcesing);
          const linkApi = new LinksApi(
            new Configuration({
              basePath: process.env.REACT_APP_BACKEND_BASE_URL,
            })
          );
          if (link.sequential) {
            //Run sequentially
            try {
              const resp: any = await linkApi.linkLinkIdPost(
                job.id as string,
                email as string
              );
              const newJobs = [...jobRef.current];
              const job_index = jobRef.current.findIndex(
                (jobEntry) => jobEntry.id === job.id
              );
              newJobs[job_index] = {
                ...newJobs[job_index],
                status: EAsyncLoadStatus.success,
                error: resp.data.message,
                link: resp.data.redirectLink,
              };
              setJobs(newJobs);
            } catch (error: any) {
              setHeadertitle("Alfred kunne ikke klare skærene! :-(");
              console.log(error.response);

              const newJobs = [...jobRef.current];
              const job_index = jobRef.current.findIndex(
                (jobEntry) => jobEntry.id === job.id
              );
              newJobs[job_index] = {
                ...newJobs[job_index],
                status: EAsyncLoadStatus.error,
                error: error.response.data.msg,
              };
              setJobs(newJobs);
            }
          } else {
            linkApi
              .linkLinkIdPost(job.id as string, email as string)
              .then((resp: any) => {
                const newJobs = [...jobRef.current];
                const job_index = jobRef.current.findIndex(
                  (jobEntry) => jobEntry.id === job.id
                );
                newJobs[job_index] = {
                  ...newJobs[job_index],
                  status: EAsyncLoadStatus.success,
                  error: resp.data.message,
                  link: resp.data.redirectLink,
                };
                setJobs(newJobs);
              })
              .catch((error) => {
                setHeadertitle("Alfred kunne ikke klare skærene! :-(");
                console.log(error.response);

                const newJobs = [...jobRef.current];
                const job_index = jobRef.current.findIndex(
                  (jobEntry) => jobEntry.id === job.id
                );
                newJobs[job_index] = {
                  ...jobs[job_index],
                  status: EAsyncLoadStatus.error,
                  error: error.response.data.msg,
                };
                setJobs(newJobs);
              });
          }
        }
      });
    }
  }, [processingLinks, jobsToExecute, isLoading, jobs, email, setJobs, jobRef, link]);
  const title =
    jobs[0].status === EAsyncLoadStatus.success
      ? `Hej ${link?.ReceiverName}, ${headerTitle}`
      : "Vent et øjeblik...";

  return (
    <LoadingScreen
      showErrorOnSuccess={true}
      title={title}
      fadeIn={false}
      fadeOut={false}
      jobs={jobRef.current}
      rowButton={true}
      rowButtonText="Gå til link"
    />
  );
};

export default Link;
