import { MDBTreeview, MDBTreeviewItem } from "mdb-react-treeview";
import {
  MDBInput,
  MDBPopover,
  MDBPopoverBody, 
} from "mdb-react-ui-kit";
import React, { useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import "./TreeSelect.scoped.scss";

/*
This class implements the logic for using an MDBTreeView in an MDBPopover.
It is used by the CriteriaValueSelector component, as well as the ActionSelector component. 
It traverses a tree structure of objects, and renders a treeview of the objects.
It also provides a callback function that is called when an object is selected.
The popover is styled by custom CSS.
The popover has a scrollbar.
It uses state management to keep track of the selected object.
It instantiates the selected object when the popover is opened.
*/

//interface for the props of the component
interface Props {
  //the tree structure of objects
  tree: object;
  //the callback function that is called when an object is selected
  onSelect: Function;
  //label for the primary button intially
  label?: string;
  //whether to close the popover when an object is selected
  closeOnSelect?: boolean;

  popoverOpen: boolean;
  setPopoverOpen: Function;
 
}

//export jsx component
export const TreeSelect = (props: Props) => {
 
  //a default value for the label
  const label = props.label !== undefined ? props.label : "Vælg"; //TRANSLATION
  const closeOnSelect =
    props.closeOnSelect !== undefined ? props.closeOnSelect : false;

  const [fieldFilter, setFieldFilter] = useState("");
  const { popoverOpen, setPopoverOpen } = props;

/*

items={[
        { name: 'One', disabled: true },
        { name: 'Two' },
        {
          name: ' Three',
          show: true,
          children: [
            { name: 'Second-one' },
            { name: 'Second-two' },
            {
              name: ' Second-three',
              disabled: true,
              children: [
                {
                  name: ' Third-one',
                  children: [{ name: 'Fourth-one' }, { name: 'Fourth-two' }, { name: 'Fourth-three' }],
                },
                { name: 'Third-two' },
                {
                  name: ' Third-three',
                  children: [{ name: 'Fourth-one' }, { name: 'Fourth-two' }, { name: 'Fourth-three' }],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
}

*/
/*
  //recusrively crall through the tree structure to produce another tree structure
  const buildTree = (e: any, filterString:string): any => {
    let children: any[] = [];
    if ( e.children && e.children.length !==0) {
      for(let i = 0; i < e.children.length; i++) {
        if (e.children.children && e.children.children.length !==0) { //it's a node with kids, don't filter
          children.push(buildTree(e.children[i], filterString));
        } else {
          if (e.children[i].name.toLowerCase().includes(filterString.toLowerCase())) {
            children.push(buildTree(e.children[i], filterString));
          }
        }
      }
      return {
        name: e.Name,
        id: e.Id,
        data: e,
        children: children,
      };
    } else { //children length are 0
      return {
        name: e.Name,
        id: e.Id,
        data: e,
      };
    }
  }
*/
  /*data treatment for the treeview when sourcing an ISuOMDO list*/
  const inner = (e: any): any => {
    return {
      id: e.Id,
      label: e.Name,
      data: e,
      children: e.ChildItems ? outer(e.ChildItems) : [],
    };
  };
  const outer = (e: any): any => {
    let toReturn = [];
    for (let i = 0; i < e.length; i++) {
      toReturn.push(inner(e[i]));
    }
    return toReturn;
  };

  //data filtering:
  const data = outer(props.tree);
  const filteredData = [...data].filter(function f(o: any) {
    if (o.label.includes(fieldFilter) || fieldFilter === "") return true;

    if (o.children) {
      return (o.children = o.children.filter(f)).length;
    }
    return false;
  });


    //return a recursive tree structure of JSX MDBTreeItem components
    const RecursiveTreeOuter2 = (e: any[], show: boolean, index: number): any => {
      let toReturn = [];
      for(let i = 0; i < e.length; i++) {
        toReturn.push(RecursiveTreeInner2(e[i], show, index + i));
      }
      return toReturn;
    };
    const RecursiveTreeInner2 = (e: any, show: boolean, index: number): any => {
      const key = index;
      index++;
      return { 
          name:e.label,
          id:e.id,
          subtree:e.children.length !== 0,
          show:show,
          key:key,
          onClick:(el:any) => {
            if (e.data.ChildItems.length === 0) {
              props.onSelect(e, setPopoverOpen);
              if (closeOnSelect) setPopoverOpen(false);
              
            }
          },
          children: e.children.length !== 0 ? RecursiveTreeOuter2(e.children, show, index): [],
        }
    }; 

  //return a recursive tree structure of JSX MDBTreeItem components
  const RecursiveTreeOuter = (e: any[], show: boolean, index: number): any => {
    return e.length !== 0 ? (
      e.map((item, itemIndex) => {
        return RecursiveTreeInner(item, show, index + itemIndex); // is itemIndex + index the right way to do this? What if there are multiple levels of nesting?
      })
    ) : (
      <></>
    );
  };
  const RecursiveTreeInner = (e: any, show: boolean, index: number): any => {
    const key = index;
    index++;
    return (
      <MDBTreeviewItem
        text-nowrap="true"
        name={e.label}
        id={e.id}
        subtree={e.children.length !== 0}
        show={show}
        key={key}
        onClick={(el) => {
          if (e.data.ChildItems.length === 0) {
            props.onSelect(e, setPopoverOpen);
            if (closeOnSelect) setPopoverOpen(false);
            
          }
        }}
      >
        {e.children.length > 0 ? (
          RecursiveTreeOuter(e.children, show, index)
        ) : (
          <></>
        )}
      </MDBTreeviewItem>
    );
  }; 

  //the selected object
  return (
    <MDBPopover
      options={{modifiers:[{ name: 'preventOverflow', enabled: false }, { name: 'offset', options: {
       
      }}, {name:'update', enabled:true, phase:'beforeRead', 'fn':() => {}}]}}
      isOpen={popoverOpen}
      onShow={() => setPopoverOpen(true)}
      onHide={() => setFieldFilter("")}
      block
      outline
      color="dark"
      className="criteriaFieldSelect"
      btnChildren={label} //this should change automatically by having a callback function that is called when an object is selected
      placement="bottom"
    >
      <MDBPopoverBody
        container={'body'}
        name="body"
        className="Dropover"
        scrollable="true"
        scrolling="true"
        style={{ maxHeight: "250px" }}
      >
        <ClickAwayListener
          onClickAway={(event) => {
            setPopoverOpen(false);
          }}
        >
          <div>
            <MDBInput
              type="text"
              label="Søg..."
              onChange={(e) => setFieldFilter(e.target.value)}
            />
            <MDBTreeview className="Dropover">
              {RecursiveTreeOuter(filteredData, fieldFilter !== "", 0)}
            </MDBTreeview>
          </div>
        </ClickAwayListener>
      </MDBPopoverBody>
    </MDBPopover>
  );
};
//