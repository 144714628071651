"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * DoWEB Alfred with Swagger
 * Alfred is a scheduling tool that lets you send scheduled emails with embedded actions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dav@doweb.dk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateApi = exports.TemplateApiFactory = exports.TemplateApiFp = exports.TemplateApiAxiosParamCreator = exports.ListApi = exports.ListApiFactory = exports.ListApiFp = exports.ListApiAxiosParamCreator = exports.LinksApi = exports.LinksApiFactory = exports.LinksApiFp = exports.LinksApiAxiosParamCreator = exports.JobApi = exports.JobApiFactory = exports.JobApiFp = exports.JobApiAxiosParamCreator = exports.GeneralApi = exports.GeneralApiFactory = exports.GeneralApiFp = exports.GeneralApiAxiosParamCreator = exports.CRMScriptApi = exports.CRMScriptApiFactory = exports.CRMScriptApiFp = exports.CRMScriptApiAxiosParamCreator = exports.ActionApi = exports.ActionApiFactory = exports.ActionApiFp = exports.ActionApiAxiosParamCreator = exports.ETreeType = exports.ETemplateType = exports.ETargetGroupType = exports.ESectionEntity = exports.ESectionActionType = exports.EPlanningYearPattern = exports.EPlanningWeekDays = exports.EPlanningSubPattern = exports.EPlanningPattern = exports.EPlanningMonthNames = exports.ELinkExpirationStatus = exports.ECriteriaOperator = exports.ECriteriaLogicalOperator = exports.EActionParameterType = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 *
 * @export
 * @enum {string}
 */
exports.EActionParameterType = {
    Custom: 'Custom',
    So: 'So'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.ECriteriaLogicalOperator = {
    And: 'and',
    Or: 'or'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.ECriteriaOperator = {
    Set: 'set',
    Eq: 'eq',
    Ne: 'ne',
    Lt: 'lt',
    Gt: 'gt',
    Greater: 'greater',
    Less: 'less',
    Unequals: 'unequals',
    Begins: 'begins',
    Between: 'between',
    Contains: 'contains',
    Is: 'is',
    Notbegins: 'notbegins',
    Notcontains: 'notcontains',
    Isnot: 'isnot',
    Before: 'before',
    Date: 'date',
    After: 'after',
    Datebetween: 'datebetween',
    Datetime: 'datetime',
    Beforetime: 'beforetime',
    Aftertime: 'aftertime',
    Beforetoday: 'beforetoday',
    Today: 'today',
    Aftertoday: 'aftertoday',
    Lastweek: 'lastweek',
    Thisweek: 'thisweek',
    Nextweek: 'nextweek',
    Lastmonth: 'lastmonth',
    Thismonth: 'thismonth',
    Nextmonth: 'nextmonth',
    Lastquarter: 'lastquarter',
    Thisquarter: 'thisquarter',
    Nextquarter: 'nextquarter',
    CustomWeek1WeeksAgo: 'custom_week_1_weeks_ago',
    Custom2WeeksAgo: 'custom_2_weeks_ago',
    Custom3WeeksAgo: 'custom_3_weeks_ago',
    Custom6WeeksAgo: 'custom_6_weeks_ago',
    Custom12WeeksAgo: 'custom_12_weeks_ago',
    Custom18WeeksAgo: 'custom_18_weeks_ago',
    Custom24WeeksAgo: 'custom_24_weeks_ago',
    Custom30WeeksAgo: 'custom_30_weeks_ago',
    Thishalf: 'thishalf',
    Thisyear: 'thisyear',
    Associateisoneof: 'associateisoneof',
    Associateisnotoneof: 'associateisnotoneof',
    Currentassociate: 'currentassociate',
    Equals: 'equals',
    Oneof: 'oneof',
    Notoneof: 'notoneof'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.ELinkExpirationStatus = {
    Expired: 'Expired',
    Active: 'Active'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.EPlanningMonthNames = {
    Jan: 'JAN',
    Feb: 'FEB',
    Mar: 'MAR',
    Apr: 'APR',
    May: 'MAY',
    Jun: 'JUN',
    Jul: 'JUL',
    Aug: 'AUG',
    Sep: 'SEP',
    Oct: 'OCT',
    Nov: 'NOV',
    Dec: 'DEC'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.EPlanningPattern = {
    Unknown: 'UNKNOWN',
    Daily: 'DAILY',
    Weekly: 'WEEKLY',
    Monthly: 'MONTHLY',
    Yearly: 'YEARLY'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.EPlanningSubPattern = {
    Startof: 'STARTOF',
    Endof: 'ENDOF'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.EPlanningWeekDays = {
    Sun: 'SUN',
    Mon: 'MON',
    Tue: 'TUE',
    Wed: 'WED',
    Thu: 'THU',
    Fri: 'FRI',
    Sat: 'SAT'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.EPlanningYearPattern = {
    _1: '1',
    _2: '2',
    _3: '3',
    _4: '4'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.ESectionActionType = {
    CrmScript: 'CRMScript',
    Entity: 'Entity',
    Link: 'Link'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.ESectionEntity = {
    Contact: 'Contact',
    Person: 'Person',
    Project: 'Project',
    Sale: 'Sale',
    Document: 'Document',
    Appointment: 'Appointment'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.ETargetGroupType = {
    User: 'USER',
    Group: 'GROUP'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.ETemplateType = {
    Wrapper: 'WRAPPER',
    Section: 'SECTION'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.ETreeType = {
    File: 'File',
    Folder: 'Folder'
};
/**
 * ActionApi - axios parameter creator
 * @export
 */
var ActionApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Creates a default action entry
         * @param {IDefaultSectionActionType} iDefaultSectionActionType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionCreateDefaultPost: function (iDefaultSectionActionType, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'iDefaultSectionActionType' is not null or undefined
                            (0, common_1.assertParamExists)('actionCreateDefaultPost', 'iDefaultSectionActionType', iDefaultSectionActionType);
                            localVarPath = "/action/CreateDefault";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(iDefaultSectionActionType, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete collection of actions from database
         * @param {Array<IDeleteArray>} iDeleteArray
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionDelete: function (iDeleteArray, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'iDeleteArray' is not null or undefined
                            (0, common_1.assertParamExists)('actionDelete', 'iDeleteArray', iDeleteArray);
                            localVarPath = "/action";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(iDeleteArray, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get action items
         * @summary Get all action Items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionGet: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/action";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete a specific action from database
         * @param {string} id The unique identifier of the action to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionIdDelete: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('actionIdDelete', 'id', id);
                            localVarPath = "/action/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update a specific action
         * @param {string} id The unique identifier of the action to update
         * @param {ISectionAction} iSectionAction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionIdPut: function (id, iSectionAction, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('actionIdPut', 'id', id);
                            // verify required parameter 'iSectionAction' is not null or undefined
                            (0, common_1.assertParamExists)('actionIdPut', 'iSectionAction', iSectionAction);
                            localVarPath = "/action/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(iSectionAction, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Post collection of actions to database (Create new ones)
         * @param {Array<ISectionAction>} iSectionAction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionPost: function (iSectionAction, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'iSectionAction' is not null or undefined
                            (0, common_1.assertParamExists)('actionPost', 'iSectionAction', iSectionAction);
                            localVarPath = "/action";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(iSectionAction, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Updates a collection of actions
         * @param {Array<ISectionAction>} iSectionAction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionPut: function (iSectionAction, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'iSectionAction' is not null or undefined
                            (0, common_1.assertParamExists)('actionPut', 'iSectionAction', iSectionAction);
                            localVarPath = "/action";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(iSectionAction, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ActionApiAxiosParamCreator = ActionApiAxiosParamCreator;
/**
 * ActionApi - functional programming interface
 * @export
 */
var ActionApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.ActionApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Creates a default action entry
         * @param {IDefaultSectionActionType} iDefaultSectionActionType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionCreateDefaultPost: function (iDefaultSectionActionType, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.actionCreateDefaultPost(iDefaultSectionActionType, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete collection of actions from database
         * @param {Array<IDeleteArray>} iDeleteArray
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionDelete: function (iDeleteArray, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.actionDelete(iDeleteArray, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get action items
         * @summary Get all action Items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.actionGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete a specific action from database
         * @param {string} id The unique identifier of the action to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionIdDelete: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.actionIdDelete(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update a specific action
         * @param {string} id The unique identifier of the action to update
         * @param {ISectionAction} iSectionAction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionIdPut: function (id, iSectionAction, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.actionIdPut(id, iSectionAction, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Post collection of actions to database (Create new ones)
         * @param {Array<ISectionAction>} iSectionAction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionPost: function (iSectionAction, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.actionPost(iSectionAction, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Updates a collection of actions
         * @param {Array<ISectionAction>} iSectionAction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionPut: function (iSectionAction, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.actionPut(iSectionAction, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.ActionApiFp = ActionApiFp;
/**
 * ActionApi - factory interface
 * @export
 */
var ActionApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.ActionApiFp)(configuration);
    return {
        /**
         *
         * @summary Creates a default action entry
         * @param {IDefaultSectionActionType} iDefaultSectionActionType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionCreateDefaultPost: function (iDefaultSectionActionType, options) {
            return localVarFp.actionCreateDefaultPost(iDefaultSectionActionType, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Delete collection of actions from database
         * @param {Array<IDeleteArray>} iDeleteArray
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionDelete: function (iDeleteArray, options) {
            return localVarFp.actionDelete(iDeleteArray, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get action items
         * @summary Get all action Items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionGet: function (options) {
            return localVarFp.actionGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Delete a specific action from database
         * @param {string} id The unique identifier of the action to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionIdDelete: function (id, options) {
            return localVarFp.actionIdDelete(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update a specific action
         * @param {string} id The unique identifier of the action to update
         * @param {ISectionAction} iSectionAction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionIdPut: function (id, iSectionAction, options) {
            return localVarFp.actionIdPut(id, iSectionAction, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Post collection of actions to database (Create new ones)
         * @param {Array<ISectionAction>} iSectionAction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionPost: function (iSectionAction, options) {
            return localVarFp.actionPost(iSectionAction, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Updates a collection of actions
         * @param {Array<ISectionAction>} iSectionAction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionPut: function (iSectionAction, options) {
            return localVarFp.actionPut(iSectionAction, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ActionApiFactory = ActionApiFactory;
/**
 * ActionApi - object-oriented interface
 * @export
 * @class ActionApi
 * @extends {BaseAPI}
 */
var ActionApi = /** @class */ (function (_super) {
    __extends(ActionApi, _super);
    function ActionApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Creates a default action entry
     * @param {IDefaultSectionActionType} iDefaultSectionActionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionApi
     */
    ActionApi.prototype.actionCreateDefaultPost = function (iDefaultSectionActionType, options) {
        var _this = this;
        return (0, exports.ActionApiFp)(this.configuration).actionCreateDefaultPost(iDefaultSectionActionType, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Delete collection of actions from database
     * @param {Array<IDeleteArray>} iDeleteArray
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionApi
     */
    ActionApi.prototype.actionDelete = function (iDeleteArray, options) {
        var _this = this;
        return (0, exports.ActionApiFp)(this.configuration).actionDelete(iDeleteArray, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get action items
     * @summary Get all action Items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionApi
     */
    ActionApi.prototype.actionGet = function (options) {
        var _this = this;
        return (0, exports.ActionApiFp)(this.configuration).actionGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Delete a specific action from database
     * @param {string} id The unique identifier of the action to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionApi
     */
    ActionApi.prototype.actionIdDelete = function (id, options) {
        var _this = this;
        return (0, exports.ActionApiFp)(this.configuration).actionIdDelete(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update a specific action
     * @param {string} id The unique identifier of the action to update
     * @param {ISectionAction} iSectionAction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionApi
     */
    ActionApi.prototype.actionIdPut = function (id, iSectionAction, options) {
        var _this = this;
        return (0, exports.ActionApiFp)(this.configuration).actionIdPut(id, iSectionAction, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Post collection of actions to database (Create new ones)
     * @param {Array<ISectionAction>} iSectionAction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionApi
     */
    ActionApi.prototype.actionPost = function (iSectionAction, options) {
        var _this = this;
        return (0, exports.ActionApiFp)(this.configuration).actionPost(iSectionAction, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Updates a collection of actions
     * @param {Array<ISectionAction>} iSectionAction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionApi
     */
    ActionApi.prototype.actionPut = function (iSectionAction, options) {
        var _this = this;
        return (0, exports.ActionApiFp)(this.configuration).actionPut(iSectionAction, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ActionApi;
}(base_1.BaseAPI));
exports.ActionApi = ActionApi;
/**
 * CRMScriptApi - axios parameter creator
 * @export
 */
var CRMScriptApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * This function returns a list of all available associates in SuperOffice.
         * @summary Get a list of all CRMScripts in SuperOffice, but in treeview order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmscriptBuildTreeGet: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/crmscript/buildTree";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This function returns a list of all available associates in SuperOffice.
         * @summary Get a list of all CRMScripts in SuperOffice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmscriptGet: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/crmscript";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This function returns a list of all available variables inside a given crmscript\'s source.
         * @summary Get a list of all variables inside a given crmscript
         * @param {number} id The unique identifier of the CRMScript
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmscriptIdVariablesGet: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('crmscriptIdVariablesGet', 'id', id);
                            localVarPath = "/crmscript/{id}/variables"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.CRMScriptApiAxiosParamCreator = CRMScriptApiAxiosParamCreator;
/**
 * CRMScriptApi - functional programming interface
 * @export
 */
var CRMScriptApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.CRMScriptApiAxiosParamCreator)(configuration);
    return {
        /**
         * This function returns a list of all available associates in SuperOffice.
         * @summary Get a list of all CRMScripts in SuperOffice, but in treeview order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmscriptBuildTreeGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.crmscriptBuildTreeGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This function returns a list of all available associates in SuperOffice.
         * @summary Get a list of all CRMScripts in SuperOffice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmscriptGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.crmscriptGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This function returns a list of all available variables inside a given crmscript\'s source.
         * @summary Get a list of all variables inside a given crmscript
         * @param {number} id The unique identifier of the CRMScript
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmscriptIdVariablesGet: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.crmscriptIdVariablesGet(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.CRMScriptApiFp = CRMScriptApiFp;
/**
 * CRMScriptApi - factory interface
 * @export
 */
var CRMScriptApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.CRMScriptApiFp)(configuration);
    return {
        /**
         * This function returns a list of all available associates in SuperOffice.
         * @summary Get a list of all CRMScripts in SuperOffice, but in treeview order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmscriptBuildTreeGet: function (options) {
            return localVarFp.crmscriptBuildTreeGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This function returns a list of all available associates in SuperOffice.
         * @summary Get a list of all CRMScripts in SuperOffice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmscriptGet: function (options) {
            return localVarFp.crmscriptGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This function returns a list of all available variables inside a given crmscript\'s source.
         * @summary Get a list of all variables inside a given crmscript
         * @param {number} id The unique identifier of the CRMScript
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmscriptIdVariablesGet: function (id, options) {
            return localVarFp.crmscriptIdVariablesGet(id, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.CRMScriptApiFactory = CRMScriptApiFactory;
/**
 * CRMScriptApi - object-oriented interface
 * @export
 * @class CRMScriptApi
 * @extends {BaseAPI}
 */
var CRMScriptApi = /** @class */ (function (_super) {
    __extends(CRMScriptApi, _super);
    function CRMScriptApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * This function returns a list of all available associates in SuperOffice.
     * @summary Get a list of all CRMScripts in SuperOffice, but in treeview order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CRMScriptApi
     */
    CRMScriptApi.prototype.crmscriptBuildTreeGet = function (options) {
        var _this = this;
        return (0, exports.CRMScriptApiFp)(this.configuration).crmscriptBuildTreeGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This function returns a list of all available associates in SuperOffice.
     * @summary Get a list of all CRMScripts in SuperOffice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CRMScriptApi
     */
    CRMScriptApi.prototype.crmscriptGet = function (options) {
        var _this = this;
        return (0, exports.CRMScriptApiFp)(this.configuration).crmscriptGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This function returns a list of all available variables inside a given crmscript\'s source.
     * @summary Get a list of all variables inside a given crmscript
     * @param {number} id The unique identifier of the CRMScript
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CRMScriptApi
     */
    CRMScriptApi.prototype.crmscriptIdVariablesGet = function (id, options) {
        var _this = this;
        return (0, exports.CRMScriptApiFp)(this.configuration).crmscriptIdVariablesGet(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return CRMScriptApi;
}(base_1.BaseAPI));
exports.CRMScriptApi = CRMScriptApi;
/**
 * GeneralApi - axios parameter creator
 * @export
 */
var GeneralApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Get which user is currently logged in, and their details. You will be receiving a minimal subset of the actual user and tenant information, as all sensitive information is filtered.
         * @summary Get which user is currently logged in.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalCurrentUserLoginGet: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/general/currentUserLogin";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.GeneralApiAxiosParamCreator = GeneralApiAxiosParamCreator;
/**
 * GeneralApi - functional programming interface
 * @export
 */
var GeneralApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.GeneralApiAxiosParamCreator)(configuration);
    return {
        /**
         * Get which user is currently logged in, and their details. You will be receiving a minimal subset of the actual user and tenant information, as all sensitive information is filtered.
         * @summary Get which user is currently logged in.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalCurrentUserLoginGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.generalCurrentUserLoginGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.GeneralApiFp = GeneralApiFp;
/**
 * GeneralApi - factory interface
 * @export
 */
var GeneralApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.GeneralApiFp)(configuration);
    return {
        /**
         * Get which user is currently logged in, and their details. You will be receiving a minimal subset of the actual user and tenant information, as all sensitive information is filtered.
         * @summary Get which user is currently logged in.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalCurrentUserLoginGet: function (options) {
            return localVarFp.generalCurrentUserLoginGet(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.GeneralApiFactory = GeneralApiFactory;
/**
 * GeneralApi - object-oriented interface
 * @export
 * @class GeneralApi
 * @extends {BaseAPI}
 */
var GeneralApi = /** @class */ (function (_super) {
    __extends(GeneralApi, _super);
    function GeneralApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Get which user is currently logged in, and their details. You will be receiving a minimal subset of the actual user and tenant information, as all sensitive information is filtered.
     * @summary Get which user is currently logged in.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.generalCurrentUserLoginGet = function (options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).generalCurrentUserLoginGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return GeneralApi;
}(base_1.BaseAPI));
exports.GeneralApi = GeneralApi;
/**
 * JobApi - axios parameter creator
 * @export
 */
var JobApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Creates a default job entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobCreateDefaultPost: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/job/CreateDefault";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete collection of jobs from database
         * @param {Array<IDeleteArray>} iDeleteArray
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobDelete: function (iDeleteArray, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'iDeleteArray' is not null or undefined
                            (0, common_1.assertParamExists)('jobDelete', 'iDeleteArray', iDeleteArray);
                            localVarPath = "/job";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(iDeleteArray, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get Job items
         * @summary Get all Job Items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobGet: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/job";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete a specific job from database
         * @param {string} id The unique identifier of the job to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobIdDelete: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('jobIdDelete', 'id', id);
                            localVarPath = "/job/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get one Job item
         * @summary Get one Job Items
         * @param {number} id The unique identifier of the Job
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobIdGet: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('jobIdGet', 'id', id);
                            localVarPath = "/job/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update a specific job
         * @param {string} id The unique identifier of the job to update
         * @param {IJobListItem} iJobListItem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobIdPut: function (id, iJobListItem, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('jobIdPut', 'id', id);
                            // verify required parameter 'iJobListItem' is not null or undefined
                            (0, common_1.assertParamExists)('jobIdPut', 'iJobListItem', iJobListItem);
                            localVarPath = "/job/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(iJobListItem, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This endpoint will give you a preview of the emails that will be generated by the job if it were running today.
         * @summary Test the job
         * @param {string} id The unique identifier of the Job
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobIdTestGet: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('jobIdTestGet', 'id', id);
                            localVarPath = "/job/{id}/test"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Post collection of jobs to database (Create new ones)
         * @param {Array<IJobListItem>} iJobListItem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobPost: function (iJobListItem, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'iJobListItem' is not null or undefined
                            (0, common_1.assertParamExists)('jobPost', 'iJobListItem', iJobListItem);
                            localVarPath = "/job";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(iJobListItem, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Updates a collection of jobs
         * @param {Array<IJobListItem>} iJobListItem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobPut: function (iJobListItem, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'iJobListItem' is not null or undefined
                            (0, common_1.assertParamExists)('jobPut', 'iJobListItem', iJobListItem);
                            localVarPath = "/job";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(iJobListItem, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.JobApiAxiosParamCreator = JobApiAxiosParamCreator;
/**
 * JobApi - functional programming interface
 * @export
 */
var JobApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.JobApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Creates a default job entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobCreateDefaultPost: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.jobCreateDefaultPost(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete collection of jobs from database
         * @param {Array<IDeleteArray>} iDeleteArray
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobDelete: function (iDeleteArray, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.jobDelete(iDeleteArray, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get Job items
         * @summary Get all Job Items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.jobGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete a specific job from database
         * @param {string} id The unique identifier of the job to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobIdDelete: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.jobIdDelete(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get one Job item
         * @summary Get one Job Items
         * @param {number} id The unique identifier of the Job
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobIdGet: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.jobIdGet(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update a specific job
         * @param {string} id The unique identifier of the job to update
         * @param {IJobListItem} iJobListItem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobIdPut: function (id, iJobListItem, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.jobIdPut(id, iJobListItem, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This endpoint will give you a preview of the emails that will be generated by the job if it were running today.
         * @summary Test the job
         * @param {string} id The unique identifier of the Job
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobIdTestGet: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.jobIdTestGet(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Post collection of jobs to database (Create new ones)
         * @param {Array<IJobListItem>} iJobListItem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobPost: function (iJobListItem, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.jobPost(iJobListItem, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Updates a collection of jobs
         * @param {Array<IJobListItem>} iJobListItem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobPut: function (iJobListItem, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.jobPut(iJobListItem, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.JobApiFp = JobApiFp;
/**
 * JobApi - factory interface
 * @export
 */
var JobApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.JobApiFp)(configuration);
    return {
        /**
         *
         * @summary Creates a default job entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobCreateDefaultPost: function (options) {
            return localVarFp.jobCreateDefaultPost(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Delete collection of jobs from database
         * @param {Array<IDeleteArray>} iDeleteArray
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobDelete: function (iDeleteArray, options) {
            return localVarFp.jobDelete(iDeleteArray, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get Job items
         * @summary Get all Job Items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobGet: function (options) {
            return localVarFp.jobGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Delete a specific job from database
         * @param {string} id The unique identifier of the job to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobIdDelete: function (id, options) {
            return localVarFp.jobIdDelete(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get one Job item
         * @summary Get one Job Items
         * @param {number} id The unique identifier of the Job
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobIdGet: function (id, options) {
            return localVarFp.jobIdGet(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update a specific job
         * @param {string} id The unique identifier of the job to update
         * @param {IJobListItem} iJobListItem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobIdPut: function (id, iJobListItem, options) {
            return localVarFp.jobIdPut(id, iJobListItem, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This endpoint will give you a preview of the emails that will be generated by the job if it were running today.
         * @summary Test the job
         * @param {string} id The unique identifier of the Job
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobIdTestGet: function (id, options) {
            return localVarFp.jobIdTestGet(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Post collection of jobs to database (Create new ones)
         * @param {Array<IJobListItem>} iJobListItem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobPost: function (iJobListItem, options) {
            return localVarFp.jobPost(iJobListItem, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Updates a collection of jobs
         * @param {Array<IJobListItem>} iJobListItem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobPut: function (iJobListItem, options) {
            return localVarFp.jobPut(iJobListItem, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.JobApiFactory = JobApiFactory;
/**
 * JobApi - object-oriented interface
 * @export
 * @class JobApi
 * @extends {BaseAPI}
 */
var JobApi = /** @class */ (function (_super) {
    __extends(JobApi, _super);
    function JobApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Creates a default job entry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    JobApi.prototype.jobCreateDefaultPost = function (options) {
        var _this = this;
        return (0, exports.JobApiFp)(this.configuration).jobCreateDefaultPost(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Delete collection of jobs from database
     * @param {Array<IDeleteArray>} iDeleteArray
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    JobApi.prototype.jobDelete = function (iDeleteArray, options) {
        var _this = this;
        return (0, exports.JobApiFp)(this.configuration).jobDelete(iDeleteArray, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get Job items
     * @summary Get all Job Items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    JobApi.prototype.jobGet = function (options) {
        var _this = this;
        return (0, exports.JobApiFp)(this.configuration).jobGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Delete a specific job from database
     * @param {string} id The unique identifier of the job to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    JobApi.prototype.jobIdDelete = function (id, options) {
        var _this = this;
        return (0, exports.JobApiFp)(this.configuration).jobIdDelete(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get one Job item
     * @summary Get one Job Items
     * @param {number} id The unique identifier of the Job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    JobApi.prototype.jobIdGet = function (id, options) {
        var _this = this;
        return (0, exports.JobApiFp)(this.configuration).jobIdGet(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update a specific job
     * @param {string} id The unique identifier of the job to update
     * @param {IJobListItem} iJobListItem
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    JobApi.prototype.jobIdPut = function (id, iJobListItem, options) {
        var _this = this;
        return (0, exports.JobApiFp)(this.configuration).jobIdPut(id, iJobListItem, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This endpoint will give you a preview of the emails that will be generated by the job if it were running today.
     * @summary Test the job
     * @param {string} id The unique identifier of the Job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    JobApi.prototype.jobIdTestGet = function (id, options) {
        var _this = this;
        return (0, exports.JobApiFp)(this.configuration).jobIdTestGet(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Post collection of jobs to database (Create new ones)
     * @param {Array<IJobListItem>} iJobListItem
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    JobApi.prototype.jobPost = function (iJobListItem, options) {
        var _this = this;
        return (0, exports.JobApiFp)(this.configuration).jobPost(iJobListItem, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Updates a collection of jobs
     * @param {Array<IJobListItem>} iJobListItem
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    JobApi.prototype.jobPut = function (iJobListItem, options) {
        var _this = this;
        return (0, exports.JobApiFp)(this.configuration).jobPut(iJobListItem, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return JobApi;
}(base_1.BaseAPI));
exports.JobApi = JobApi;
/**
 * LinksApi - axios parameter creator
 * @export
 */
var LinksApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * The API call will return information about the action that is carried. If the link is a \"Run all link\", we will return information about all the links that are part of the run all link, and their id, so that the front end can run them. If this is a single link, it will just return one link to be executed.
         * @summary Get the details of the link, by using the id of the link.
         * @param {string} linkId The unique identifier of the link to get details for.
         * @param {string} email The email that received the mail containing the link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkLinkIdGet: function (linkId, email, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'linkId' is not null or undefined
                    (0, common_1.assertParamExists)('linkLinkIdGet', 'linkId', linkId);
                    // verify required parameter 'email' is not null or undefined
                    (0, common_1.assertParamExists)('linkLinkIdGet', 'email', email);
                    localVarPath = "/link/{linkId}"
                        .replace("{".concat("linkId", "}"), encodeURIComponent(String(linkId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (email !== undefined) {
                        localVarQueryParameter['email'] = email;
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * The API call will execute the link id provided.
         * @summary Executes the action of the link
         * @param {string} linkId The unique identifier of the link to execute
         * @param {string} email The email that received the mail containing the link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkLinkIdPost: function (linkId, email, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'linkId' is not null or undefined
                    (0, common_1.assertParamExists)('linkLinkIdPost', 'linkId', linkId);
                    // verify required parameter 'email' is not null or undefined
                    (0, common_1.assertParamExists)('linkLinkIdPost', 'email', email);
                    localVarPath = "/link/{linkId}"
                        .replace("{".concat("linkId", "}"), encodeURIComponent(String(linkId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (email !== undefined) {
                        localVarQueryParameter['email'] = email;
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.LinksApiAxiosParamCreator = LinksApiAxiosParamCreator;
/**
 * LinksApi - functional programming interface
 * @export
 */
var LinksApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.LinksApiAxiosParamCreator)(configuration);
    return {
        /**
         * The API call will return information about the action that is carried. If the link is a \"Run all link\", we will return information about all the links that are part of the run all link, and their id, so that the front end can run them. If this is a single link, it will just return one link to be executed.
         * @summary Get the details of the link, by using the id of the link.
         * @param {string} linkId The unique identifier of the link to get details for.
         * @param {string} email The email that received the mail containing the link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkLinkIdGet: function (linkId, email, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.linkLinkIdGet(linkId, email, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * The API call will execute the link id provided.
         * @summary Executes the action of the link
         * @param {string} linkId The unique identifier of the link to execute
         * @param {string} email The email that received the mail containing the link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkLinkIdPost: function (linkId, email, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.linkLinkIdPost(linkId, email, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.LinksApiFp = LinksApiFp;
/**
 * LinksApi - factory interface
 * @export
 */
var LinksApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.LinksApiFp)(configuration);
    return {
        /**
         * The API call will return information about the action that is carried. If the link is a \"Run all link\", we will return information about all the links that are part of the run all link, and their id, so that the front end can run them. If this is a single link, it will just return one link to be executed.
         * @summary Get the details of the link, by using the id of the link.
         * @param {string} linkId The unique identifier of the link to get details for.
         * @param {string} email The email that received the mail containing the link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkLinkIdGet: function (linkId, email, options) {
            return localVarFp.linkLinkIdGet(linkId, email, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * The API call will execute the link id provided.
         * @summary Executes the action of the link
         * @param {string} linkId The unique identifier of the link to execute
         * @param {string} email The email that received the mail containing the link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkLinkIdPost: function (linkId, email, options) {
            return localVarFp.linkLinkIdPost(linkId, email, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.LinksApiFactory = LinksApiFactory;
/**
 * LinksApi - object-oriented interface
 * @export
 * @class LinksApi
 * @extends {BaseAPI}
 */
var LinksApi = /** @class */ (function (_super) {
    __extends(LinksApi, _super);
    function LinksApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * The API call will return information about the action that is carried. If the link is a \"Run all link\", we will return information about all the links that are part of the run all link, and their id, so that the front end can run them. If this is a single link, it will just return one link to be executed.
     * @summary Get the details of the link, by using the id of the link.
     * @param {string} linkId The unique identifier of the link to get details for.
     * @param {string} email The email that received the mail containing the link
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinksApi
     */
    LinksApi.prototype.linkLinkIdGet = function (linkId, email, options) {
        var _this = this;
        return (0, exports.LinksApiFp)(this.configuration).linkLinkIdGet(linkId, email, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * The API call will execute the link id provided.
     * @summary Executes the action of the link
     * @param {string} linkId The unique identifier of the link to execute
     * @param {string} email The email that received the mail containing the link
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinksApi
     */
    LinksApi.prototype.linkLinkIdPost = function (linkId, email, options) {
        var _this = this;
        return (0, exports.LinksApiFp)(this.configuration).linkLinkIdPost(linkId, email, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return LinksApi;
}(base_1.BaseAPI));
exports.LinksApi = LinksApi;
/**
 * ListApi - axios parameter creator
 * @export
 */
var ListApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * This function returns a list of all available associates in SuperOffice.
         * @summary Get a list of all associates in SuperOffice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssociatesGet: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/list/associates";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This function returns a list of all available columns to sort on for a specific archiveprovider in SuperOffice.
         * @summary Get a list of all availbale columns for a specific provider
         * @param {string} provider The archive provider name for which you wish to get the list of available criteria fields for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listColumnsProviderGet: function (provider, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'provider' is not null or undefined
                            (0, common_1.assertParamExists)('listColumnsProviderGet', 'provider', provider);
                            localVarPath = "/list/columns/{provider}"
                                .replace("{".concat("provider", "}"), encodeURIComponent(String(provider)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get a list of all list items from a specified owner type and progId.
         * @summary Get a list of all list items from a specified owner type and progId
         * @param {string} ownerType The index of the entity type, comes from ESectionEntity enum.
         * @param {string} progId The prog id of the custom field in SuperOffice.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGetListByFieldProgIdGet: function (ownerType, progId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'ownerType' is not null or undefined
                            (0, common_1.assertParamExists)('listGetListByFieldProgIdGet', 'ownerType', ownerType);
                            // verify required parameter 'progId' is not null or undefined
                            (0, common_1.assertParamExists)('listGetListByFieldProgIdGet', 'progId', progId);
                            localVarPath = "/list/getListByFieldProgId";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            if (ownerType !== undefined) {
                                localVarQueryParameter['ownerType'] = ownerType;
                            }
                            if (progId !== undefined) {
                                localVarQueryParameter['progId'] = progId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get a list of all list items from a specified MDO List. This works for both build in and custom lists..
         * @summary Get a list of all list items from a specified owner type and progId
         * @param {string} mDOListName The name of the mdo list to pull from, default is the associate list. This works for both build in and custom lists.
         * @param {string} [query] Filtering for the list
         * @param {boolean} [flatten] If true, the list will be returned as a flat list, without child items.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGetListByMDONameMDOListNameGet: function (mDOListName, query, flatten, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'mDOListName' is not null or undefined
                            (0, common_1.assertParamExists)('listGetListByMDONameMDOListNameGet', 'mDOListName', mDOListName);
                            localVarPath = "/list/getListByMDOName/{MDOListName}"
                                .replace("{".concat("MDOListName", "}"), encodeURIComponent(String(mDOListName)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            if (query !== undefined) {
                                localVarQueryParameter['query'] = query;
                            }
                            if (flatten !== undefined) {
                                localVarQueryParameter['flatten'] = flatten;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This function returns a list of all available usergroups in SuperOffice.
         * @summary Get a list of all usergroups in SuperOffice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsergroupsGet: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/list/usergroups";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ListApiAxiosParamCreator = ListApiAxiosParamCreator;
/**
 * ListApi - functional programming interface
 * @export
 */
var ListApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.ListApiAxiosParamCreator)(configuration);
    return {
        /**
         * This function returns a list of all available associates in SuperOffice.
         * @summary Get a list of all associates in SuperOffice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssociatesGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listAssociatesGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This function returns a list of all available columns to sort on for a specific archiveprovider in SuperOffice.
         * @summary Get a list of all availbale columns for a specific provider
         * @param {string} provider The archive provider name for which you wish to get the list of available criteria fields for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listColumnsProviderGet: function (provider, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listColumnsProviderGet(provider, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get a list of all list items from a specified owner type and progId.
         * @summary Get a list of all list items from a specified owner type and progId
         * @param {string} ownerType The index of the entity type, comes from ESectionEntity enum.
         * @param {string} progId The prog id of the custom field in SuperOffice.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGetListByFieldProgIdGet: function (ownerType, progId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listGetListByFieldProgIdGet(ownerType, progId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get a list of all list items from a specified MDO List. This works for both build in and custom lists..
         * @summary Get a list of all list items from a specified owner type and progId
         * @param {string} mDOListName The name of the mdo list to pull from, default is the associate list. This works for both build in and custom lists.
         * @param {string} [query] Filtering for the list
         * @param {boolean} [flatten] If true, the list will be returned as a flat list, without child items.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGetListByMDONameMDOListNameGet: function (mDOListName, query, flatten, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listGetListByMDONameMDOListNameGet(mDOListName, query, flatten, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This function returns a list of all available usergroups in SuperOffice.
         * @summary Get a list of all usergroups in SuperOffice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsergroupsGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listUsergroupsGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.ListApiFp = ListApiFp;
/**
 * ListApi - factory interface
 * @export
 */
var ListApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.ListApiFp)(configuration);
    return {
        /**
         * This function returns a list of all available associates in SuperOffice.
         * @summary Get a list of all associates in SuperOffice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssociatesGet: function (options) {
            return localVarFp.listAssociatesGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This function returns a list of all available columns to sort on for a specific archiveprovider in SuperOffice.
         * @summary Get a list of all availbale columns for a specific provider
         * @param {string} provider The archive provider name for which you wish to get the list of available criteria fields for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listColumnsProviderGet: function (provider, options) {
            return localVarFp.listColumnsProviderGet(provider, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get a list of all list items from a specified owner type and progId.
         * @summary Get a list of all list items from a specified owner type and progId
         * @param {string} ownerType The index of the entity type, comes from ESectionEntity enum.
         * @param {string} progId The prog id of the custom field in SuperOffice.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGetListByFieldProgIdGet: function (ownerType, progId, options) {
            return localVarFp.listGetListByFieldProgIdGet(ownerType, progId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get a list of all list items from a specified MDO List. This works for both build in and custom lists..
         * @summary Get a list of all list items from a specified owner type and progId
         * @param {string} mDOListName The name of the mdo list to pull from, default is the associate list. This works for both build in and custom lists.
         * @param {string} [query] Filtering for the list
         * @param {boolean} [flatten] If true, the list will be returned as a flat list, without child items.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGetListByMDONameMDOListNameGet: function (mDOListName, query, flatten, options) {
            return localVarFp.listGetListByMDONameMDOListNameGet(mDOListName, query, flatten, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This function returns a list of all available usergroups in SuperOffice.
         * @summary Get a list of all usergroups in SuperOffice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsergroupsGet: function (options) {
            return localVarFp.listUsergroupsGet(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ListApiFactory = ListApiFactory;
/**
 * ListApi - object-oriented interface
 * @export
 * @class ListApi
 * @extends {BaseAPI}
 */
var ListApi = /** @class */ (function (_super) {
    __extends(ListApi, _super);
    function ListApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * This function returns a list of all available associates in SuperOffice.
     * @summary Get a list of all associates in SuperOffice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListApi
     */
    ListApi.prototype.listAssociatesGet = function (options) {
        var _this = this;
        return (0, exports.ListApiFp)(this.configuration).listAssociatesGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This function returns a list of all available columns to sort on for a specific archiveprovider in SuperOffice.
     * @summary Get a list of all availbale columns for a specific provider
     * @param {string} provider The archive provider name for which you wish to get the list of available criteria fields for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListApi
     */
    ListApi.prototype.listColumnsProviderGet = function (provider, options) {
        var _this = this;
        return (0, exports.ListApiFp)(this.configuration).listColumnsProviderGet(provider, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get a list of all list items from a specified owner type and progId.
     * @summary Get a list of all list items from a specified owner type and progId
     * @param {string} ownerType The index of the entity type, comes from ESectionEntity enum.
     * @param {string} progId The prog id of the custom field in SuperOffice.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListApi
     */
    ListApi.prototype.listGetListByFieldProgIdGet = function (ownerType, progId, options) {
        var _this = this;
        return (0, exports.ListApiFp)(this.configuration).listGetListByFieldProgIdGet(ownerType, progId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get a list of all list items from a specified MDO List. This works for both build in and custom lists..
     * @summary Get a list of all list items from a specified owner type and progId
     * @param {string} mDOListName The name of the mdo list to pull from, default is the associate list. This works for both build in and custom lists.
     * @param {string} [query] Filtering for the list
     * @param {boolean} [flatten] If true, the list will be returned as a flat list, without child items.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListApi
     */
    ListApi.prototype.listGetListByMDONameMDOListNameGet = function (mDOListName, query, flatten, options) {
        var _this = this;
        return (0, exports.ListApiFp)(this.configuration).listGetListByMDONameMDOListNameGet(mDOListName, query, flatten, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This function returns a list of all available usergroups in SuperOffice.
     * @summary Get a list of all usergroups in SuperOffice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListApi
     */
    ListApi.prototype.listUsergroupsGet = function (options) {
        var _this = this;
        return (0, exports.ListApiFp)(this.configuration).listUsergroupsGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ListApi;
}(base_1.BaseAPI));
exports.ListApi = ListApi;
/**
 * TemplateApi - axios parameter creator
 * @export
 */
var TemplateApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create a new default template. Assigns a dbid.
         * @summary Create a new template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateCreateDefault: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/template/CreateDefault";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get all template items
         * @summary Get all template items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateGet: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/template";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Use a template ID to retrieve a template
         * @summary Get a template item via a specific ID
         * @param {string} id The unique identifier of the job to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateGetSpecific: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('templateGetSpecific', 'id', id);
                            localVarPath = "/template/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete a specific template from the database using an
         * @param {string} id The unique identifier of the job to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateIdDelete: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('templateIdDelete', 'id', id);
                            localVarPath = "/template/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Updates a template via a specific ID
         * @param {string} id The unique identifier of the job to update
         * @param {ITemplate} iTemplate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateIdPut: function (id, iTemplate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('templateIdPut', 'id', id);
                            // verify required parameter 'iTemplate' is not null or undefined
                            (0, common_1.assertParamExists)('templateIdPut', 'iTemplate', iTemplate);
                            localVarPath = "/template/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(iTemplate, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create a new default template. Assigns a dbid.
         * @summary Create a new template
         * @param {ITemplate} iTemplate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templatePost: function (iTemplate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'iTemplate' is not null or undefined
                            (0, common_1.assertParamExists)('templatePost', 'iTemplate', iTemplate);
                            localVarPath = "/template";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication Bearer required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication Bearer required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(iTemplate, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.TemplateApiAxiosParamCreator = TemplateApiAxiosParamCreator;
/**
 * TemplateApi - functional programming interface
 * @export
 */
var TemplateApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.TemplateApiAxiosParamCreator)(configuration);
    return {
        /**
         * Create a new default template. Assigns a dbid.
         * @summary Create a new template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateCreateDefault: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.templateCreateDefault(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get all template items
         * @summary Get all template items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.templateGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Use a template ID to retrieve a template
         * @summary Get a template item via a specific ID
         * @param {string} id The unique identifier of the job to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateGetSpecific: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.templateGetSpecific(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete a specific template from the database using an
         * @param {string} id The unique identifier of the job to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateIdDelete: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.templateIdDelete(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Updates a template via a specific ID
         * @param {string} id The unique identifier of the job to update
         * @param {ITemplate} iTemplate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateIdPut: function (id, iTemplate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.templateIdPut(id, iTemplate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Create a new default template. Assigns a dbid.
         * @summary Create a new template
         * @param {ITemplate} iTemplate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templatePost: function (iTemplate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.templatePost(iTemplate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.TemplateApiFp = TemplateApiFp;
/**
 * TemplateApi - factory interface
 * @export
 */
var TemplateApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.TemplateApiFp)(configuration);
    return {
        /**
         * Create a new default template. Assigns a dbid.
         * @summary Create a new template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateCreateDefault: function (options) {
            return localVarFp.templateCreateDefault(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get all template items
         * @summary Get all template items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateGet: function (options) {
            return localVarFp.templateGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Use a template ID to retrieve a template
         * @summary Get a template item via a specific ID
         * @param {string} id The unique identifier of the job to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateGetSpecific: function (id, options) {
            return localVarFp.templateGetSpecific(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Delete a specific template from the database using an
         * @param {string} id The unique identifier of the job to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateIdDelete: function (id, options) {
            return localVarFp.templateIdDelete(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Updates a template via a specific ID
         * @param {string} id The unique identifier of the job to update
         * @param {ITemplate} iTemplate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateIdPut: function (id, iTemplate, options) {
            return localVarFp.templateIdPut(id, iTemplate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create a new default template. Assigns a dbid.
         * @summary Create a new template
         * @param {ITemplate} iTemplate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templatePost: function (iTemplate, options) {
            return localVarFp.templatePost(iTemplate, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.TemplateApiFactory = TemplateApiFactory;
/**
 * TemplateApi - object-oriented interface
 * @export
 * @class TemplateApi
 * @extends {BaseAPI}
 */
var TemplateApi = /** @class */ (function (_super) {
    __extends(TemplateApi, _super);
    function TemplateApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create a new default template. Assigns a dbid.
     * @summary Create a new template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    TemplateApi.prototype.templateCreateDefault = function (options) {
        var _this = this;
        return (0, exports.TemplateApiFp)(this.configuration).templateCreateDefault(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get all template items
     * @summary Get all template items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    TemplateApi.prototype.templateGet = function (options) {
        var _this = this;
        return (0, exports.TemplateApiFp)(this.configuration).templateGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Use a template ID to retrieve a template
     * @summary Get a template item via a specific ID
     * @param {string} id The unique identifier of the job to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    TemplateApi.prototype.templateGetSpecific = function (id, options) {
        var _this = this;
        return (0, exports.TemplateApiFp)(this.configuration).templateGetSpecific(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Delete a specific template from the database using an
     * @param {string} id The unique identifier of the job to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    TemplateApi.prototype.templateIdDelete = function (id, options) {
        var _this = this;
        return (0, exports.TemplateApiFp)(this.configuration).templateIdDelete(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Updates a template via a specific ID
     * @param {string} id The unique identifier of the job to update
     * @param {ITemplate} iTemplate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    TemplateApi.prototype.templateIdPut = function (id, iTemplate, options) {
        var _this = this;
        return (0, exports.TemplateApiFp)(this.configuration).templateIdPut(id, iTemplate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create a new default template. Assigns a dbid.
     * @summary Create a new template
     * @param {ITemplate} iTemplate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    TemplateApi.prototype.templatePost = function (iTemplate, options) {
        var _this = this;
        return (0, exports.TemplateApiFp)(this.configuration).templatePost(iTemplate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return TemplateApi;
}(base_1.BaseAPI));
exports.TemplateApi = TemplateApi;
