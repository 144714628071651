import React, { useEffect, useState } from "react";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBBtn,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBRadio,
} from "mdb-react-ui-kit";
import {
  EActionParameterType,
  ESectionEntity,
  IActionParameter,
} from "doweb-alfred-backend-client";
import { TreeSelect } from "../../../components/TreeSelect/TreeSelect";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

interface Props {
  setParameter: Function;
  parameter: IActionParameter;
  show: boolean;
  setModal: Function;
  sectionType: String;
}
export const CreateEditActionParameterModal = (props: Props) => {
  const fieldSelectorTree = useSelector(
    (state: RootState) => state.list.fields // sectionType ***REPLACE***
  );
  let theActualSelectorTree = fieldSelectorTree.project;
  //Make a Switch on props.sectionType
  switch (props.sectionType) {
    case ESectionEntity.Appointment:
      theActualSelectorTree = fieldSelectorTree.appointment;
      break;
    case ESectionEntity.Project:
      theActualSelectorTree = fieldSelectorTree.project;
      break;
    case ESectionEntity.Contact:
      theActualSelectorTree = fieldSelectorTree.contact;
      break;
    case ESectionEntity.Person:
      theActualSelectorTree = fieldSelectorTree.person;
      break;
    case ESectionEntity.Sale:
      theActualSelectorTree = fieldSelectorTree.sale;
      break;
    case ESectionEntity.Document:
      theActualSelectorTree = fieldSelectorTree.document;
      break;
  }

  //We put the criterias in cache, so everything wont be saved before hitting the save button
  const [parameterCache, setParameterCache] = useState(props.parameter);
  const [fieldSelectPopover, setFieldSelectPopover] = useState(false);
  //UseRef for the radio buttons
  const radioRef = React.useRef<HTMLInputElement>(null);
  const radioRef2 = React.useRef<HTMLInputElement>(null);

  const saveHandler = () => {
    props.setParameter(parameterCache);
    props.setModal(!props.show);
  };
  useEffect(() => {
    setParameterCache(props.parameter);
    if(radioRef.current && radioRef2.current){
      if(props.parameter.type === EActionParameterType.So){
        radioRef.current.checked = true;
        radioRef2.current.checked = false;
      }
      else{
        radioRef.current.checked = false;
        radioRef2.current.checked = true;
      }
    }
  }, [props.parameter]); 
  return (
    <MDBModal
      staticBackdrop
      className="criteriaSelectModal"
      show={props.show}
      setShow={(e: any) => {}}
    >
      <MDBModalDialog centered size="lg">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Rediger parameter</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => props.setModal(!props.show)}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              <MDBCol size={3}>
              
                  <MDBRadio
                    name="options"
                    label="SuperOffice"
                    inputRef={radioRef}
                    defaultChecked={parameterCache.type === EActionParameterType.So}
                    onClick={() => {
                      setParameterCache({
                        ...parameterCache,
                        type: EActionParameterType.So,
                      });
                    }}
                  />
                  <MDBRadio
                    name="options" 
                    label="Custom"
                    inputRef={radioRef2}
                    defaultChecked={parameterCache.type === EActionParameterType.Custom}
                    onClick={() => {
                      setParameterCache({
                        ...parameterCache,
                        type: EActionParameterType.Custom,
                      });
                    }}
                  />
                 
              </MDBCol>
              <MDBCol size={4} className="d-flex align-items-center">
                <MDBInput
                  label="Navn"
                  type="text"
                  value={parameterCache.name}
                  onChange={(e) =>
                    setParameterCache({
                      ...parameterCache,
                      name: e.target.value,
                    })
                  }
                />
              </MDBCol>
              <MDBCol className="d-flex align-items-center flex-fill" size={5}>
                {EActionParameterType[parameterCache.type] ===
                EActionParameterType.So ? (
                  <TreeSelect
                  tree={theActualSelectorTree}
                  label={parameterCache.label}
                  onSelect={
                    //function that sets the parameter cache
                    (selected: any) => {
                    
                      setParameterCache({
                        ...parameterCache,
                        value: selected.data.Type,
                        label: selected.label
                      });
                    }
                  }
                  closeOnSelect={true}
                  popoverOpen={fieldSelectPopover}
                  setPopoverOpen={setFieldSelectPopover}
                  />
                ) : (
                  <MDBInput 
                    label="Værdi"
                    value={parameterCache.value}
                    onChange={(e: any) => {
                      setParameterCache({
                        ...parameterCache,
                        value: e.target.value,
                        label: ''
                      });
                    }}
                  />
                )}
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="secondary"
              onClick={() => props.setModal(!props.show)}
            >
              Annuller
            </MDBBtn>
            <MDBBtn onClick={saveHandler}>Gem</MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

/*
      <ModalWrapper>
        <CreateEditCriteriaModal
          sectionType={props.sectionType}
          criteria={activeCriteria.criteria}
          setCriteria={(changedCriteria: ISectionCriteria) => {
            if (activeCriteria.index === -1) {
              const newCriteriaArray = [...props.criteriaGroup.criterias, changedCriteria];  
              props.setCriteriaGroup({...props.criteriaGroup, criterias: newCriteriaArray});
              console.log({...props.criteriaGroup, criterias: newCriteriaArray});
            } else {
              let tempCriterias = [...props.criteriaGroup.criterias];
              tempCriterias[activeCriteria.index] = changedCriteria;
              props.setCriteriaGroup({...props.criteriaGroup, criterias: tempCriterias});
            }
          }}
          show={activeCriteria.showModal}
          setModal={(show: boolean) => {
            setActiveCriteria({
              ...activeCriteria,
              showModal: show,
            });
            props.setMotherModal(!show);
          }}
        />
      </ModalWrapper>
*/
