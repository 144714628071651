import { EditModal } from "./Modals";

import {
  MDBCol,
  MDBRow,
  MDBCardHeader,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBIcon,
} from "mdb-react-ui-kit";
import { useState } from "react";
import { ModalWrapper } from "../../ModalWrapper";
import {
  ETargetGroupType,
  ISuOAssociate,
  ITargetGroup,
  ITargetGroupGroupMember,
} from "doweb-alfred-backend-client";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { ISuoGroupAssocs } from "../../../assets/types/General.d";
interface Props {
  targetGroup: ITargetGroup;
  setTargetGroup: Function;
}
export function TargetGroup(props: Props) {
  const groups = useSelector((state: RootState) => state.list.groups);
  const [modal, setModal] = useState(false);
  const calculateGroupReceivers = () => {
    const unique_ids: number[] = [];
    props.targetGroup.groupMembers.forEach(
      (member: ITargetGroupGroupMember) => {
        const this_group: ISuoGroupAssocs | undefined =
          groups.list.find(
            (group) => group.groupId === member.groupId
          );
        if (typeof this_group !== "undefined") {
          this_group.associates.forEach(
            (associate: ISuOAssociate) => {
              const isDuplicate = unique_ids.includes(
                associate.associateId
              );
              if (!isDuplicate) {
                unique_ids.push(associate.associateId);
              }
            }
          );
        }
      }
    );
    return unique_ids.length;
  };
  return (
    <MDBCard border="#e3e3e3">
      <ModalWrapper>
        <EditModal
          modal={modal}
          setModal={setModal}
          targetGroup={props.targetGroup}
          setTargetGroup={(newTargetGroup: ITargetGroup) =>
            props.setTargetGroup(newTargetGroup)
          }
        />
      </ModalWrapper>
      <MDBCardBody>
        <MDBCardHeader className="text-center">Målgruppe</MDBCardHeader>

        <MDBRow className="mt-2">
          <MDBCol size={7}>
            {props.targetGroup.type === ETargetGroupType.Group && calculateGroupReceivers()}
            {props.targetGroup.type === ETargetGroupType.User &&
              props.targetGroup.associateMembers.length}{" "}
            modtagere i målgruppen
          </MDBCol>
          <MDBCol size={5}>
            <div className="d-grid gap-2">
              <MDBBtn
                onClick={() => setModal(!modal)}
                size="sm"
                color="warning"
              >
                <MDBIcon fas icon="pencil-alt" />
                &nbsp;Rediger
              </MDBBtn>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  );
}
